import React, { useState } from "react";
import Header from "../hireform/Headerform";
import UpdatedFooter from "../footer/UpdatedFooter";
import "./leadform.css";
import FormWrapper from "./FormWrapper";
import ThankYou from "../hireform/ThankYou";

function LeadForm() {
  const [step, setStep] = useState(1);
  return (
    <>
      <Header />
      <div style={{ maxWidth: "1440px", margin: "0 auto" }}>
        <div className="lead-form-wrapper">
          <div className="lead-form-des-wrapper">
            <p>Let's Connect</p>
            <p>
              At Remotepad, our team is committed to understanding your needs
              and finding the perfect match for your business objectives.
            </p>
            <p>
              Share your details, and our team will reach out to schedule a
              personalised discussion about your requirements. Let's find the
              perfect solution for your business.
            </p>
          </div>

          <div className="lead-form">
            {step === 1 ? <FormWrapper setStep={setStep} /> : <ThankYou />}
          </div>
        </div>
      </div>
      <UpdatedFooter />
    </>
  );
}

export default LeadForm;
