import * as Yup from "yup";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const nameRegex = /^[A-Za-z ]+$/;
const phoneNumberPattern = /^[+-]?\d+(\.\d+)?( |$)/;

export const validateLeadForm = Yup.object().shape({
  fullname: Yup.string()
    .matches(nameRegex, "Name must contain only alphabets")
    .required("Name is required"),
  professionalEmail: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is required"),
  companyName: Yup.string()
    .matches(nameRegex, "Company name must contain only alphabets")
    .required("Company name is required"),
  industry: Yup.string().required("Industry Type is required"),
  skypeID: Yup.string().required("SkypeId is required"),
  location: Yup.string().required("Location is required"),
  briefDetail: Yup.string().required("Brief Detail is required"),
  meetingDate: Yup.string().required("Meeting Date is required"),
  meetingTime: Yup.string().required("Meeting Time is required"),
});
