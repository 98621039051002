import React from "react";
import "./updatedFooter.css";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../Assets/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../Assets/Instagram.svg";
import { ReactComponent as LinkedInIcon } from "../Assets/Linkedin.svg";

function UpdatedFooter() {
  const location = useLocation();
  const currentPathname = location.pathname.split("/")[1];
  const data = [
    {
      id: 1,
      title: "Frontend Developer",
      skills: [
        { name: "ReactJs", slug: "/hire-a-react-js-developer" },
        { name: "Angular", slug: "/hire-a-angular-developer" },
        { name: "NextJs", slug: "/hire-a-react-js-developer" },
        { name: "vueJs", slug: "/hire-a-frontend-developer" },
        { name: "HTML/CSS/JS", slug: "/hire-a-frontend-developer" },
      ],
    },
    {
      id: 2,
      title: "Backend Developer",
      skills: [
        { name: "NodeJs", slug: "/hire-a-node-js-developer" },
        { name: "Laravel", slug: "/hire-a-laravel-developer" },
        { name: "Ruby On Rails", slug: "/hire-a-ROR-developer" },
        { name: "MongoDb", slug: "/hire-a-node-js-developer" },
        { name: "ExpressJs", slug: "/hire-a-node-js-developer" },
      ],
    },
    {
      id: 3,
      title: "Full Stack Developer",
      skills: [
        { name: "ReactJs", slug: "/hire-a-react-js-developer" },
        { name: "NodeJs", slug: "/hire-a-node-js-developer" },
        { name: "MongoDb", slug: "/hire-a-node-js-developer" },
        { name: "ExpressJs", slug: "/hire-a-node-js-developer" },
        { name: "HTML/CSS/JS", slug: "/hire-a-frontend-developer" },
      ],
    },
    {
      id: 4,
      title: "Mobile Developer",
      skills: [
        { name: "React Native", slug: "/hire-a-react-native-developer" },
        { name: "Flutter", slug: "/hire-a-flutter-developer" },
        { name: "Dart", slug: "/hire-a-android-developer" },
        { name: "Javascript", slug: "/hire-a-frontend-developer" },
        { name: "Java", slug: "/hire-a-android-developer" },
        { name: "Kotlin", slug: "/hire-a-android-developer" },
      ],
    },
    {
      id: 5,
      title: "DevOps Engineer",
      skills: [
        { name: "Git", slug: "/hire-a-devops-developer" },
        { name: "AWS", slug: "/hire-a-devops-developer" },
      ],
    },
    {
      id: 6,
      title: "QA Engineer",
      skills: [
        { name: "Automate Testing", slug: "/hire-a-QA-developer" },
        { name: "Manual", slug: "/hire-a-QA-developer" },
      ],
    },
    {
      id: 7,
      title: "Graphic Designer",
      skills: [
        { name: "Adobe Photoshop", slug: "/hire-a-graphic-designer" },
        { name: "Adobe Indesign", slug: "/hire-a-graphic-designer" },
        { name: "Figma", slug: "/hire-a-graphic-designer" },
        { name: "Branding", slug: "/hire-a-graphic-designer" },
      ],
    },
  ];
  const pagesArray = [
    "/hire-me-form",
    "/hire-a-team",
    "/join-a-team",
    "/leadform",
  ];

  const getClassname = () => {
    if (pagesArray.includes("/" + currentPathname)) {
      return "inner-container-op";
    } else {
      return "inner-container";
    }
  };

  return (
    <div className="container">
      <div className={getClassname()}>
        <div className="inner-main-container">
          <div className="categories">
            {data?.map((item, id) => (
              <div className="footer-categories-wrapper">
                <p className="heading-cat">{item?.title}</p>
                <div className="skills-wrapper">
                  {item?.skills?.map((item, index) => (
                    <Link to={`${item?.slug}`}>
                      <p className="skill-cat">{item?.name}</p>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="inner-bottom-container">
            <Link to={"/blog"} className="visit-blog-link">
              Visit Our Blog
            </Link>

            <div className="terms-pp-wrapper">
              <Link to={"#"}>Terms & Conditions</Link>
              <div className="circle-dot"></div>
              <Link to={"#"}>Privacy Policy</Link>
            </div>

            <div className="social-icons-wrapper">
              <Link
                to={
                  "https://www.linkedin.com/company/bitsclansolutions/mycompany/verification/"
                }
                target="_blank"
              >
                <LinkedInIcon />
              </Link>
              <Link
                to={"https://www.instagram.com/bitsclansolutions/"}
                target="_blank"
              >
                <InstagramIcon />
              </Link>
              <Link to={"https://www.facebook.com/bitsclan/"} target="_blank">
                <FacebookIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatedFooter;
