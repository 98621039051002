import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "./Assets/newBannerImage2.webp"

const Herosection = () => {
  return (
    <div className="container hero-section-main">
      <div className="hero-content p-0">
        <div className="row home-hero justify-content-center align-items-center">
          <div className="col-md-6 ">
            <div className="main-title">
              <h1 className=" mb-3">Hire Top Remote Developers</h1>
              <p className="mb-4 main-title-des">
                Empower your projects with a handpicked team of top-tier remote
                software developers, ready to deliver exceptional results no
                matter where you are.
              </p>
              <div className="d-flex gap-2">
                <div className="form_btn_ctrl d-flex justify-content-start d-md-flex mb-2">
                  <Link to={"/schedule-a-call"}>
                    <button className="step-btn ">
                      Schedule Free Call Now
                    </button>
                  </Link>
                </div>
                <div className="form_btn_ctrl d-flex justify-content-start d-md-flex mb-2">
                  <Link to={"/hire-a-team"}>
                    <button className="step-back_btn ">Hire Developers</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center hero-img-sect">
            <div className="hero-img-box_main">
              <img src={BannerImage} alt="" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Herosection;
