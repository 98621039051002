import React, { Suspense } from "react";
import "./techanimationcss.css";
import "../../components/animSection/animation.css";
// import Firsts_layeranimation from "./Firsts_layeranimation";
// import BasedOnRole from "./BasedOnRole";
// import BasedOnCareer from "./BasedOnCareer";

const Firsts_layeranimation = React.lazy(() =>
  import("./Firsts_layeranimation")
);
const BasedOnRole = React.lazy(() => import("./BasedOnRole"));
const BasedOnCareer = React.lazy(() => import("./BasedOnCareer"));

function Techanimation() {
  return (
    <div className="Wrapper container">
      <div className="pt-5 text-center mx-2 mx-sm-0">
        <h2 className="mb-3">
          Empower Your Vision with Our cutting-edge Tech Stacks
        </h2>
        <p className="mb-4">
          At Remotepad, we specialise in hiring remote software developers
          skilled in a wide range of tech stacks. Trust us to find the perfect
          fit for your projects.
        </p>
      </div>
      <div className="hero-content d-flex flex-column px-0 justify-content-center gap-1">
        <h3>Find Your Perfect Match Based on Skills:</h3>
        <Suspense fallback={<div>loading....</div>}>
          <Firsts_layeranimation />
        </Suspense>
        <h3 className="mt-3">Explore Roles That Fit Your Needs:</h3>
        <Suspense fallback={<div>loading....</div>}>
          <BasedOnRole />
        </Suspense>
        <h3 className="my-3">Based On Career Trajectory:</h3>
        <Suspense fallback={<div>loading....</div>}>
          <BasedOnCareer />
        </Suspense>
      </div>
    </div>
  );
}

export default Techanimation;
