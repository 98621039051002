export const skillsData = [
  {
    id: 1,
    title: "backend",
    skills: [
      "Java",
      "Python",
      "Ruby",
      "Node.js",
      "C#",
      "MySql",
      "Mongo",
      "PHP",
      "GraphQl",
      "Express",
    ],
  },
  {
    id: 2,
    title: "web",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "Wordpress",
      "Shopify",
      "PHP",
      "Ruby",
      "Python",
      "React",
      "Angular",
      "Vue.js",
      "Next.js",
      "Laravel",
    ],
  },
  {
    id: 3,
    title: "fullstack",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "TypeScript",
      "React",
      "Angular",
      "Vue.js",
      "Java",
      "Python",
      "Ruby",
      "Node.js",
      "C#",
    ],
  },
  {
    id: 4,
    title: "python",
    skills: ["Java", "Python", "C#", "Django", "Flask"],
  },
  {
    id: 5,
    title: "nodejs",
    skills: ["Java", "Python", "Ruby", "Node.js", "C#", "Mongo", "GraphQl"],
  },
  {
    id: 6,
    title: "graphicdesigner",
    skills: ["Figma", "Branding", "Adobe XD", "Sketch", "InDesign"],
  },
  {
    id: 7,
    title: "frontend",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "TypeScript",
      "React",
      "Angular",
      "Vue.js",
      "Antd",
      "Mui",
      "Chakra-ui",
    ],
  },
  {
    id: 8,
    title: "reactjs",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "TypeScript",
      "React",
      "Redux",
      "RTK",
      "React-Query",
      "Antd",
      "Mui",
      "Chakra-ui",
    ],
  },
  {
    id: 9,
    title: "cloud",
    skills: [
      "AWS",
      "Azure",
      "Google Cloud Platform",
      "WCF-MSMQ",
      "SQL Server",
      "Kubernetes",
    ],
  },
  {
    id: 10,
    title: "flutter",
    skills: [
      "Swift (iOS)",
      "Java",
      "React Native",
      "Kotlin",
      "MVVM",
      "RecyclerView",
    ],
  },
  {
    id: 11,
    title: "wordpress",
    skills: ["HTML", "CSS", "JavaScript", "PHP", "Theme Dev", "Plugin Dev"],
  },
  {
    id: 12,
    title: "shopify",
    skills: ["HTML", "CSS", "JavaScript", "PHP", "Theme Dev", "Plugin Dev"],
  },
  {
    id: 13,
    title: "laravel",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "PHP",
      "Livewrire",
      "Composer",
      "MySQL",
      "PostgreSQL",
      "RESTful APIs",
    ],
  },
  {
    id: 14,
    title: "mernstack",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "TypeScript",
      "React",
      "Redux",
      "RTK",
      "React-Query",
      "Antd",
      "Mui",
      "Chakra-ui",
      "Node-js",
      "Express",
      "MoongoDb",
      "GraphQl",
      "MySql",
    ],
  },
  {
    id: 15,
    title: "datascientist",
    skills: [
      "Python",
      "ROR",
      "SQL",
      "Pandas",
      "NumPy",
      "Jupyter Notebooks",
      "Matplotlib",
      "Seaborn",
      "Plotly",
      "Scikit-learn",
      "TensorFlow and PyTorch",
      "Statsmodels",
      "MySQL",
      "PostgreSQL",
      "Apache Spark",
      "Hadoop",
      "OpenRefine",
      "AWS",
      "Azure",
    ],
  },
  {
    id: 16,
    title: "graphicdesigner",
    skills: ["HTML", "CSS", "JavaScript", "Adobe XD", "Sketch"],
  },
  {
    id: 17,
    title: "reactnative",
    skills: [
      "JavaScript",
      "React",
      "React Native",
      "Expo",
      "Redux",
      "React Context API",
      "NativeBase",
      "Styled Components",
      "Axios",
      "AsyncStorage",
      "Realm",
      "SQLite",
      "REST",
      "GraphQL",
      "Jest",
      "Detox",
      "SSL Pinning",
    ],
  },
  {
    id: 18,
    title: "app",
    skills: [
      "Swift",
      "Kotlin",
      "React Native",
      "Flutter",
      "Xamarin",
      "UIKit",
      "Android XML",
      "Core Data",
      "Room",
      "XCTest",
      "JUnit",
      "App Store",
      "Google Play Store",
    ],
  },
  {
    id: 19,
    title: "devops",
    skills: [
      "Git",
      "Jenkins",
      "Travis CI",
      "CircleCI",
      "Docker",
      "Kubernetes",
      "Terraform",
      "Ansible",
      "Chef",
      "Jenkins Pipeline",
      "GitLab CI",
      "GitHub Actions",
      "AWS",
      "Microsoft Azure",
      "HashiCorp Vault",
      "Bash",
      "Python",
      "New Relic",
      "Datadog",
    ],
  },
  {
    id: 20,
    title: "dotnet",
    skills: [
      ".NET Core",
      "C#",
      "ASP.NET Core",
      "MVC",
      "Entity Framework",
      "SQL Server",
      "Blazor",
      "Azure",
      "Azure DevOps",
      "Git",
      "Docker",
      "Unit Testing",
      "RESTful API",
    ],
  },
  {
    id: 21,
    title: "blockchain",
    skills: [
      "Solidity",
      "Ethereum",
      "Web3.js",
      "Consensus",
      "Decentralized Applications",
      "Smart Contract Security",
      "Token Standards",
      "Hyperledger Fabric",
      "Interoperability",
      "Hash Functions",
      "DLT",
      "Contract Auditing",
      "Contract Deployment",
      "Wasm",
      "Truffle",
      "Rust",
    ],
  },
  {
    id: 22,
    title: "angular",
    skills: [
      "HTML",
      "CSS",
      "Angular",
      "TypeScript",
      "JavaScript",
      "Angular CLI",
      "RxJS",
      "Jasmine",
      "Angular Material",
    ],
  },
  {
    id: 23,
    title: "java",
    skills: [
      "Java",
      "Java SE",
      "Java EE",
      "Spring Framework",
      "Hibernate",
      "Maven",
      "Gradle",
      "JUnit",
      "RESTful",
      "Spring Boot",
      "JavaFX",
      "JDBC",
      "Servlets and JSP",
      "Design Patterns in Java",
      "Git",
    ],
  },
  {
    id: 24,
    title: "ios",
    skills: [
      "Swift",
      "IOS",
      "Xcode IDE",
      "UIKit Framework",
      "SwiftUI Framework",
      "Auto Layout",
      "Interface Builder",
      "Core Data",
      "Cocoa Touch",
      "RESTful APIs",
      "HIG",
      "Push Notifications",
      "Core Animation",
      "TDD",
      "CI for iOS",
    ],
  },
  {
    id: 25,
    title: "qa",
    skills: [
      "Manual Testing",
      "Automated Testing",
      "Test Planning",
      "Test Case Design",
      "Defect Tracking",
      "Testing Documentation",
      "Regression Testing",
      "Performance Testing",
      "Load Testing",
      "Security Testing",
      "Cross-Browser Testing",
      "Mobile Testing",
      "API Testing",
      "Selenium",
      "Appium",
      "CI/CD Testing",
    ],
  },
  {
    id: 26,
    title: "android",
    skills: [
      "Java",
      "Kotlin",
      "Android Studio IDE",
      "Android SDK",
      "MVVM",
      "Kotlin Coroutines",
      "RecyclerView",
      "Adapters",
      "Fragments",
      "RESTful API",
      "SQLite",
      "Room",
      "Firebase",
      "Push Notifications",
      "Dagger",
      "Koin",
      "JUnit",
      "Espresso",
    ],
  },
  {
    id: 27,
    title: "ror",
    skills: [
      "Ruby",
      "Ruby on Rails Framework",
      "MVC",
      "Active Record",
      "RESTful API",
      "Rails CLI",
      "Rails Routing",
      "ERB",
      "RSpec",
      "Capistrano",
      "Action Cable",
      "Git",
      "PostgreSQL",
      "JavaScript",
      "AJAX",
    ],
  },
  {
    id: 28,
    title: "php",
    skills: [
      "PHP",
      "Laravel",
      "Symfony",
      "CodeIgniter",
      "MVC",
      "Composer",
      "RESTful API",
      "MySQL",
      "ORM",
      "PHPUnit",
      "Git",
      "JavaScript",
    ],
  },
  {
    id: 29,
    title: "machinelearning",
    skills: [
      "Python",
      "NumPy and Pandas",
      "Scikit-Learn",
      "TensorFlow",
      "PyTorch",
      "Keras",
      "Matplotlib and Seaborn",
      "Jupyter Notebooks",
      "Feature Engineering",
      "Hyperparameter Tuning",
      "Supervised Learning",
      "Unsupervised Learning",
      "NLP",
      "Computer Vision",
      "Neural Networks",
    ],
  },
  {
    id: 30,
    title: "game",
    skills: [
      "Unity Game Engine",
      "C#",
      "Unity Scripting API",
      "Unity UI/UX Design",
      "Blender",
      "Maya",
      "Unity ShaderLab",
      "AR or VR Development",
      "Multiplatform",
      "Unity Asset Store",
      "Networking",
      "Multiplayer",
      "Unity Physics",
      "Particle Systems",
      "Audio Integration",
      "Sound Design",
      "Git",
    ],
  },
  {
    id: 31,
    title: "database",
    skills: [
      "DBMS",
      "SQL",
      "Oracle Database",
      "Database Design",
      "Modeling",
      "Query Optimization",
      "RDBMS",
      "NoSQL Databases",
      "Database Security",
      "Data Backup",
      "ETL Processes",
      "Database Migration",
      "Data Warehousing",
      "Cloud Services",
      "Hadoop",
      "Spark",
    ],
  },
  {
    id: 32,
    title: "automation",
    skills: [
      "Python",
      "Java",
      "C#",
      "Selenium",
      "Appium",
      "JUnit",
      "TestNG",
      "Bash",
      "PowerShell",
      "Git",
      "CI/CD",
      "Docker",
      "BDD Tools",
    ],
  },
  {
    id: 33,
    title: "software",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "TypeScript",
      "React",
      "Angular",
      "Vue.js",
      "Java",
      "Python",
      "Ruby",
      "Node.js",
      "C#",
    ],
  },
  {
    id: 34,
    title: "seniorsoftware",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "TypeScript",
      "React",
      "Angular",
      "Vue.js",
      "Java",
      "Python",
      "Ruby",
      "Node.js",
      "C#",
      "SQL",
      "NoSQL Databases",
      "Hadoop",
      "Spark",
      "Git",
      "CI/CD",
      "Docker",
    ],
  },
  {
    id: 35,
    title: "seniorandroid",
    skills: [
      "Java",
      "Kotlin",
      "Android Studio IDE",
      "Android SDK",
      "MVVM",
      "Kotlin Coroutines",
      "RecyclerView",
      "Adapters",
      "Fragments",
      "RESTful API",
      "SQLite",
      "Room",
      "Firebase",
      "Push Notifications",
      "Dagger",
      "Koin",
      "JUnit",
      "Espresso",
    ],
  },
  {
    id: 36,
    title: "directorofengineer",
    skills: [
      "Project Management",
      "Leadership",
      "Strategic Planning",
      "Program Management",
      "Budgeting",
      "Resource Allocation",
      "Cross-Func Collaboration",
      "Talent Recruitment",
      "Retention",
      "Conflict Resolution",
      "Risk Management",
      "Agile",
      "Scrum",
    ],
  },
  {
    id: 37,
    title: "softwarearchitecture",
    skills: [
      "SQL Server",
      "Microservices",
      "WCF-MSMQ",
      "Enterprise-level",
      "System Design",
      "Cloud Computing",
      "API Design",
      "Docker",
      "Kubernetes",
      "Design Principles",
      "Technical Leadership",
      "Strategic Planning",
      "Cross-Functional",
      "Vendor Evaluation",
      "Security Architecture",
      "Performance Optimization",
    ],
  },
  {
    id: 38,
    title: "techlead",
    skills: [
      "Enterprise-level",
      "System Design",
      "Microservices",
      "Cloud Computing",
      "API Design",
      "Docker",
      "Kubernetes",
      "Budgeting",
      "Cross-Functional",
      "Vendor Evaluation",
      "Agile",
      "Scrum",
    ],
  },
  {
    id: 39,
    title: "seniorjavascript",
    skills: [
      "Full-Stack",
      "Node.js",
      "Express.js",
      "React.js",
      "Angular.js",
      "Vue.js",
      "SSR",
      "RESTful API",
      "GraphQL",
      "Redux",
      "MobX",
      "Webpack",
      "Jest",
      "Mocha",
      "Jasmine",
      "PWA",
      "CI/CD",
      "Docker",
    ],
  },
  {
    id: 40,
    title: "system",
    skills: [
      "Full-Stack",
      "JavaScript",
      "TypeScript",
      "Node.js",
      "Express.js",
      "React",
      "Angular.js",
      "Vue.js",
      "System Design",
      "Microservices",
      "Cloud Computing",
      "RESTful API",
      "GraphQL",
      "Redux",
      "MobX",
      "Webpack",
      "Jest",
      "Mocha",
      "Jasmine",
      "PWA",
      "CI/CD",
      "Docker",
    ],
  },
];
