import React, { useState } from "react";
import Slider from "../Ourdeveloper-2.jsx/Ourdeveloper_2_slider";
import "./readytoget.css";
import { Link } from "react-router-dom";

function Readytoget() {
  const [currentSlideData, setCurrentSlideData] = useState({});
  return (
    <>
      <div className="readytoget row g-0">
        <div className="readytoget-inner-container row g-0">
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end ready-left-side">
            <Slider
              currentSlideData={currentSlideData}
              setCurrentSlideData={setCurrentSlideData}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 text-white ready-right-side-parent">
            <div className="ready-right-side mb-sm-4 mt-0">
              <h2 className="fw-bolder mb-3">
                Ready to Begin Your Remote Hiring Journey?
              </h2>
              <p className="mb-4">
                Get in touch now, and let's pair you with top-tier Developers
                and more!
              </p>
              <div className="">
                <Link to={"/engineer"}>
                  <button className="step-back_btn my-2 my-sm-0  ">
                    Meet Top Developers
                  </button>
                </Link>
                <Link to={"/schedule-a-call"}>
                  <button className="step-btn border-0 mx-2 my-2 my-sm-0">
                    Contact Us
                  </button>
                </Link>
              </div>

              {/* <div className="current-dev-info">
                  <div className="info-wrapper">
                    <p>Years of experience</p>
                    <p>{`${currentSlideData?.EmpExp} Years`}</p>
                  </div>
                  <div className="info-wrapper">
                    <p>Rating</p>
                    <p>76% positive rating</p>
                  </div>
                  <div className="info-wrapper">
                    <p>Email address</p>
                    <p>{currentSlideData?.email}</p>
                  </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Readytoget;
