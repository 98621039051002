import React, { useState, useEffect } from "react";
import "./about-Hiring-Company.css";
import $ from "jquery";
import Spinner from "../../Spinner/Spinner";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const AboutHiringcompany = ({
  val,
  setNo,
  myform,
  setmyform,
  next,
  back,
  selectedQty,
  selectedExp,
  selectedSkills,
  selectedTeams,
  devName,
  category,
}) => {
  const [value, setValue] = useState("1");
  const [err, setError] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [verified, setVerified] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  setNo(value);
  useEffect(() => {
    setValue(val);
    $(".PhoneInputCountryIcon").addClass("democlass");
    $(".democlass").append("<p>Select</p>");
  }, []);
  const removeErr = () => {
    setTimeout(() => {
      setError(0);
      setErrMsg("");
    }, 3000);
    console.log(selectedSkills);
  };

  const handleOnchange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setmyform({ ...myform, [name]: value });
  };

  // const sheetName= "HireATeamSheet";
  const sheetName =
    location.pathname === "/hire-a-team"
      ? "HireATeamSheet"
      : devName
      ? "RequestCandidateSheet"
      : "HireMeSheet";

  const state = {
    DeveloperName: devName,
    Category: JSON.stringify(category),
    RemoteTeam: JSON.stringify(selectedTeams),
    Skills: JSON.stringify(selectedSkills),
    TeamMembers: selectedQty,
    Experience: selectedExp,
    FullName: myform.myformName,
    Designation: myform.myformDesignation,
    Email: myform.myformEmail,
    SkypeId: myform.myformSkypeId,
    CompanyName: myform.myformcompName,
    Industry: myform.myformcompIndustry,
    Location: myform.myformcompLoc,
    CompanyEmployees: myform.myformcompEmp,
    ProjectDetails: myform.myformBreifDetail,
    SheetName: sheetName,
  };

  const sendForm = () => {
    setLoading(true);
    const formData = new FormData();
    for (const key in state) {
      if (state.hasOwnProperty(key)) {
        const value = state[key];

        if (Array.isArray(value)) {
          // If the value is an array, append each element separately
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[${i}]`, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    fetch(
      // "https://script.google.com/macros/s/AKfycbxn_3gN6ndF4Oc-OSMwS-TEmYM-p09iDN0DRDjkgLyslC9-0QfBOgx-cFSbLE_rFRx8/exec",
      process.env.REACT_APP_GSHEET_URL,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        // res.json();
        next();
      })
      .catch((error) => console.log(error));
  };

  const submitData = (e) => {
    e.preventDefault();
    var filter =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (myform.myformName === "") {
      setErrMsg("Full name required!");
      setError(1);
      removeErr();
      return;
    }
    if (!isNaN(myform.myformName)) {
      setErrMsg("Numerical value is not allowed!");
      setError(1);
      removeErr();
      return;
    }

    if (myform.myformDesignation === "") {
      setErrMsg("Designation required!");
      setError(2);
      removeErr();
      return;
    }
    if (!isNaN(myform.myformDesignation)) {
      setErrMsg("Numerical value is not allowed!");
      setError(2);
      removeErr();
      return;
    }

    if (myform.myformEmail === "") {
      setErrMsg("Email required!");
      setError(3);
      removeErr();
      return;
    }
    if (!filter.test(myform.myformEmail)) {
      setErrMsg("Please provide a valid email address");
      setError(3);
      removeErr();
      return;
    }

    if (myform.myformSkypeId === "") {
      setErrMsg("Customer's skypeId required!");
      setError(4);
      removeErr();
      return;
    }
    if (!isNaN(myform.myformSkypeId)) {
      setErrMsg("Please provide valid skypeId!");
      setError(4);
      removeErr();
      return;
    }

    if (myform.myformcompName === "") {
      setErrMsg("Company name required!");
      setError(5);
      removeErr();
      return;
    }
    if (!isNaN(myform.myformcompName)) {
      setErrMsg("Numerical value is not allowed!");
      setError(5);
      removeErr();
      return;
    }

    if (myform.myformcompIndustry === "") {
      setErrMsg("Company name required!");
      setError(6);
      removeErr();
      return;
    }
    if (!isNaN(myform.myformcompIndustry)) {
      setErrMsg("Numerical value is not allowed!");
      setError(6);
      removeErr();
      return;
    }

    if (myform.myformcompLoc === "") {
      setErrMsg("Company location required!");
      setError(7);
      removeErr();
      return;
    }
    var hasNumber = /\d/;
    if (hasNumber.test(myform.myformcompLoc)) {
      setErrMsg("Location should not contain numeric values!");
      setError(7);
      removeErr();
      return;
    }

    if (myform.myformcompEmp === "") {
      setErrMsg("Number of employees required!");
      setError(8);
      removeErr();
      return;
    }
    if (myform.myformBreifDetail === "") {
      setErrMsg("Project's brief detail is required!");
      setError(8);
      removeErr();
      return;
    }
    if (!verified) {
      setCaptchaError(true);
      return;
    }
    sendForm();
  };

  const backStep = () => {
    back();
  };
  function onChange(value) {
    setVerified(true);
    if (verified) {
      setCaptchaError(false);
    }
  }

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <div className="mt-4 inp-wrapper">
          <form onSubmit={submitData}>
            <div className=" ">
              <h1 className="set-remote-font text-center px-3 mt-2 mb-5 fw-bold w-md-50 mx-auto">
                Please fill out the form below to kickstart your project:
              </h1>
              <p className="text-center">
                Our expert will contact you shortly to discuss your project in
                more detail.
              </p>

              <div className="row py-3 mx-xl-5">
                <div
                  className="col-md-6  mb-3 info set-my-input-relative"
                  key="1"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Full Name
                  </label>
                  <input
                    required
                    maxLength="20"
                    type="text"
                    className="form-control form-control-lg"
                    name={"myformName"}
                    value={myform.myformName}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 1 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6  mb-3 info set-my-input-relative"
                  key="2"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Designation
                  </label>
                  <input
                    required
                    maxLength="55"
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformDesignation"
                    value={myform.myformDesignation}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 2 && errMsg}</small>
                </div>
                <div className=" col-md-6  mb-3 set-my-input-relative" key="3">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Professional Email
                  </label>
                  <input
                    required
                    maxLength="32"
                    type="email"
                    name="myformEmail"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    value={myform.myformEmail}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 3 && errMsg}</small>
                </div>
                <div className=" col-md-6  mb-3 set-my-input-relative" key="4">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Skype ID
                  </label>
                  <input
                    maxLength="35"
                    required
                    type="text"
                    name="myformSkypeId"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    value={myform.myformSkypeId}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 4 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6  mb-3 info set-my-input-relative"
                  key="5"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Company Name:
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="comp_name"
                    name="myformcompName"
                    value={myform.myformcompName}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 5 && errMsg}</small>
                </div>
                <div className=" col-md-6  mb-3 set-my-input-relative" key="6">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Industry
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformcompIndustry"
                    value={myform.myformcompIndustry}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div>
                <div className=" col-md-6  mb-3 set-my-input-relative" key="7">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Location
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformcompLoc"
                    value={myform.myformcompLoc}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 7 && errMsg}</small>
                </div>
                <div className=" col-md-6  mb-3 set-my-input-relative" key="8">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Company Size
                  </label>
                  <select
                    className="form-select set-my-select"
                    required
                    name="myformcompEmp"
                    value={myform.myformcompEmp}
                    onChange={handleOnchange}
                    aria-label="Default select example"
                  >
                    <option value="0">Select Employee</option>
                    <option value="0-20">0 to 20</option>
                    <option value="20-50">20 to 50</option>
                    <option value="50-100">50 to 100</option>
                    <option value="100-500">100 to 500</option>
                    <option value="500-1000">500 to 1000</option>
                    <option value="1000-10000">1000 to 10,000</option>
                  </select>
                  <small className="redmsg">{err === 8 && errMsg}</small>
                </div>
                <div className=" col-md-6  mb-3 set-my-input-relative" key="7">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Project Description
                  </label>
                  <textarea
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformBreifDetail"
                    value={myform.myformBreifDetail}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 7 && errMsg}</small>
                </div>

                <div className="px-3">
                  <ReCAPTCHA
                    sitekey="6LcsgmopAAAAAMTyP7jnxKeb-fr02SURarYrCVMS"
                    onChange={onChange}
                  />
                </div>
                {captchaError && <h6 className="text-danger">Please verify</h6>}
              </div>
            </div>
            <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-none d-md-flex">
              {devName ? null : (
                <button className="step-back_btn mx-1 mb-5 " onClick={backStep}>
                  Back
                </button>
              )}
              <button type="submit" className="step-btn px-5 mx-1 mb-5">
                Next
              </button>
            </div>
            <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-md-none">
              <button type="submit" className="step-btn mx-1 px-3 mb-3">
                Next
              </button>
              <button className="step-back_btn mx-1 mb-5 " onClick={backStep}>
                Back
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default AboutHiringcompany;
