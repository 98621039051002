import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  posts: [],
};

const blogPostsSlice = createSlice({
  name: "blogPost",
  initialState,
  reducers: {
    postsHandler: (state, action) => {
      state.posts = action.payload;
    },
    removePosts: (state, action) => {
      state.posts = [];
    },
  },
});

export const { postsHandler, removePosts } = blogPostsSlice.actions;

export default blogPostsSlice.reducer;
