import React from "react";
import "../../components/animSection/animation.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import frame from "../Assets/Frame.png";
import frame1 from "../Assets/Frame (1).png";
import frame2 from "../Assets/Frame (2).png";
import frame3 from "../Assets/Frame (3).png";
import frame4 from "../Assets/Frame (4).png";
import frame5 from "../Assets/Frame (5).png";
import { Link } from "react-router-dom";

function Traial() {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const animation = useAnimation();
  React.useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.1,
        },
      });
    }
    if (!inView) {
      animation.start({ y: "120%", opacity: 0 });
    }
  }, [inView]);
  return (
    <div className="trail-main py-5">
      <div className="hero-content text-dark">
        <div className="d-flex flex-column justify-content-center align-items-center mb-5">
          <h2 className="fw-bold text-white mb-3">Enjoy 2-Week Free Trial</h2>
          <p className=" w-80 text-white text-center mb-0">
            We offer a 2-week risk-free trial to all our customers, during which
            you can work with your new tech team for 14 days without paying us
            anything upfront. Pay only when you are happy with the team’s
            performance in the trial period.
          </p>
        </div>
        <div className=" d-flex justify-content-center">
          {/* <div className="container animation px-4" ref={ref}> */}
            {/* <motion.div> */}
              <div className="row g-0">
                <div className="col-lg-4 col-sm-12  p-2 ">
                  <div className="d-flex rounded card_body  bg-white flex-column justify-content-center align-items-center gap-2 pb-4 pt-5 mt-4">
                    <div className="trial-svg">
                      <img src={frame} alt="" />
                    </div>
                    <h3 className="">Taking Ownership</h3>
                    <p className="trial-para-2 ">
                      We own all tasks to the finish line and focus on impact
                      against goals. Our colleagues map out their work and
                      define their tasks to get the job done, delivering great
                      quality results every time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12  p-2 mt-4">
                  <div className="d-flex rounded card_body  bg-white flex-column justify-content-center align-items-center gap-2 pb-4 pt-5">
                    <div className="trial-svg">
                      <img src={frame1} alt="" />
                    </div>
                    <h3 className="">Delight Customers</h3>
                    <p className="trial-para-2">
                      Our customers are key to our success and growth. We strive
                      to delight them in every interaction and provide them with
                      developers who understand their needs and match their
                      expectations.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 p-2 mt-4">
                  <div className="d-flex rounded card_body bg-white flex-column  justify-content-center align-items-center gap-2 pb-4 pt-5">
                    <div className="trial-svg">
                      <img src={frame2} alt="" />
                    </div>
                    <h3 className="">Be Passionate</h3>
                    <p className="trial-para-2">
                      We are the best in our field because we all are passionate
                      about our roles. We care about our clients’ success and
                      provide regular feedback to our developers to support
                      development and growth.
                    </p>
                  </div>
                </div>
              </div>
            {/* </motion.div> */}
          {/* </div> */}
        </div>
        {/* 2nd Row  */}
        <div className=" d-flex justify-content-center">
          <div className="row g-0">
            <div className="col-lg-4 col-sm-12  p-2 ">
              <div className="d-flex rounded card_body  bg-white flex-column justify-content-center align-items-center gap-2 pb-4 pt-5 mt-4">
                <div className="trial-svg">
                  <img src={frame3} alt="" />
                </div>
                <h3 className="">Always Innovate</h3>
                <p className="trial-para-2">
                  We are consistently working towards building innovative
                  products, solutions, and ways to boost efficiency, improve our
                  services and provide the best quality to our customers.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12  p-2">
              <div className="d-flex rounded card_body  flex-column justify-content-center bg-white align-items-center gap-2 pb-4 pt-5 mt-4">
                <div className="trial-svg">
                  <img src={frame4} alt="" />
                </div>
                <h3 className="fw-bold">Collaborate & Engage</h3>
                <p className="trial-para-2">
                  We operate as a cross-functional community, ensuring that each
                  team knows its responsibilities. We put the company goals at
                  the heart of decisions and support each other to achieve great
                  things.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12  p-2">
              <div className="d-flex rounded card_body flex-column bg-white justify-content-center align-items-center gap-2 pb-4 pt-5 mt-4">
                <div className="trial-svg">
                  <img src={frame5} alt="" />
                </div>
                <h3 className="">Be Respectful</h3>
                <p className="trial-para-2">
                  We operate as a cross-functional community, ensuring that each
                  team knows its responsibilities. We put the company goals at
                  the heart of decisions and support each other to achieve great
                  things.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Link to={"/hire-a-team"}>
            <button className="step-back_btn ">Hire Developers</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Traial;
