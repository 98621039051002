import React from "react";
import Marquee from "react-fast-marquee";
import "./ourclientanimation.css";
import Images_1 from "../Assets/aira2b.webp";
import Images_2 from "../Assets/airdropja.webp";
import Images_3 from "../Assets/aira2b.webp";
import Images_4 from "../Assets/songheart.webp";
import Images_5 from "../Assets/aira2b.webp";
import Images_6 from "../Assets/airdropja.webp";
import "../../components/animSection/animation.css";
import { motion } from "framer-motion";

function Ourclientsanimation({ clientSec }) {
  const clientLogoData = [
    {
      imagePath: Images_1,
      link: "https://www.linkedin.com/in/sumaira-ramzan-a66923223/?trk=nav_responsive_tab_profile_pic&originalSubdomain=pk",
    },
    { imagePath: Images_2, link: "https://example.com/2" },
    { imagePath: Images_3, link: "https://example.com/3" },
    { imagePath: Images_4, link: "https://example.com/4" },
    { imagePath: Images_5, link: "https://example.com/5" },
    { imagePath: Images_6, link: "https://example.com/6" },
  ];

  return (
    <div className="client-animation-container py-5">
      <div className="text-center px-4">
        {/* <h2 className="fw-bold mb-0 text-dark">
          We Work With The Best Companies Of The World
        </h2> */}
      </div>
      <div
        className="container animation px-4"
        style={{ overflow: "hidden" }}
        ref={clientSec}
      >
        <motion.div>
          <div
            className="marque container-fluid mb-3"
            style={{ width: "1150px" }}
          >
            <Marquee direction="left" speed={80} loop={0} pauseOnHover={true}>
              <div className="animation d-flex align-items-center me-5 mt-4 ">
                {clientLogoData.map((data, index) => (
                  <div key={index} className="client-logo">
                    <img
                      loading="lazy"
                      width="130px"
                      height="35px"
                      src={data.imagePath}
                      alt={`Client Logo ${index}`}
                    />
                  </div>
                ))}
              </div>
            </Marquee>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Ourclientsanimation;
