import React, { useState } from "react";
import EngrCard from "./EngrCard";
import "./engrSection.css";
import Header from "../Header/Header";
import DevelopmentServices from "./DevelopmentServices";
import {
  devCategories,
  developer,
  techs,
} from "../SkillsSlider/developersData";
import engieer1 from "../Assets/engineer1.png";
import engieer2 from "../Assets/engeer2.png";
import engieer3 from "../Assets/engeer3.svg";
import Developerstack from "../developerstack/Developerstack";
import SearchBar from "../Searchbar/Searchbar";
import Readytoget from "../readytogetsection/Readytoget";
import { useParams } from "react-router-dom";
import UpdatedFooter from "../footer/UpdatedFooter";

const EngrSection = () => {
  const [visibleRows, setVisibelRows] = useState(9);
  const [expand, setExpand] = useState(false);
  const [devCategory, setDevCategory] = useState("");

  const showLessRows = () => {
    setVisibelRows((prevCount) => prevCount - 3);
  };
  const showMoreRows = () => {
    setVisibelRows((prevCount) => prevCount + 3);
  };
  const { techTitle } = useParams();
  const filteredDevelopers = developer.filter((item) =>
    item.technology.includes(techTitle)
  );

  const filteredCatDev = devCategory
    ? developer.filter((item) => item.technology.includes(devCategory))
    : developer;

  console.log("filteredDevelopers => ", filteredCatDev);

  const image = (i) => {
    if (i % 3 === 0) {
      return engieer1;
    } else if (i % 3 === 1) {
      return engieer2;
    } else if (i % 3 === 2) {
      return engieer3;
    }
  };
  return (
    <>
      <Header />
      <div className="engr-hero-section">
        <div className="engr-heading-wrapper">
          <p>
            Remotepad <span>Engineers</span>
          </p>
          <p>Why Should we hire you?</p>
        </div>
        <div className={`engr-cat-wrapper`}>
          <p className="engr-cat">Categories</p>
          <div className={`inner-cat-wrapper ${expand ? "engr-active" : ""}`}>
            {devCategories?.map((item, index) => (
              <p
                className={`${item?.path === devCategory ? "active-cat" : ""}`}
                key={index}
                onClick={() => setDevCategory(item?.path)}
              >
                {item?.name}
              </p>
            ))}
          </div>
          <p className="view-all-btn" onClick={() => setExpand(!expand)}>
            {expand ? "View Less" : "View All"}
          </p>
        </div>
      </div>
      <div className="pt-5" id="engineer">
        <h1 className="text-center w-75m-auto fw-bold mb-5 engr-heading">
          Engineers you can <span>hire.</span>
        </h1>

        <div className="row hero-content">
          {/* <SearchBar /> */}
          {filteredCatDev
            ?.slice(
              0,
              visibleRows ? Math.min(visibleRows, developer.length) : 9
            )
            .map((item, index) => (
              <div key={index} className="col-sm-6 mb-sm-4 col-lg-4">
                <EngrCard
                  title={item.category}
                  name={item.name}
                  exp={item?.EmpExp}
                  skills={item?.stack}
                  item={item}
                  path={item?.technology}
                  img={image(index)}
                  devProfile={item?.image}
                />
              </div>
            ))}
        </div>
        <div className="d-flex justify-content-center gap-3 ">
          {visibleRows > 9 && (
            <button className="btn-show-more" onClick={showLessRows}>
              <i class={`fas fa-arrow-left`}></i>
              Show Less
            </button>
          )}
          {visibleRows < filteredCatDev.length && (
            <button className="btn-show-more" onClick={showMoreRows}>
              Show More
              <i class={`fas fa-arrow-right`}></i>
            </button>
          )}
        </div>
      </div>

      <DevelopmentServices />
      {/* <EngineersSection /> */}
      <div className="mb-5">
        <Developerstack />
      </div>
      <Readytoget devlopers={filteredDevelopers} />
      {/* <Footer2 /> */}
      <UpdatedFooter />
    </>
  );
};

export default EngrSection;
