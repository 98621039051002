import React, { useState } from "react";
import "./engrSection.css";
import { Link, useLocation } from "react-router-dom";
import react from "../Assets/react.png";
import ml from "../Assets/ml.webp";
import lock from "../Assets/locker.svg";
import numpty from "../Assets/numpy.webp";
import python from "../Assets/python.png";
import chat from "../Assets/chatgpt.png";
import node from "../Assets/node-js.png";
import php from "../Assets/php.webp";
import backend from "../Assets/backendd.png";
import laravel from "../Assets/laravel.webp";
import web from "../Assets/frontend.webp";
import frontend from "../Assets/chatgpt.png";
import angular from "../Assets/angular.png";
import wordpress from "../Assets/wordpress.png";
import android from "../Assets/android.png";
import flutter from "../Assets/flutter.png";
import swift from "../Assets/swift.png";
import kotline from "../Assets/kotlin.webp";
import ios from "../Assets/app.webp";
import cloud from "../Assets/cloud.webp";
import mvcc from "../Assets/mvcc.webp";
import mvc from "../Assets/MVC.png";
import flash from "../Assets/flash.png";
import cloudservice from "../Assets/cloudservice.webp";
import dart from "../Assets/dart.png";
import game from "../Assets/game.jpg";
import tensorflow from "../Assets/TensorFlow.webp";
import window from "../Assets/window.webp";
import vp from "../Assets/vp.png";
import se from "../Assets/se.webp";
import branding from "../Assets/branding.webp";
import git from "../Assets/git.webp";
import app from "../Assets/app.webp";
import blockchain from "../Assets/blockchain.png";
import fullstack from "../Assets/fullstack.png";
import f from "../Assets/f#.png";
import graph from "../Assets/graph.webp";
import rust from "../Assets/rust.png";
import webapp from "../Assets/webapp.svg";
import mobileapp from "../Assets/mobileapp.svg";
import gameapp from "../Assets/gameapp.svg";
import block from "../Assets/block.svg";
import ai from "../Assets/ai.svg";

const Temp = ({ active, blog }) => {
  return (
    <>
      {active === "chat" && (
        <div
          className={` ${
            blog
              ? "pl-2 pt-0 pl-md-5 Services-module--service col-md-12 my-2 "
              : "pl-2 pt-0 pl-md-5 Services-module--service col-md-6 my-2"
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <h2 className="mb-3 text-center">AI/ML/ChatGPT Solutions</h2>
              <p className="pb-0 mb-4">
                Optimize operations and save time with our tailored
                AI/ML/ChatGPT solutions. Boost productivity, reduce errors, and
                focus on profit-making decisions effortlessly.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap mb-4 gap-3">
                <div className="pb-0 ">
                  <img loading="lazy" src={ml} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4 mx-2 ">
                  <img loading="lazy" src={git} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4 mx-2 ">
                  <img loading="lazy" src={chat} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4 mx-2 ">
                  <img loading="lazy" src={numpty} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0  pr-4  mx-2 ">
                  <img loading="lazy" src={python} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4 mx-2 ">
                  <img loading="lazy" src={react} alt="" width="40px" height="40px" />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Link to={"/contact-us"}>
                  <button className="step-back_btn ">Book a Demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {active === "web" && (
        <div
          className={` ${
            blog
              ? "pl-2 pt-0 pl-md-5 Services-module--service col-md-12 my-2 "
              : "pl-2 pt-0 pl-md-5 Services-module--service col-md-6 my-2"
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">
                Dynamic Web Application Development
              </h2>
              <p className="pb-2">
                Take your vision from concept to reality with our scalable web
                product development solutions. Count on us for ongoing support
                to keep your project thriving after launch.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap pb-4">
                <div className="pb-0  pr-4 ">
                  <img loading="lazy" src={react} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={node} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={php} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={backend} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={wordpress} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={frontend} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={angular} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={web} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={laravel} alt="" width="40px" height="40px" />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Link to={"/contact-us"}>
                  <button className="step-back_btn ">Book a Demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {active === "mobile" && (
        <div
          className={` ${
            blog
              ? "pl-2 pt-0 pl-md-5 Services-module--service col-md-12 my-2 "
              : "pl-2 pt-0 pl-md-5 Services-module--service col-md-6 my-2"
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">
                Cutting-Edge Mobile App Development
              </h2>
              <p className="pb-2">
                From consultation on updating existing mobile applications to
                crafting new apps that excel on both iOS and Android platforms,
                we ensure your apps look great and perform even better.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap pb-4">
                <div className="pb-0 pr-4">
                  <img loading="lazy" src={android} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={react} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={chat} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2">
                  <img loading="lazy" src={flutter} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={swift} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={kotline} alt="" width="40px" height="40px" />
                </div>

                <div className="pb-0 pr-4  mx-2">
                  <img loading="lazy" src={ios} alt="" width="40px" height="40px" />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Link to={"/contact-us"}>
                  <button className="step-back_btn ">Book a Demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {active === "cloud" && (
        <div
          className={` ${
            blog
              ? "pl-2 pt-0 pl-md-5 Services-module--service col-md-12 my-2 "
              : "pl-2 pt-0 pl-md-5 Services-module--service col-md-6 my-2"
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">Secure Your Cloud with Remotepad</h2>
              <p className="pb-2">
                Protect your assets and prevent cyberattacks with our
                comprehensive cybersecurity services. We've got the experience
                to keep you safe.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap pb-4">
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={cloud} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={mvc} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={mvcc} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={cloudservice} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={dart} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={flash} alt="" width="40px" height="40px" />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Link to={"/contact-us"}>
                  <button className="step-back_btn ">Book a Demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {active === "game" && (
        <div
          className={` ${
            blog
              ? "pl-2 pt-0 pl-md-5 Services-module--service col-md-12 my-2 "
              : "pl-2 pt-0 pl-md-5 Services-module--service col-md-6 my-2"
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">
                Level Up Your Game/Metaverse Development
              </h2>
              <p className="pb-2">
                Craft captivating gaming experiences with our expert team. Let
                us bring your concepts to life and launch unforgettable gaming
                adventures.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap pb-4">
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={game} alt="" width="40px" height="40px" />
                </div>

                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={tensorflow} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={window} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={vp} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={git} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={se} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={branding} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={app} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={react} alt="" width="40px" height="40px" />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Link to={"/contact-us"}>
                  <button className="step-back_btn ">Book a Demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {active === "block" && (
        <div
          className={` ${
            blog
              ? "pl-2 pt-0 pl-md-5 Services-module--service col-md-12 my-2 "
              : "pl-2 pt-0 pl-md-5 Services-module--service col-md-6 my-2"
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">
                Unlock Blockchain Potential with Remotepad
              </h2>
              <p className="pb-2">
                Step into the decentralized world with our expert blockchain
                consultancy and development services. Let's make your vision a
                reality.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap pb-4">
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={git} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={chat} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2">
                  <img loading="lazy" src={blockchain} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={fullstack} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={f} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={graph} alt="" width="40px" height="40px" />
                </div>
                <div className="pb-0 pr-4  mx-2 ">
                  <img loading="lazy" src={rust} alt="" width="40px" height="40px" />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Link to={"/contact-us"}>
                  <button className="step-back_btn ">Book a Demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Temp;
