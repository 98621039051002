import React from "react";
import { useLocation, useParams } from "react-router-dom";
import "./teamExp.css";
const TeamExp = ({ next, back, err, exp, selectedExp, setSelectedExp }) => {
  const selectqty = (exp) => {
    if (selectedExp === exp) {
      setSelectedExp(0);
    } else {
      setSelectedExp(exp);
    }
  };

  const { id, techTitle } = useParams();

  const nextStep = (e) => {
    if (selectedExp === 0) {
      err("Please select team members.");
      return;
    }
    next();
  };
  const backStep = () => {
    back();
  };
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div className="set-content-flex-center">
      <div className="team-member wrapper">
        <h1 className="set-remote-font text-center my-5  w-md-50 mx-auto fw-bold">
          <>
            How many years <span className="hilight">Experience</span> do you
            want?
          </>
        </h1>

        <p className="text-center px-2 footr_p">
          <>We have following options:</>
        </p>
        <div className="team-num w-md-75 p1-1 mb-2 mx-auto mx-md-5">
          {exp?.map((num, i) => {
            const selected = selectedExp === num;
            return (
              <button
                key={i}
                onClick={() => selectqty(num)}
                value={num}
                className={`select-team-num ${
                  num === ">06" &&
                  "d-block m-auto d-sm-inline select-team-dont "
                } ${selected && "selected"}`}
              >
                {num}
              </button>
            );
          })}
        </div>

        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-none d-md-flex">
          <button className="step-back_btn mx-1 mt-4" onClick={backStep}>
            Back
          </button>
          <button className="step-btn mx-1 px-5 mt-4" onClick={nextStep}>
            Next
          </button>
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-md-none">
          <button className="step-btn mx-1 mt-4" onClick={nextStep}>
            Next
          </button>
          <button className="step-back_btn mx-1 mt-3" onClick={backStep}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamExp;
