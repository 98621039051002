import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Container, Row } from "react-bootstrap";
import "./blog.css";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import UpdatedFooter from "../footer/UpdatedFooter";

const SinglePage = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [postContent, setPostContent] = useState();
  const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { id } = location.state;
    console.log("location", id);
  // const { id } = useParams();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
    setLoading(true);
    let url = `${process.env.REACT_APP_WP_HL_URL}/posts/${id}`;
    axios
      .get(url)
      .then((res) => {
        setPostContent(res?.data);
        setLoading(false);
      })
      .catch((err) => alert("Something went wrong"));
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  console.log("content", postContent);
  return (
    <React.Fragment>
      {showTopBtn && (
        <div className="toTop" style={{ marginTop: "50%" }} onClick={goToTop}>
          &#10148;
        </div>
      )}

      <Header customStyle="custom-padding" />

      {loading && (
        <div style={{ height: "100vh", paddingTop: "15%" }}>
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          <div className="feature-image-wrapper">
            <img src={postContent?.featured_src?.featured_src?.url} alt="" />
          </div>
          <div className="custom-card-main">
            <Container>
              <div className="row custom-card-inner">
                <div className="d-flex mb-3 justify-content-left">
                  <h2 className="fw-bold text-center">
                    {postContent?.title?.rendered}
                  </h2>
                </div>
                <div
                  className="col-xl-12 col-lg-12 col-md-12 col-sm-12 blog-content"
                  dangerouslySetInnerHTML={{
                    __html: postContent?.content?.rendered,
                  }}
                ></div>
              </div>
            </Container>
          </div>
        </>
      )}
      {/* <Footer2 /> */}
      <UpdatedFooter/>
    </React.Fragment>
  );
};

export default SinglePage;
