import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./ourclientanimation-2.css";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import { developer, techs } from "../SkillsSlider/developersData";
import { Link, useParams } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getFirstThreeAsCommaSeparatedString } from "../../utils/getStringFromArray";
import { ReactComponent as SlideNextIcon } from "../Assets/slideNextIcon.svg";
import { ReactComponent as SlidePrevIcon } from "../Assets/slidePrevIcon.svg";
import { getTechTitleFromUrl } from "../../utils/getTechTitleFromUrl";

SwiperCore.use([Autoplay, Navigation]);
function Ourdeveloper_2_slider({ setCurrentSlideData, currentSlideData }) {
  const currentSwiper = useRef();
  // Initialize with the data of the first slide
  const { techTitle } = useParams();
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const tectTitleKey = getTechTitleFromUrl(techTitle);
  const filteredDevelopers = techTitle
    ? developer?.filter((item) => item?.technology?.includes(tectTitleKey))
    : developer;
  const [techName] = techs.filter((item) => item.path === tectTitleKey);

  const nextSlide = () => {
    currentSwiper.current.slideNext();
  };
  const prevSlide = () => {
    currentSwiper.current.slidePrev();
  };

  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex;
    setCurrentSlideData(filteredDevelopers[currentSlideIndex - 1]);
  };

  return (
    <div className="custom-swiper-container">
      <Swiper
        spaceBetween={30}
        loop={true}
        centeredSlides={true}
        effect="fade"
        autoplay={{
          delay: 1000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        // navigation={true}
        speed={3000}
        className="mySwiper_2"
        pause
        // ref={currentSwiper}
        onSwiper={(it) => (currentSwiper.current = it)}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
      >
        {filteredDevelopers?.map((dev, index) => (
          <SwiperSlide className="swiper-card my-5 ">
            <div className="justify-content-center align-content-center slide ">
              <div className=" justify-content-between align-content-center px-1">
                <div
                  key={index}
                  className="card custom_card_new m-0 d-flex flex-column border rounded-3 align-items-center w-100"
                >
                  <div className="d-flex flex-column align-items-center w-100">
                    <div className="exp-bar">
                      <p>5 Years of Experience</p>
                    </div>
                    <div className="dev-image-wrapper">
                      <CircularProgressbarWithChildren value={71}>
                        <img src={dev.image} alt="doge" />
                      </CircularProgressbarWithChildren>
                    </div>
                    <p className="dev-name-bar">
                      {capitalizeFirstLetter(dev.name)}
                    </p>
                    <p className="dev-stack-bar">
                      {capitalizeFirstLetter(dev.category)}
                    </p>
                    <div className="dev-tech-bar d-flex justify-content-between w-100">
                      <p className="dev-tech-bar-child1">Technology</p>
                      <div className="dev-stack-string">
                        <p className="dev-tech-bar-child2">
                          {getFirstThreeAsCommaSeparatedString(dev.stack)}
                        </p>
                      </div>
                    </div>
                    <div className="dev-tech-bar d-flex justify-content-between w-100">
                      <p className="dev-tech-bar-child1">Rating</p>
                      <p className="dev-tech-bar-child2">76% Positive Rated</p>
                    </div>
                  </div>
                  <Link to={`/hire-me-form/${dev?.technology[0]}-developer`}>
                    <button
                      className="step-btn"
                      style={{ marginTop: "3rem" }}
                      onClick={() => {
                        localStorage.setItem("devDetails", JSON.stringify(dev));
                      }}
                    >
                      Hire Me
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Ourdeveloper_2_slider;
