import React from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import react from "../Assets/react.png";
import js from "../Assets/js.png";
import angular from "../Assets/node-js.png";
import mozam from "../Assets/Ellipse 3.webp";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import { developer } from "../SkillsSlider/developersData";
import { getFirstThreeAsCommaSeparatedString } from "../../utils/getStringFromArray";
SwiperCore.use([Autoplay, Navigation]);

const BlogSlider = () => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <Swiper
      spaceBetween={30}
      loop={true}
      centeredSlides={true}
      effect="fade"
      autoplay={{
        delay: 1000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      navigation={false}
      speed={3000}
      className="mySwiper_2 blog-swiper"
    >
      {" "}
      {developer?.map((item, index) => (
        <SwiperSlide className="swiper-card   ">
          <div className="justify-content-center align-content-center slide blog-slider">
            <div className=" justify-content-between align-content-center px-1">
              <div className="card custom_card_new m-0 d-flex flex-column border rounded-3 align-items-center">
                {/* <div className="card_details d-flex justify-content-between w-100">
                  <div className="experience">
                    <span className="fw-bold text-success">
                      {item?.EmpExp}{" "}
                    </span>{" "}
                    Years +
                    <br /> Experience
                  </div>
                  <div className="profile-img-warapper">
                    <CircularProgressbarWithChildren value={71}>
                      <img src={mozam} alt="doge" />
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="dev-score">
                    <h3 className="text-blue">71%</h3> score
                  </div>
                </div>

                <h5 className="fw-bold title-blue">{item?.name}</h5>
                <strong>{item?.category}</strong>
                <div className="languages w-100 mb-0 d-flex justify-content-between">
                  {item.stack.slice(0, 3).map(({ name, image }, techIndex) => (
                    <div key={techIndex} className="technology">
                      <div className="techicon w-100  d-flex align-items-center gap-2">
                        <div className="skills_img">
                          <img src={image} alt="" />
                        </div>

                        <p className="m-0  text-stack">{name}</p>
                      </div>
                    </div>
                  ))}
                </div> */}
                <div className="d-flex flex-column align-items-center w-100">
                  <div className="exp-bar">
                    <p>5 Years of Experience</p>
                  </div>
                  <div className="dev-image-wrapper">
                    <CircularProgressbarWithChildren value={71}>
                      <img src={item.image} alt="doge" />
                    </CircularProgressbarWithChildren>
                  </div>
                  <p className="dev-name-bar">
                    {capitalizeFirstLetter(item.name)}
                  </p>
                  <p className="dev-stack-bar">
                    {capitalizeFirstLetter(item.category)}
                  </p>
                  <div className="dev-tech-bar d-flex justify-content-between w-100">
                    <p className="dev-tech-bar-child1">Technology</p>
                    <div className="dev-stack-string">
                      <p className="dev-tech-bar-child2">
                        {getFirstThreeAsCommaSeparatedString(item.stack)}
                      </p>
                    </div>
                  </div>
                  <div className="dev-tech-bar d-flex justify-content-between w-100">
                    <p className="dev-tech-bar-child1">Rating</p>
                    <p className="dev-tech-bar-child2">76% Positive Rated</p>
                  </div>
                </div>

                <Link to={`/hire-me-form/${item?.technology}-developer`}>
                  <button
                    onClick={() => {
                      localStorage.setItem("devDetails", JSON.stringify(item));
                    }}
                    className="step-btn my-3"
                  >
                    Hire Me
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BlogSlider;
