import React, { useState } from "react";
import "./custoomerslider.css";

const Arrows = ({ onClick, direction }) => {
  const iconColor = "blue"; // Change this to the desired icon color
  const backgroundColor = "transparent"; // Change this to the desired background color

  const nextArrowPath =
    "M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z";

  const prevArrowPath =
    "M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z";
  const [arrowColor, setArrowColor] = useState("#000000");
  return (
    <div
      className={`custom-arrow custom-${direction}`}
      onClick={onClick}
      onMouseEnter={() => setArrowColor("#ffffff")}
      onMouseLeave={() => setArrowColor("#000000")}
    >
      <div className="">
        <svg
          fill={arrowColor}
          height="30px"
          width="30px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 330 330"
          xml="preserve"
          stroke="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              id="XMLID_222_"
              d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"
            ></path>{" "}
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Arrows;
