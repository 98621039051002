const Scope = [
    {
        id:1,
        stack:'Senior Fullstack Engineer',
        skills: [            
            'Swift',
            'Html/Css',
            'Vue Js',
            'React Js',
            'JavaScript',
            'Java',
            'wordpress',
            'Flutter',
            'Android',
            'Node Js',
            'PHP',
            'Bootstrap5',
            'React Native',
            'Rails',
            'Python',
            'Laravel',
        ]
    },
    {
        id:2,
        stack:'Mid-level Fullstack Engineer',
        skills: [            
            'Swift',
            'Html/Css',
            'Vue Js',
            'React Js',
            'JavaScript',
            'Java',
            'wordpress',
            'Flutter',
            'Android',
            'Node Js',
            'PHP',
            'Bootstrap5',
            'React Native',
            'Rails',
            'Python',
            'Laravel',
        ]
    },
    {
        id:3,
        stack:'Junior Fullstack Engineer',
        skills: [            
            'Swift',
            'Html/Css',
            'Vue Js',
            'React Js',
            'JavaScript',
            'Java',
            'wordpress',
            'Flutter',
            'Android',
            'Node Js',
            'PHP',
            'Bootstrap5',
            'React Native',
            'Rails',
            'Python',
            'Laravel',
        ]
    },
    {
        id:4,
        stack:'Senior Backend Engineer',
        skills: [
            'C#',
            'Aws',
            'Swift',
            'Python',
            'Java',
            'Flutter',
            'Android',
            'Node Js',
            'PHP',
            'Rails',
            'Laravel',
            'MySQL',
            'MongoDB',
        ]
    },
    {
        id:5,
        stack:'Mid-level Backend Engineer',
        skills: [
            'C#',
            'Aws',
            'Swift',
            'Python',
            'Java',
            'Flutter',
            'Android',
            'Node Js',
            'PHP',
            'Rails',
            'Laravel',
            'MySQL',
            'MongoDB',
        ]
    },
    {
        id:6,
        stack:'Junior Backend Engineer',
        skills: [
            'C#',
            'Aws',
            'Swift',
            'Python',
            'Java',
            'Flutter',
            'Android',
            'Node Js',
            'PHP',
            'Rails',
            'Laravel',
            'MySQL',
            'MongoDB',
        ]
    },
    {
        id:7,
        stack:'Senior Frontend Developer',
        skills: [
            'Html/Css',
            'Vue Js',
            'React Js',
            'JavaScript',
            'wordpress',
            'Bootstrap5',
            'React Native',
        ]
    },
    {
        id:8,
        stack:'Mid-level Frontend Developer',
        skills: [
            'Html/Css',
            'Vue Js',
            'React Js',
            'JavaScript',
            'wordpress',
            'Bootstrap5',
            'React Native',
        ]
    },
    {
        id:9,
        stack:'Junior Frontend Developer',
        skills: [
            'Html/Css',
            'Vue Js',
            'React Js',
            'JavaScript',
            'wordpress',
            'Bootstrap5',
            'React Native',
        ]
    },
    {
        id:10,
        stack:'Senior WordPress Developer',
        skills: [
            'Html/Css',
            'PHP',
            'JavaScript',
            'Elementor',
            'Divi',
            'Beaver',
            'SQL',
            'MySQL',
            'SEO',
        ]
    },
    {
        id:11,
        stack:'Mid-level WordPress Developer',
        skills: [
            'Html/Css',
            'PHP',
            'JavaScript',
            'Elementor',
            'Divi',
            'Beaver',
            'SQL',
            'MySQL',
            'SEO',
        ]
    },
    {
        id:12,
        stack:'Junior WordPress Developer',
        skills: [
            'Html/Css',
            'PHP',
            'JavaScript',
            'Elementor',
            'Divi',
            'Beaver',
            'SQL',
            'MySQL',
            'SEO',
        ]
    },
    {
        id:13,
        stack:'Senior Graphic Designer',
        skills: [
            'Photoshop',
            'InDesign',
            'Illustrator ',
            'UI,UX',
            'Figma',
            'XD',
            'Typography',
            'Branding',
        ]
    },
    {
        id:14,
        stack:'Mid-level Graphic Designer',
        skills: [
            'Photoshop',
            'InDesign',
            'Illustrator ',
            'UI,UX',
            'Figma',
            'XD',
            'Typography',
            'Branding',
        ]
    },
    {
        id:15,
        stack:'Junior Graphic Designer',
        skills: [
            'Photoshop',
            'InDesign',
            'Illustrator ',
            'UI,UX',
            'Figma',
            'XD',
            'Typography',
            'Branding',
        ]
    },
    {
        id:16,
        stack:'Quality Assurance',
        skills: [
            'Functional testing',
            'Quality control',
            'QA testing',
            'Quality Control',
            'Functionality Testing',
            'Automation Testing',
            'Selinium',
            'API Testing',
            'Postman',
        ]
    }
]

export default Scope;