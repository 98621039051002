import React, { useState } from "react";
import Header from "../Header/Header";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./Contact.css";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdatedFooter from "../footer/UpdatedFooter";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [varified, setVarified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setError] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [verified, setVerified] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const navigate = useNavigate();

  const handleOnchange = (e) => {
    setmyform({
      ...myform,
      [e.target.name]: e.target.value,
    });
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }

  function onChangeReCaptcha(value) {
    console.log("Captcha value:", value);
    setVerified(true);
    if (verified) {
      setCaptchaError(false);
    }
  }

  const [myform, setmyform] = useState({
    myformName: "",
    myformEmail: "",
    mytext: "",
  });

  const sheetName = "ContactUs";
  const state = {
    Name: myform?.myformName || "",
    Email: myform?.myformEmail || "",
    Text: myform?.mytext || "",
    SheetName: sheetName,
  };

  const sendForm = () => {
    setLoading(true);
    const formData = new FormData();
    for (const key in state) {
      if (state.hasOwnProperty(key)) {
        const value = state[key];

        if (Array.isArray(value)) {
          // If the value is an array, append each element separately
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[${i}]`, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    fetch(
      // "https://script.google.com/macros/s/AKfycbxn_3gN6ndF4Oc-OSMwS-TEmYM-p09iDN0DRDjkgLyslC9-0QfBOgx-cFSbLE_rFRx8/exec",
      process.env.REACT_APP_GSHEET_URL,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        res.json();
        toast("Successful");
        setLoading(false);
        setmyform({
          myformEmail: "",
          myformName: "",
          mytext: "",
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // const sheet = "Contact Us";

  // const sendForm = () => {
  //   setLoading(true);
  //   fetch(`https://sheetdb.io/api/v1/auy68oitjj3jf/?sheet=${sheet} `, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       data: [
  //         {
  //           FullName: state.FullName,
  //           Email: state.Email,
  //           Text: state.Text,
  //         },
  //       ],
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => console.log(data))
  //     .then(() => setLoading(false))
  //     .then(() => toast("Successful"))
  //     .catch((e) => console.log(e));
  // };
  const removeErr = () => {
    setTimeout(() => {
      setError(0);
      setErrMsg("");
    }, 5000);
  };

  const submitData = (e) => {
    e.preventDefault();
    var filter =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (myform.myformName === "") {
      setErrMsg("Full name required!");
      setError(1);
      removeErr();
      return;
    }
    if (!isNaN(myform.myformName)) {
      setErrMsg("Numerical value is not allowed!");
      setError(1);
      removeErr();
      return;
    }

    if (myform.myformEmail === "") {
      setErrMsg("Email required!");
      setError(2);
      removeErr();
      return;
    }
    if (!filter.test(myform.myformEmail)) {
      setErrMsg("Please provide a valid email address");
      setError(2);
      removeErr();
      return;
    }
    if (!verified) {
      setCaptchaError(true);
      return;
    }
    sendForm();
  };

  return (
    <>
      <Header />
      {loading && <Spinner />}
      {!loading && (
        <Container className="pt-5">
          <Row className="contactus">
            <Col md={12} lg={8}>
              <div className="contact-left">
                <h2 className="pt-sm-4 text-center">
                  Welcome! Please feel free to connect with us.
                </h2>
                <p className="mb-3 text-center">
                  Nevermore than a click or a phone call away, we’re always here
                  to hear from you and answer any queries you may have!
                </p>
                <p className="mb-0 text-center">
                  Contact us and relieve your business stress.
                </p>
              </div>
              <section className="offices-container">
                <div className="main-container">
                  <div className="inner-box">
                    <div className="icon-box">
                      <a href="#">
                        <i class="fa-solid fa-location-dot"></i>
                      </a>
                    </div>
                    <div className="icone-description">
                      <h3>Head Office</h3>
                      <p>
                        1317 EDGEWATER DR STE 2453, Orlando, FL, US, 32804-6350
                      </p>
                    </div>
                  </div>
                  <div className="inner-box">
                    <div className="icon-box">
                      <a href="#">
                        <i class="fa-solid fa-location-dot"></i>
                      </a>
                    </div>
                    <div className="icone-description">
                      <h3>Sweden Office</h3>
                      <p>Vaderlisgatan 59 LGH 1602/71, 418 36 Göteborg</p>
                    </div>
                  </div>
                </div>
              </section>
            </Col>
            <Col md={12} lg={4}>
              <div className="formData">
                <div className="data">
                  <div className="inner_content">
                    <h3 className="heading mb-0 pt-5 pb-1">Drop us a Line</h3>
                    <Form onSubmit={submitData}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          required
                          maxLength="20"
                          type="text"
                          className="control_input"
                          placeholder="Name"
                          name={"myformName"}
                          value={myform.myformName}
                          onChange={handleOnchange}
                        />
                        <small className="redmsg">{err === 1 && errMsg}</small>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          required
                          className="control_input"
                          type="email"
                          placeholder="Email"
                          name={"myformEmail"}
                          value={myform.myformEmail}
                          onChange={handleOnchange}
                        />
                        <small className="redmsg">{err === 2 && errMsg}</small>
                      </Form.Group>
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          required
                          maxLength="20"
                          className="control_input"
                          as="textarea"
                          rows={3}
                          placeholder="Write Us Your Query"
                          value={myform.mytext}
                          name={"mytext"}
                          onChange={handleOnchange}
                        />
                        <small className="redmsg">{err === 3 && errMsg}</small>
                      </Form.Group>
                      {/* <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        onChange={onChange}
                      /> */}
                      <small className="redmsg">{err === 4 && errMsg}</small>
                      <div className="mt-3">
                        <ReCAPTCHA
                          sitekey="6LcsgmopAAAAAMTyP7jnxKeb-fr02SURarYrCVMS"
                          onChange={onChangeReCaptcha}
                        />
                      </div>

                      <div className=" d-flex justify-content-center py-5">
                        <button type="submit" className="step-btn">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {/* <Footer2 /> */}
      <UpdatedFooter />
      <ToastContainer />
    </>
  );
};

export default ContactUs;
