import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import whatsapp from "../Assets/whatsapp.png";
import messanger from "../Assets/messanger.png";
import LinkedIn from "../Assets/linkedin.png";
import Facebook from "../Assets/faceboook.png";
import Instagram from "../Assets/insta.png";
import Email from "../Assets/email.png";
import Skype from "../Assets/skypee.png";
import Gmail from "../Assets/google.png";

const ModalComponent = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="medium"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <a href="#" className="align-items-center my-2 gap-2 d-flex">
                <img src={whatsapp} alt="" height="30px" />
                Whatsapp
              </a>
            </div>
            <div className="col-md-4">
              <a href="#" className="align-items-center my-2 gap-2 d-flex">
                <img src={messanger} alt="" height="30px" />
                Messanger
              </a>
            </div>
            <div className="col-md-4">
              <a href="#" className="align-items-center my-2 gap-2 d-flex">
                <img src={LinkedIn} alt="" height="30px" />
                LinkedIn
              </a>
            </div>
            <div className="col-md-4">
              <a href="#" className="align-items-center my-2 gap-2 d-flex">
                <img src={Facebook} alt="" height="30px" />
                Facebook
              </a>
            </div>
            <div className="col-md-4">
              <a href="#" className="align-items-center my-2 gap-2 d-flex">
                <img src={Instagram} alt="" height="30px" />
                Instagram
              </a>
            </div>
            <div className="col-md-4">
              <a href="#" className="align-items-center my-2 gap-2 d-flex">
                <img src={Email} alt="" height="30px" />
                Email
              </a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalComponent;
