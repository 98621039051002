import React from "react";

function CustomTimePicker({ field, form, label, ...props }) {
  return (
    <div className="info set-my-input-relative" key="1">
      <label htmlFor="basic-url" className="set-my-label form-label">
        {label}
      </label>
      <input
        type="time"
        {...field}
        {...props}
        className="form-control form-control-lg"
      />
    </div>
  );
}

export default CustomTimePicker;
