import React, { useState, useEffect } from "react";
import "../hireform/hire-form.css";
import Header from "../hireform/Headerform";
import Footer from "../hireform/footerform/Footer";
import HireMeForm from "./HireMeForm";
import { developer } from "../SkillsSlider/developersData";
import { Navigate, useParams } from "react-router-dom";
import ThankYou from "./ThankYou";
import Progress from "../hireform/progress/Progress";
import ProgressForHireMe from "../hireform/progress/ProgressForHireMe";
import TeamQty from "../hireform/teamQty/TeamQty";
import AboutHiringcandidate from "../hireform/abouHiringcompany/AboutHiringcompany.jsx";
import HireMeSkill from "../hireform/skillsFrom/HireMeSkill.jsx";
import TeamExp from "../hireform/teamExp/teamExp.jsx";
import EngrCard from "../engrTeamSection/EngrCard.jsx";
import { processString } from "../../utils/urlFilter.js";
import UpdatedFooter from "../footer/UpdatedFooter.jsx";

const HireMe = () => {
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [data, setData] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedQty, setSelectedQty] = useState(0);
  const [selectedExp, setSelectedExp] = useState(0);
  const [devName, setDevName] = useState("");
  const [devCategory, setDevCategory] = useState("");
  const engrDetails = JSON.parse(localStorage.getItem("devDetails"));

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (engrDetails) {
      // const details = JSON.parse(localStorage.getItem("devDetails"));
      setStep(3);
      setSelectedExp(engrDetails?.EmpExp);
      setSelectedQty("01");
      setSelectedSkills(engrDetails?.stack?.map((item) => item.name));
      setDevName(engrDetails?.name);
      setDevCategory(engrDetails?.technology);
      console.log("devDetails", engrDetails);
    }

    return () => {
      localStorage.removeItem("devDetails");
      setStep(0);
      setSelectedExp("");
      setSelectedQty("");
      setSelectedSkills([]);
      setDevName("");
    };
  }, []);

  const skills = [
    "C#",
    "Aws",
    "Swift",
    "Html/Css",
    "Vue Js",
    "React Js",
    "JavaScript",
    "Java",
    "wordpress",
    "Flutter",
    "Android",
    "Node Js",
    "PHP",
    "Bootstrap5",
    "React Native",
    "MySQL",
    "MongoDB",
    "Rails",
    "Python",
    "Laravel",
  ];

  const qty = ["02", "03", "04", "05", "06", "07", "don't know"];
  const exp = ["01", "02", "03", "04", "05", "06", ">06"];

  const [no, setNo] = useState("");

  // MY SECTION SETTING
  const [myform, setmyform] = useState({
    id: new Date().getTime().toString(),
    myformName: "",
    myformLastName: "",
    myformEmail: "",
    myformSkypeId: "",
    myformContact: "",
    myformcompIndustry: "",
    myformcompLoc: "",
    myformcompEmp: "",
  });
  // END MY SECTION SETTING

  const [compAdd, setCompAdd] = useState({
    compName: "",
    compPhone: no,
    compEmail: "",
    compLoc: "",
    compEmps: "",
    compEngrs: "",
  });

  const [custAdd, setCustAdd] = useState({
    custName: "",
    custLoc: "",
    custEmail: "",
    custSkypeId: "",
  });

  const totalStep = 4;

  const clearData = () => {
    setSelectedTeams([]);
    setSelectedSkills([]);
    setSelectedQty(0);
    setSelectedExp(0);
    setNo("");
    setData([]);

    // MY SECTION SETTING
    setmyform({
      myformName: "",
      myformDesignation: "",
      myformEmail: "",
      myformSkypeId: "",
      myformcompName: "",
      myformcompIndustry: "",
      myformcompLoc: "",
      myformcompEmp: "",
    });
    // END MY SECTION SETTING

    setCompAdd({
      compEmail: "",
      compEmps: "",
      // compEngrs: '',
      compLoc: "",
      compName: "",
      compPhone: "",
    });
    setCustAdd({
      custEmail: "",
      custLoc: "",
      custName: "",
      custSkypeId: "",
    });
  };
  const sheet = "hire me";
  const showErr = (msg) => {
    setErrMsg(msg);
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 2000);
  };

  const stepChange = () => {
    if (step === 4) {
      return;
    }
    setStep((prev) => prev + 1);
  };

  const stepBack = () => {
    if (step === 0) {
      setStep(0);
      return;
    }
    setStep((prev) => prev - 1);
  };

  console.log(step);

  const { id } = useParams();
  const { techTitle } = useParams();

  let categoryTitle = processString(techTitle);

  const [filteredDeveloper] = developer.filter((item) => item.id === +id);
  console.log("filter wala ", filteredDeveloper);
  // const data = {
  //   "Developer's Experience": filteredDeveloper?.EmpExp,
  //   "Developer's Full Name": filteredDeveloper?.name,
  //   "Developer's Stack": filteredDeveloper?.stack,
  //   "Developer's Email": filteredDeveloper?.email,
  //   "Developer's Technology": filteredDeveloper?.technology,
  // };
  var percentage = ((step / totalStep) * 100).toFixed(0);
  percentage = percentage < 0 ? 0 : percentage;
  percentage = percentage > 100 ? 100 : percentage;

  console.log("percentage", percentage);

  return (
    <div className="container" style={{ position: "relative" }}>
      <Header />
      <div className="set-main-error">
        {error ? <p className="error-msg">{errMsg}</p> : ""}
      </div>
      <div className="formHeight">
        {engrDetails ? (
          <EngrCard
            item={engrDetails}
            exp={engrDetails?.EmpExp}
            name={engrDetails?.name}
            path={"#"}
            devProfile={engrDetails?.image}
            skills={engrDetails?.stack}
            title={engrDetails?.category}
            hideBtn={true}
          />
        ) : (
          <Progress progress={percentage} stp={step} />
        )}

        {step === 0 ? (
          <HireMeSkill
            next={stepChange}
            skills={skills}
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            back={stepBack}
            err={showErr}
            data={data}
            clearData={clearData}
          />
        ) : step === 1 ? (
          <TeamQty
            next={stepChange}
            qty={qty}
            selectedQty={selectedQty}
            setSelectedQty={setSelectedQty}
            back={stepBack}
            err={showErr}
          />
        ) : step === 2 ? (
          <TeamExp
            next={stepChange}
            exp={exp}
            selectedExp={selectedExp}
            setSelectedExp={setSelectedExp}
            back={stepBack}
            err={showErr}
          />
        ) : step === 3 ? (
          <AboutHiringcandidate
            val={no}
            setNo={setNo}
            compAdd={compAdd}
            custAdd={custAdd}
            setCompAdd={setCompAdd}
            myform={myform}
            setmyform={setmyform}
            next={stepChange}
            back={stepBack}
            err={showErr}
            selectedQty={selectedQty}
            selectedExp={selectedExp}
            selectedSkills={selectedSkills}
            selectedTeams={selectedTeams}
            devName={devName}
            category={devCategory ? devCategory : categoryTitle}
            clearData={clearData}
          />
        ) : (
          step === 4 && <ThankYou next={stepChange} />
        )}
      </div>
      {/* <Footer2 faq={false} /> */}
      <UpdatedFooter />
    </div>
  );
};

export default HireMe;
