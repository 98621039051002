import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const ThankYou = ({ next }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
      toast("Successful");
    }, 3000);
  }, []);
  const nextStep = () => {
    next();
  };

  return (
    <div className="my-5 set-align-center-thankyou">
      <div>
        <h1 className=" display-2  text-center fw-bold">Thank You Note:</h1>
        {/* <p className="text-center px-3 mx-cust">
          Thank You for reaching out to us!{" "}
        </p> */}
        <p className="text-center px-3  mx-cust">
          Thank you for taking the first step toward unlocking your project's
          potential. Our team is thrilled to connect with you and explore how we
          can help bring your vision to life. Our experts will be in touch soon
          to embark on this exciting adventure together. Let the innovation
          begin!{" "}
        </p>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5">
          <Link to="/">
            <button className="step-btn mx-1 mt-4" onClick={nextStep}>
              Back To Home
            </button>
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ThankYou;
