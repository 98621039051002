import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Header from "../Header/Header";
import { Container } from "react-bootstrap";
import CustomCard from "../Cards";
import CardImage from "../../components/Cards";
import "./blog.css";
import ModalComponent from "../Modal/Modal";
import Spinner from "../Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { postsHandler } from "../../store/slices/blogSlice";
import Developerstack from "../developerstack/Developerstack";
import Readytoget from "../readytogetsection/Readytoget";
import { useParams } from "react-router";
import { developer } from "../SkillsSlider/developersData";
import BlogSlider from "./BlogSlider";
import DevelopmentServices from "../engrTeamSection/DevelopmentServices";
import UpdatedFooter from "../footer/UpdatedFooter";

const blogs = [
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
  {
    img: CardImage,
    data: "2022-07-15",
    title: "What is the cost of Engineer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Loream Ipsum has been the industry’s.",
  },
];

const BlogPage = () => {
  const [visibleRows, setVisibelRows] = useState(6);
  const [modalShow, setModalShow] = useState(false);
  const [blogCategory, setBlogCategory] = useState("all-articles");
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const blogs = useSelector((state) => state?.blogPosts?.posts);
  const showLessRows = () => {
    setVisibelRows((prevCount) => prevCount - 2);
  };
  const showMoreRows = () => {
    setVisibelRows((prevCount) => prevCount + 2);
  };
  const blogCategories = [
    {
      name: "RemotePad",
      slug: "all-articles",
    },
    {
      name: "Developers",
      slug: "developer",
    },
    {
      name: "Founders and Tech Leads",
      slug: "founder",
    },
    {
      name: "Recruiters",
      slug: "recruiter",
    },
  ];

  const handleFilter = (slug) => {};

  // function filterObjectsByCategory(searchString) {
  //   console.log("searchString", searchString);
  //   blogs.forEach((obj) => {
  //     // Check if any category name matches the search string
  //     const categoryNames = obj.featured_src.categories.map((category) =>
  //       category.name.toLowerCase()
  //     );
  //     console.log("categoryNames", categoryNames);
  //     const searchLowerCase = searchString.toLowerCase();
  //     if (
  //       categoryNames.some((categoryName) => categoryName === searchLowerCase)
  //     ) {
  //       console.log(obj);
  //     }
  //   });
  // }

  function filterObjectsByCategory(searchString) {
    setBlogCategory(searchString);
    if (searchString === "all-articles") {
      setFilteredBlogs([]);
    } else {
      const filteredData = blogs?.filter((item) => {
        // Check if the searchString matches any category name inside featured_src
        return item.featured_src.categories?.some((category) => {
          return category.name
            ?.toLowerCase()
            ?.includes(searchString?.toLowerCase());
        });
      });
      setFilteredBlogs(filteredData);
      console.log(filteredData);
    }
  }

  useEffect(() => {
    if (blogs?.length === 0) {
      setLoading(true);
      let url = `${process.env.REACT_APP_WP_HL_URL}/posts?categories=8`;
      axios
        .get(url)
        .then((res) => {
          setLoading(false);
          dispatch(postsHandler(res?.data));
          console.log("Posts => ", res?.data);
        })
        .catch((err) => {
          alert("Something went wrong");
          console.log(err);
          setLoading(false);
        });
    }
    // let url = `${process.env.REACT_APP_WP_HL_URL}/categories`;
    // axios
    //   .get(url)
    //   .then((res) => console.log("categories", res?.data))
    //   .catch((err) => console.log("Error", err));
  }, []);

  // console.log(blogCategory);
  // console.log("Redux", blogs);
  // console.log("categories =>", blogCategory);
  const { techTitle } = useParams();
  const filteredDevelopers = developer.filter((item) =>
    item.technology.includes(techTitle)
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [techTitle]);

  // console.log("filteredBlogs", filteredBlogs);
  // console.log("filteredBlogs >= 1", filteredBlogs.length >= 1);
  return (
    <React.Fragment>
      <Header customStyle="custom-padding" />
      {loading && (
        <div style={{ height: "100vh", paddingTop: "15%" }}>
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          <div className="tabs hero-content p-0">
            <div className="categories-wrapper">
              {blogCategories?.map((button) => (
                <button
                  onClick={() => filterObjectsByCategory(button?.slug)}
                  className={`${
                    blogCategory === button?.slug ? "step-btn" : "step-back_btn"
                  }`}
                >
                  {button.name}
                </button>
              ))}
            </div>
            <Container>
              <div className="row mt-3  custom-card sticky-slider-container position-relative">
                <div className="col-lg-12 col-md-12 row h-100 p-0 p-sm-2">
                  {(filteredBlogs.length >= 1 ? filteredBlogs : blogs)
                    ?.slice(
                      0,
                      visibleRows
                        ? visibleRows < blogs?.length
                          ? visibleRows
                          : blogs?.length
                        : 4
                    )
                    .map((item, index) => (
                      <div
                        key={index}
                        className="col-sm-6 mb-sm-4 col-lg-6 p-0 p-sm-2"
                      >
                        <CustomCard
                          img={item?.featured_src?.featured_src?.url}
                          title={item?.title?.rendered}
                          date={moment(item?.date).format("YYYY-MM-DD")}
                          content={item?.featured_src?.excerpt}
                          link={`/blog/${item?.slug}`}
                          buttonText="Read More"
                          blogId={item?.id}
                        />
                      </div>
                    ))}
                  <div className="d-flex justify-content-center gap-3 pb-4">
                    {visibleRows > 4 && (
                      <button className="btn-show-more" onClick={showLessRows}>
                        <i class={`fas fa-arrow-left`}></i>
                        Show Less
                      </button>
                    )}
                    {visibleRows < blogs.length && (
                      <button className="btn-show-more" onClick={showMoreRows}>
                        Show More
                        <i class={`fas fa-arrow-right`}></i>
                      </button>
                    )}
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-12">
                  <DevelopmentServices blog={true} />
                  <BlogSlider id="blog-slider" />
                </div> */}
              </div>
            </Container>
          </div>
          <ModalComponent show={modalShow} onHide={() => setModalShow(false)} />
          <div className="mb-5">
            <Developerstack />
          </div>
          <Readytoget devlopers={filteredDevelopers} />
          {/* <Footer2 /> */}
          <UpdatedFooter />
        </>
      )}
    </React.Fragment>
  );
};

export default BlogPage;
