import React, { useState } from "react";
import "./remoteTeam.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { skillsHandler } from "../../../store/slices/hireTeam";
import SearchBar from "../../Searchbar/Searchbar";

const RemoteTeam = ({
  next,
  back,
  err,
  teams,
  findskills,
  delskills,
  Scope,
  selectedTeams,
  setSelectedTeams,
}) => {
  const [items, setItems] = useState(Scope);
  const dispatch = useDispatch();
  // const [selectedTeams, setSelectedTeams] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleSearch = (searchTerm) => {
    // Filter the teams based on the search term
    const filteredItems = Scope.filter((team) =>
      team.stack.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setItems(filteredItems);
  };
  const selectTeam = (team) => {
    console.log("TEAMS");
    if (selectedTeams.includes(team)) {
      setSelectedTeams([...selectedTeams.filter((t) => t !== team)]);
    } else {
      setSelectedTeams([...selectedTeams, team]);
    }
  };

  const nextStep = () => {
    if (selectedTeams.length === 0) {
      err("Please select at least one remote team.");
      return;
    } else {
      next();
    }
  };

  const backStep = () => {
    back();
  };

  const selectstack = (team) => {
    findskills(team);
  };

  const delstack = (id) => {
    delskills(id);
  };

  const location = useLocation();

  return (
    <div className="set-content-flex-center">
      <div className="wrapper">
        <h1 className="set-remote-font text-center px-3 my-5 w-md-50 mx-auto remote-team fw-bold">
          {location.pathname === "/join-a-team" ? (
            <>What you can do for Remote team?</>
          ) : (
            <>Select top-notch engineers according to your need.</>
          )}
        </h1>
        <SearchBar onSearch={handleSearch} />
        <div className="pt-1 select-dev">
          {items.map((team, i) => {
            const selected = selectedTeams.includes(team.stack);
            return (
              <button
                onClick={() => {
                  selectTeam(team.stack);
                  selected ? delstack(team.id) : selectstack(team.id);
                }}
                id={`team_${team.id}`}
                value={`team_${team.stack}`}
                key={i}
                className={`select-dev-btn ${selected && "selected"}`}
              >{`${team.stack}`}</button>
            );
          })}
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-2  d-none d-md-flex">
          <button className="step-btn px-5 mt-3" onClick={nextStep}>
            Next
          </button>
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-2 d-md-none">
          <button className="step-btn mx-1 mt-3" onClick={nextStep}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoteTeam;
