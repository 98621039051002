import React, { useState } from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div>
      <style>
        {`
          progress {
            height: 10px;
            width: 100%;
            border-radius: 0.25rem;
            appearance: none;
          }

          progress::-webkit-progress-value {
            background-color:#2F83CB;
            border-radius: 0.25rem;
          }

          progress::-webkit-progress-bar {
            background-color:#e1e1e14f;
            border-radius: 0.25rem;
            border:none;
          }

          progress::-moz-progress-bar {
            background-color:#2F83CB;
          }

          progress::-ms-fill {
            background-color:#2F83CB;
          }
        `}
      </style>
      <progress value={progress} max="100"></progress>
    </div>
  );
};

export default ProgressBar;
