import React from "react";
import { useLocation, useParams } from "react-router-dom";
import "./teamQty.css";
const TeamQty = ({ next, back, err, qty, selectedQty, setSelectedQty }) => {
  const selectqty = (qty) => {
    if (selectedQty === qty) {
      setSelectedQty(0);
    } else {
      setSelectedQty(qty);
    }
  };

  const { id, techTitle } = useParams();

  const nextStep = (e) => {
    if (selectedQty === 0) {
      err("Please select team members.");
      return;
    }
    next();
  };
  const backStep = () => {
    back();
  };
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div className="set-content-flex-center">
      <div className="team-member wrapper">
        <h1 className="set-remote-font text-center my-5  w-md-50 mx-auto fw-bold">
          {location.pathname === "/join-a-team" ||
          location.pathname === `/hire-me-form/${techTitle}/${id}` ? (
            <>
              How many years <span className="hilight">Experience</span> do you
              have?
            </>
          ) : (
            <>How many Programmers do you require?</>
          )}
        </h1>
        <p className="text-center px-2 footr_p">
          {location.pathname === "/join-a-team" ||
          location.pathname === `/hire-me-form/${techTitle}/${id}` ? (
            <>How many Experience do you have?</>
          ) : (
            <>A team can have as many as 7 members as few as 2:</>
          )}
        </p>
        <div className="team-num w-md-75 p1-1 mb-2 mx-auto mx-md-5">
          {qty.map((num, i) => {
            const selected = selectedQty === num;
            return (
              <button
                key={i}
                onClick={() => selectqty(num)}
                value={num}
                className={`select-team-num ${
                  num === ">06" && "d-block d-sm-inline select-team-dont "
                } ${selected && "selected"}`}
              >
                {num}
              </button>
            );
          })}
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-none d-md-flex">
          <button className="step-back_btn mx-1 mt-4" onClick={backStep}>
            Back
          </button>
          <button className="step-btn mx-1 px-5 mt-4" onClick={nextStep}>
            Next
          </button>
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-md-none">
          <button className="step-btn mx-1 mt-4" onClick={nextStep}>
            Next
          </button>
          <button className="step-back_btn mx-1 mt-3" onClick={backStep}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamQty;
