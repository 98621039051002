import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import "./index.css";
import "swiper/css/navigation";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import { developer, techs } from "./developersData";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getFirstThreeAsCommaSeparatedString } from "../../utils/getStringFromArray";
import { getTechTitleFromUrl } from "../../utils/getTechTitleFromUrl";

// Install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

function SkillsSlider() {
  const currentSwiper = useRef();
  const { techTitle } = useParams();
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const tectTitleKey = getTechTitleFromUrl(techTitle);
  console.log("tectTitleKey", tectTitleKey);

  const filteredDevelopers = developer.filter((item) =>
    item.technology.includes(tectTitleKey)
  );
  const [techName] = techs.filter((item) => item.path === tectTitleKey);

  const nextSlide = () => {
    currentSwiper.current.slideNext();
  };
  const prevSlide = () => {
    currentSwiper.current.slidePrev();
  };
  return (
    <>
      <div className="hero_main_bg">
        <div
          className="custom-swiper-container"
        >
          <Swiper
            spaceBetween={30}
            loop={true}
            centeredSlides={true}
            effect="fade"
            autoplay={{
              delay: 1000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            navigation={false}
            speed={3000}
            className="mySwiper_2"
          >
            {filteredDevelopers.map((dev, index) => (
              <>
                <SwiperSlide className="swiper-card " key={index}>
                  <div className="justify-content-center align-content-center slide ">
                    <div className=" justify-content-between align-content-center px-1">
                      <div
                        key={index}
                        className="card custom_card_new m-0 d-flex flex-column border rounded-3 align-items-center w-100"
                      >
                        <div className="d-flex flex-column align-items-center w-100">
                          <div className="exp-bar">
                            <p>5 Years of Experience</p>
                          </div>
                          <div className="dev-image-wrapper">
                            <CircularProgressbarWithChildren value={71}>
                              <img src={dev.image} alt="doge" />
                            </CircularProgressbarWithChildren>
                          </div>
                          <p className="dev-name-bar">
                            {capitalizeFirstLetter(dev.name)}
                          </p>
                          <p className="dev-stack-bar">
                            {capitalizeFirstLetter(dev.category)}
                          </p>
                          <div className="dev-tech-bar d-flex justify-content-between w-100">
                            <p className="dev-tech-bar-child1">Technology</p>
                            <div className="dev-stack-string">
                              <p className="dev-tech-bar-child2">
                                {getFirstThreeAsCommaSeparatedString(dev.stack)}
                              </p>
                            </div>
                          </div>
                          <div className="dev-tech-bar d-flex justify-content-between w-100">
                            <p className="dev-tech-bar-child1">Rating</p>
                            <p className="dev-tech-bar-child2">
                              76% Positive Rated
                            </p>
                          </div>
                        </div>
                        {/* <div className="card_details d-flex justify-content-between w-100">
                        <div className="">
                          <span className="fw-bold text-success">5 </span> Years
                          +
                          <br /> Experience
                        </div>
                        <div className="profile-img-warapper">
                          <CircularProgressbarWithChildren value={71}>
                            <img src={dev.image} alt="doge" />
                          </CircularProgressbarWithChildren>
                        </div>
                        <div className="align-self-center">
                          <h3 className="text-blue">71%</h3> score
                        </div>
                      </div>

                      <h5 className="fw-bold title-blue">
                        {capitalizeFirstLetter(dev.name)}
                      </h5>
                      <strong> {dev.category}</strong>
                      <div className="languages w-100 mb-0 d-flex justify-content-between">
                        {dev.stack
                          .slice(0, 3)
                          .map(({ name, image }, techIndex) => (
                            <div key={techIndex} className="  technology">
                              <div className="techicon w-100  d-flex align-items-center ">
                                <div className="skills_img">
                                  <img src={image} alt="" />
                                </div>

                                <p className="m-0  text-stack">{name}</p>
                              </div>
                            </div>
                          ))}
                      </div> */}

                        <Link to={`/hire-me-form/${tectTitleKey}-developer`}>
                          <button
                            className="step-btn my-3"
                            onClick={() => {
                              localStorage.setItem(
                                "devDetails",
                                JSON.stringify(dev)
                              );
                            }}
                          >
                            Hire Me
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
          {/* <div className="slide-next-custom-btn" onClick={nextSlide}>
        <SlideNextIcon />
      </div>
      <div className="slide-prev-custom-btn" onClick={prevSlide}>
        <SlidePrevIcon />
      </div> */}
        </div>
        {/* <HireMeForm developerData={developer} /> */}
      </div>
    </>
  );
}

export default SkillsSlider;
