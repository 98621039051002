import React from "react";
import "./WhyRemotepad.css";
import thumb from "../Assets/thumb.svg";
import thumb2 from "../Assets/why-2-s.webp";
import bolt from "../Assets/bolt.svg";
import stopwatch from "../Assets/stopwatch.svg";
import { Link } from "react-router-dom";
import DevelopmentServices from "../engrTeamSection/DevelopmentServices";
import AnimationSection from "../animSection/AnimationSection";

const WhyRemotepad = () => {
  return (
    <>
      <section className="AboutStyled">
        <div className="hero-content py-5">
          <div className="styles__AboutHead">
            <h2 className="mb-3">Why choose The Remotepad?</h2>
            {/* <p className="styles__AboutPara pb-4">
              Here are some reasons why global companies prefer Remotepad for
              hiring remote developers for all their software development needs:
            </p> */}
          </div>
          <div className="styles__AboutGrid">
            <div className="styles__AboutContent row">
              <div className="styles__AboutCard">
                <h3 className="styles__AboutSubHeading">Hiring in 24 Hours</h3>
                <p className="styles__AboutDetails ">
                  Hire top remote engineers in just 24 hours
                </p>
              </div>
              <div className="styles__AboutCard">
                <h3 className="styles__AboutSubHeading">
                  1-Week Risk-Free Trial
                </h3>
                <p className="styles__AboutDetails">
                  Try our developers for a week at no cost.
                </p>
              </div>
              <div className="styles__AboutCard ">
                <h3 className="styles__AboutSubHeading">
                  24/5 Dedicated Support
                </h3>
                <p className="styles__AboutDetails">
                  We're with you every step of the way
                </p>
              </div>
              <div className="styles__AboutCard">
                <h3 className="styles__AboutSubHeading">
                  Top 10% internal community
                </h3>
                <p className="styles__AboutDetails">
                  We're proud of our internal community of software engineers.
                  We select only the top 3% of applicants, fostering exceptional
                  talent.
                </p>
              </div>
              <div className="styles__AboutCard">
                <h3 className="styles__AboutSubHeading">
                  Top 1% Vetted Talent
                </h3>
                <p className="styles__AboutDetails">
                  Get role-specific professionals from our talented pool to
                  ensure project success.
                </p>
              </div>

              <div className="styles__AboutCard ">
                <h3 className="styles__AboutSubHeading">
                  Starting at $1200/month
                </h3>
                <p className="styles__AboutDetails">
                  Save money with our competitive pricing, for 1.5 to 2 years of
                  experience.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center  pt-5">
            <Link to={"/hire-a-team"}>
              <button className="step-back_btn  ">Hire Developers</button>
            </Link>
          </div> */}
        </div>
      </section>
      {/* <AnimationSection /> */}
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6 col-12">
            <div className="ContentFold_image">
              <img loading="lazy" src={thumb2} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="PrismicRichText">
              <h2 className="">Why Top Developers Choose Remotepad</h2>
              {/* <h3 className="fw-bold mb-0">
                {" "}
                They are seeking long-term careers, not just temporary gigs.
              </h3> */}

              <div className="ContentFold_keyContent">
                <div className="mt-4">
                  <div className="objective-wrapper">
                    <h3>Career Stability:</h3>
                    <p>
                      Our platform offers long-term career opportunities, not
                      just short-term gigs.
                    </p>
                  </div>
                  <div className="objective-wrapper">
                    <h3>Verified Credentials:</h3>
                    <p>
                      Once verified, developers are qualified for life, ensuring
                      recognition of their skills.
                    </p>
                  </div>
                  <div className="objective-wrapper">
                    <h3>Exclusive Developer Support:</h3>
                    <p>
                      Developers receive tailored support from Turing for
                      success.
                    </p>
                  </div>
                  <div className="objective-wrapper">
                    <h3>Income Stability:</h3>
                    <p>
                      With a 99% rematch rate, income stability is guaranteed
                      for developers.
                    </p>
                  </div>
                  {/* <ul className="custom-list">
                    <li>Verified once, qualified for life</li>
                    <li>Exclusive support for developer success from Turing</li>
                    <li>Income stability ensured by a 99% rematch rate</li>
                  </ul> */}
                </div>
              </div>
              {/* <div className="d-flex justify-content-start mt-4">
                <Link to={"/hire-a-team"}>
                  <button className="step-back_btn  ">Hire Developers</button>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <DevelopmentServices />
      <div className="section_frame_2">
        <div className="hero-content py-5">
          <div className="container">
            <div className="headline-align-center">
              <h2 className="fw-bold mb-4">Why businesses trust Remotepad</h2>
            </div>
            <div className="row mx-sm-2">
              <div className=" col-md-4 mb-3 mb-sm-0">
                <div className="TuringMetrics_headline mb-3">
                  <img src={thumb} alt="" width="40px" height="40px" />
                  <div>
                    <h3 className="my-2 p-0">Unmatched Quality</h3>
                    <h5 className="TuringMetrics_highlight mb-2">
                      <span>95%</span> Trial Success Rate
                    </h5>
                    <p className="mb-0 ms-1">
                      For Smarter, More <br /> Productive Teams
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 mb-sm-0">
                <div className="TuringMetrics_headline mb-3">
                  <img src={bolt} alt="" width="40px" height="40px" />
                  <div>
                    <h3 className="my-2 p-0 ">Superior Efficiency</h3>
                    <h5 className="TuringMetrics_highlight mb-2">
                      <span>97%</span> engagement success
                    </h5>
                    <p className="mb-0 ms-1">
                      For Accelerated Outcomes With <br /> Maximum Efficiency
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 mb-sm-0">
                <div className="TuringMetrics_headline mb-3">
                  <img src={stopwatch} alt="" width="40px" height="40px" />
                  <div>
                    <h3 className="my-2 p-0 ">Trusted Expertise</h3>
                    <h5 className="TuringMetrics_highlight mb-2">
                      <span>125+</span> Years Combined Experience
                    </h5>
                    <p className="mb-0 ms-1">
                      For Transformational Business Success
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center pb-5 ">
                <Link to={"/hire-a-team"}>
                  <button className="step-back_btn  ">Hire Developers</button>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyRemotepad;
