import React from "react";
import Header from "../Header/Header";
import "./aboutus.css";
import Ourclientsanimation from "../ourclientanimtion/Ourclientsanimation";
import Trial from "./Traial";
import { Link, useParams } from "react-router-dom";
import Developerstack from "../developerstack/Developerstack";
import Readytoget from "../readytogetsection/Readytoget";
import { developer } from "../SkillsSlider/developersData";
import UpdatedFooter from "../footer/UpdatedFooter";

function Aboutus() {
  const { techTitle } = useParams();
  const filteredDevelopers = developer.filter((item) =>
    item.technology.includes(techTitle)
  );
  return (
    <>
      <Header />
      <div className="container">
        <div className="hero-content">
          <div className="row home-hero justify-content-center align-items-center">
            <div className="col-md-6 ">
              <div className="hero-title">
                <p className="about-us-p-heading mb-3">About us</p>
                <h1 className="mb-3">
                  Remotepad Connects World’s Best Developer Talent With Global
                  Companies
                </h1>
                <p className="mb-4">
                  We are transforming the tech hiring process by finding,
                  vetting, and training extraordinary tech talent from across
                  the globe to help companies hire their ideal developer teams
                  within 24 hours.
                </p>

                <div className="form_btn_ctrl d-flex justify-content-start d-none d-md-flex mb-2">
                  <Link to={"/hire-a-team"}>
                    <button className="step-back_btn ">Hire Developers</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center hero-img-sect">
              <div className="hero-img-box_main">
                <img
                  src="./images/aboutUsBannerImage2.png"
                  alt=""
                  className="img-fluid title-img  d-md-block"
                />
                {/* <div className="topLeftSvg">
                  <TopLeftSvg />
                </div>
                <div className="topRightSvg">
                  <TopRightSvg />
                </div>
                <div className="bottomLeftSvg">
                  <BottomLeftSvg />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Ourclientsanimation />
      <Trial />
      <div className="my-5">
        <Developerstack />
      </div>
      <Readytoget devlopers={filteredDevelopers} />
      {/* <Footer2 /> */}
      <UpdatedFooter />
    </>
  );
}

export default Aboutus;
