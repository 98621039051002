import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { developer } from "../../SkillsSlider/developersData";
import "./skill.css";
import { processString } from "../../../utils/urlFilter";
import { skillsData } from "../../../Contants/SkillsData";

const Skill = ({
  next,
  back,
  err,
  setSelectedSkills,
  selectedSkills,
  clearData,
}) => {
  const { id } = useParams();
  const { techTitle } = useParams();

  const [selectedDeveloper] = developer?.filter((skill) => skill.id == id);
  const developerTech = selectedDeveloper?.stack.map((skill) => {
    return skill.name;
  });

  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (selectedSkills === undefined) setSelectedSkills([]);
  }, []);

  const selectSkill = (skill) => {
    console.log("check out");
    // setSelectedSkills((selectedSkills) =>  [...selectedSkills, skill]);
    if (selectedSkills?.includes(skill)) {
      setSelectedSkills([...selectedSkills?.filter((s) => s !== skill)]);
      console.log("check 1");
    } else {
      setSelectedSkills([...selectedSkills, skill]);
      console.log("check 2");
    }
    console.log("check out 2");
  };

  console.log("selected skill", selectedSkills);

  const nextStep = () => {
    if (selectedSkills.length === 0) {
      err("Please select atleast one skill.");
      return;
    }
    next();
  };
  const backStep = () => {
    back();
    clearData();
  };
  const location = useLocation();

  let updatedString = processString(techTitle);

  const filterByTitle = (title) => {
    return skillsData.filter(
      (item) => item.title.toLowerCase() === title.toLowerCase()
    );
  };

  const filteredData = filterByTitle(updatedString);
  console.log("filteredData", filteredData);

  const handleSomethingElse = () => {
    setShowDropdown(!showDropdown);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleDropdownSubmit = (e) => {
    e.preventDefault();
    const newItem = { id: filteredData[0]?.skills.length, stack: inputValue };
    // setSkills([...skills, newItem.stack]);
    filteredData[0]?.skills?.push(newItem.stack);
    setSelectedSkills([...selectedSkills, newItem.stack]);
    setShowDropdown(false);
    setInputValue("");
  };

  return (
    <div className="set-content-flex-center">
      <div className="req-skill wrapper">
        <h1 className="text-center set-remote-font my-5 w-md-50 mx-auto fw-bold">
          {location.pathname === "/join-a-team" ? (
            <>
              What kind of <span className="hilight">skill</span> do you have
              for our team?
            </>
          ) : (
            <>What kind of skill sets do you require for your team?</>
          )}
        </h1>
        <p className="text-center footr_p">
          {location.pathname === "/join-form" ? (
            <>What kind of skill do you have ?</>
          ) : (
            <>Tell us which skill your team needs.</>
          )}
        </p>
        <div className=" row pt-1 select-skills d-flex justify-content-center set-content-between">
          {filteredData[0]?.skills?.map((skill, i) => {
            const selected = selectedSkills?.includes(skill);
            return (
              <button
                className={`select-skill-btn ${selected && "selected"}`}
                value={skill}
                key={i}
                onClick={() => selectSkill(skill)}
              >
                {skill}
              </button>
            );
          })}
          <button onClick={handleSomethingElse} className="select-skill-btn">
            Something else
          </button>
          {showDropdown && (
            <div className="dropdown-box" style={{ width: "231px" }}>
              <form action="" onSubmit={handleDropdownSubmit}>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter something..."
                  style={{ width: "80%" }}
                />
                <button type="submit" className="add_more_btn">
                  <i class="fas fa-arrow-right"></i>
                </button>
              </form>
            </div>
          )}
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-none d-md-flex">
          <button className="step-back_btn mx-1 mt-4" onClick={backStep}>
            Back
          </button>
          <button className="step-btn mx-1 mt-4 px-5" onClick={nextStep}>
            Next
          </button>
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-md-none ">
          <button className="step-btn mx-1 mt-4" onClick={nextStep}>
            Next
          </button>
          <button className="step-back_btn mx-1 mt-3" onClick={backStep}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Skill;
