import React from "react";
import { Link, useParams } from "react-router-dom";
import classes from "./style.module.css";
import { Col, Container, Row } from "react-bootstrap";
import SkillsSlider from "../SkillsSlider/SkillsSlider";
import { techs } from "../SkillsSlider/developersData";
import { getTechTitleFromUrl } from "../../utils/getTechTitleFromUrl";

const SkillsHeroSec = () => {
  const { techTitle } = useParams();

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const tectTitleKey = getTechTitleFromUrl(techTitle);
  console.log("tectTitleKey", tectTitleKey);

  const [techName] = techs?.filter((item) => item?.path === tectTitleKey);

  console.log("techName", techName);
  console.log("Params", techTitle);

  return (
    <div className="">
      <Container className={classes.hero_section}>
        <Row className={classes.set_height}>
          <Col md={12} lg={6} sm={12} className="slider-2 ">
            <SkillsSlider />
          </Col>
          <Col md={12} lg={6} sm={12} className="px-5">
            <div className={classes.main_title}>
              <h2 className=" title-black mb-3">
                Hire The Top 1% Of The Global
              </h2>
              <h5 className="fw-bold title-blue mb-3">
                {capitalizeFirstLetter(techName?.name)}
              </h5>
              <p className="mb-4 w-80">
                Get rigorously vetted web developer teams that best match your
                timezone and work model. Save your hiring time and budget with
                Remotepad.
              </p>
              <Link
                to={`/hire-me-form/${tectTitleKey}-developer`}
                className="hire-cta "
              >
                <button className="step-btn developer-btn">
                  {" "}
                  Hire {techName?.name}
                </button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SkillsHeroSec;
