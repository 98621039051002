import React from "react";

function CustomDatePicker({ field, form, label, ...props }) {
  return (
    <div className="info set-my-input-relative" key="1">
      <label htmlFor="basic-url" className="set-my-label form-label">
        {label}
      </label>
      <input type="date" {...field} {...props} className="form-control form-control-lg" />
    </div>
  );
}

export default CustomDatePicker;