import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./developerpage.css";
import Ourclientanimation from "../ourclientanimtion/Ourclientsanimation";
import Ourclientslider from "../teamslider/Teamslider";
import Developerstack from "../developerstack/Developerstack";
import Readytoget from "../readytogetsection/Readytoget";
import "../../components/animSection/animation.css";
import Header from "../Header/Header";
import { developer } from "../SkillsSlider/developersData";
import SkillsHeroSec from "../SkillsHeroSec";
import UpdatedFooter from "../footer/UpdatedFooter";
import { getTechTitleFromUrl } from "../../utils/getTechTitleFromUrl";

function Developerpage() {
  const { techTitle } = useParams();

  const tectTitleKey= getTechTitleFromUrl(techTitle);

  const filteredDevelopers = developer.filter((item) =>
    item.technology.includes(tectTitleKey)
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tectTitleKey]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tectTitleKey]);

  return (
    <>
      <Header customStyle="custom-padding" />
      <SkillsHeroSec />
      <div className="mb-5">
        <Ourclientanimation />
      </div>
      <h2 className="text-center mb-5">
        {" "}
        Get access to the top-notch global tech talent pool from anywhere in the
        world
      </h2>
      <Ourclientslider devlopers={filteredDevelopers} />
      <Developerstack />
      <Readytoget />
      {/* <Footer2 /> */}
      <UpdatedFooter />
    </>
  );
}

export default Developerpage;
