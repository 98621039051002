import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../index.css";
import Logo from "../Assets/Remotepad Logo.webp";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`main-navbar position-fixed w-100 relative ${
        isScrolled ? "navbar-sticky" : ""
      }`}
      style={{height: "90px", display: "flex"}}
    >
      <nav className="navbar container navbar-expand-lg navbar-light p-0 ">
        <div className="container-fluid .header_nav">
          <Link to={"/"} className="  navbar-brand">
            <img src={Logo} alt="" className={`img-fluid ${isScrolled ? "small-logo" : ""}`} />
          </Link>

          <div
            className="dropdown_hover"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ marginRight: "4%" }}
          >
            <Link to={"/"} className="hire-cta">
              <button className="step-btn header-btn px-3">Back to Home</button>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
