import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  skills: [],
  tech: [],
  members: null,
};

const hireTeamSlice = createSlice({
  name: "hireTeam",
  initialState,
  reducers: {
    skillsHandler: (state, action) => {
      state.skills = action.payload;
    },
    techHandler: (state, action) => {
      state.tech = action.payload;
    },
    membersHandler: (state, action) => {
      state.members = action.payload;
    },
  },
});

export const { skillsHandler, techHandler, membersHandler } =
  hireTeamSlice.actions;

export default hireTeamSlice.reducer;
