import React, { useState } from "react";
import CustomInputFeild from "../CustomInputFeild";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { validateLeadForm } from "../../yupSchema/LeadFormShcema";
import CustomDatePicker from "../CustomDatePicker";
import CustomTimePicker from "../CustomTimePicker";
import CustomTextArea from "../CustomTextArea";
import ReCAPTCHA from "react-google-recaptcha";

function FormWrapper({ setStep }) {
  const [isLoading, setIsLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const initialValues = {
    fullname: "",
    professionalEmail: "",
    companyName: "",
    industry: "",
    skypeID: "",
    location: "",
    briefDetail: "",
    meetingDate: "",
    meetingTime: "",
  };
  const SheetName = "LeadGenFormSheet";
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validateLeadForm}
        onSubmit={async (values, actions) => {
          setIsLoading(true);
          const state = {
            Fullname: values.fullname,
            ProfessionalEmail: values.professionalEmail,
            CompanyName: values.companyName,
            Industry: values.industry,
            SkypeID: values.skypeID,
            Location: values.location,
            BriefDetail: values.briefDetail,
            MeetingDate: values.meetingDate,
            MeetingTime: values.meetingTime,
            SheetName: SheetName,
          };
          const formData = new FormData();
          for (const key in state) {
            if (state.hasOwnProperty(key)) {
              const value = state[key];

              if (Array.isArray(value)) {
                // If the value is an array, append each element separately
                for (let i = 0; i < value.length; i++) {
                  formData.append(`${key}[${i}]`, value[i]);
                }
              } else {
                formData.append(key, value);
              }
            }
          }
          console.log("object", state);
          if (verified) {
            await fetch(process.env.REACT_APP_GSHEET_URL, {
              method: "POST",
              body: formData,
            })
              .then((res) => {
                setIsLoading(false);
                setStep(2);
                actions.resetForm();
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
                actions.resetForm();
              });
          } else {
            alert("Verify ReCAPTCHA");
            setIsLoading(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomInputFeild}
                type="text"
                id="fullname"
                name="fullname"
                placeholder="Enter Your Full Name"
                label="Full Name"
              />
              <ErrorMessage name="fullname" component="div" className="error" />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomInputFeild}
                type="text"
                id="professionalEmail"
                name="professionalEmail"
                placeholder="Enter Your Email"
                label="Professional Email"
              />
              <ErrorMessage
                name="professionalEmail"
                component="div"
                className="error"
              />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomInputFeild}
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Enter Your Company Name"
                label="Company Name"
              />
              <ErrorMessage
                name="companyName"
                component="div"
                className="error"
              />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomInputFeild}
                type="text"
                id="industry"
                name="industry"
                placeholder="Enter Your Industry"
                label="Industry"
              />
              <ErrorMessage name="industry" component="div" className="error" />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomInputFeild}
                type="text"
                id="skypeID"
                name="skypeID"
                placeholder="Enter Your Skype ID"
                label="Skype ID"
              />
              <ErrorMessage name="skypeID" component="div" className="error" />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomInputFeild}
                type="text"
                id="location"
                name="location"
                placeholder="Enter Your Location"
                label="Location"
              />
              <ErrorMessage name="location" component="div" className="error" />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomTextArea}
                type="text"
                id="briefDetail"
                name="briefDetail"
                placeholder="Enter Brief Detail Of Your Project or Query"
                label="Brief Detail"
              />
              <ErrorMessage
                name="briefDetail"
                component="div"
                className="error"
              />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomDatePicker}
                id="meetingDate"
                name="meetingDate"
                placeholder="Select Meeting Date"
                label="Meeting Date"
              />
              <ErrorMessage
                name="meetingDate"
                component="div"
                className="error"
              />
            </div>
            <div className="inputAndErrorWrapper">
              <Field
                component={CustomTimePicker}
                id="meetingTime"
                name="meetingTime"
                placeholder="Select Meeting Time"
                label="Meeting Time"
              />
              <ErrorMessage
                name="meetingTime"
                component="div"
                className="error"
              />
            </div>

            <div className="px-3">
              <ReCAPTCHA
                sitekey="6LcsgmopAAAAAMTyP7jnxKeb-fr02SURarYrCVMS"
                onChange={() => setVerified(true)}
              />
            </div>
            <button type="submit" className="step-btn mx-auto px-3 mb-5">
              {isLoading ? (
                <div
                  class="spinner-border spinner-border-sm text-dark mt-1"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default FormWrapper;
