import React, { useState, useEffect } from "react";
import "./about-hiring-candidate.css";
import emailjs from "emailjs-com";
import $ from "jquery";
import axios from "axios";
import Spinner from "../../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
// import { Dropbox } from "dropbox";
// import PhoneInput from 'react-phone-number-input'

const AboutHirinCandidate = ({
  val,
  setNo,
  compAdd,
  custAdd,
  myform,
  setmyform,
  setCompAdd,
  next,
  back,
  // err,
  clearData,
  selectedQty,
  selectedSkills,
  selectedTeams,
}) => {
  const [value, setValue] = useState("1");
  const [err, setError] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [resumeUrl, setResumeUrl] = useState("");
  const [documentLoading, setDocumentLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [varified, setVarified] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [verified, setVerified] = useState(false);

  let filename;

  console.log(selectedFile);
  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    filename = file.name;

    // Check if the file size is less than or equal to 2MB
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    if (file && file.size > maxSizeInBytes) {
      alert("File size exceeds 2MB. Please choose a smaller file.");
      return;
    }

    // Check if the file type is either 'application/pdf' or 'application/msword'
    const allowedFileTypes = ["application/pdf"];
    if (file && !allowedFileTypes.includes(file.type)) {
      alert("Invalid file type. Please choose a PDF document.");
      return;
    }

    setSelectedFile(file);
    const form = new FormData();
    form.append("file", file);
    uploadFile(form);
    console.log("check 1 =>");
  };

  const uploadFile = async (file) => {
    try {
      setDocumentLoading(true);
      console.log(file, "current file");
      const res = await fetch(
        "https://remotepad.bitsclansolutions.com/api/upload",
        {
          method: "POST",
          body: file,
        }
      );
      const data = await res.json();
      setResumeUrl(data.url);
      setFileName(filename);
      console.log(data);
      setDocumentLoading(false);
      toast.success("Successfull");
    } catch (e) {
      console.log(e);
      setDocumentLoading(false);
      toast.error("Failed to upload your resume try again");
    }
  };

  useEffect(() => {}, [selectedFile]);

  console.log(resumeUrl);

  setNo(value);
  useEffect(() => {
    setValue(val);
    $(".PhoneInputCountryIcon").addClass("democlass");
    $(".democlass").append("<p>Select</p>");
  }, []);

  const handleOnchange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setmyform({ ...myform, [name]: value });
  };

  const sheetName = "JoinATeamSheet";

  const state = {
    RemoteTeam: JSON.stringify(selectedTeams),
    Skills: JSON.stringify(selectedSkills),
    Experience: selectedQty,
    FirstName: myform.myformName,
    LastName: myform.myformLastName,
    Email: myform.myformEmail,
    SkypeId: myform.myformSkypeId,
    Contact: myform.myformContact,
    Industry: myform.myformcompIndustry,
    Location: myform.myformcompLoc,
    CompanyEmployees: myform.myformcompEmp,
    SheetName: sheetName,
    ResumeUrl: resumeUrl,
  };

  console.log(state);

  const sendForm = () => {
    setLoading(true);
    const formData = new FormData();
    for (const key in state) {
      if (state.hasOwnProperty(key)) {
        const value = state[key];

        if (Array.isArray(value)) {
          // If the value is an array, append each element separately
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[${i}]`, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    fetch(
      process.env.REACT_APP_GSHEET_URL,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        // res.json();
        next();
      })
      .catch((error) => console.log(error));
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (resumeUrl) {
      sendForm();
    } else {
      toast.error("Resume is required");
      return;
    }
    if (!verified) {
      setCaptchaError(true);
      return;
    }
  };

  const nextStep = () => {
    next();
    clearData();
  };
  const backStep = () => {
    back();
  };
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
    if (verified) {
      setCaptchaError(false);
    }
  }

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <div className="mt-4 inp-wrapper wrapper">
          <form onSubmit={submitForm}>
            <div className=" ">
              <h1 className="set-remote-font text-center px-3 mt-2 mb-5 fw-bold w-md-50 mx-auto">
                We would like to learn more about <br />
                <span className="hilight ">you !</span>
              </h1>

              <div className="row py-3 px-wrap gx-5 mx-xl-5">
                <div className=" col-md-6 mb-3 info px-3 set-my-input-relative"
                  key="1"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    First Name
                  </label>
                  <input
                    required
                    maxLength="20"
                    type="text"
                    className="form-control form-control-lg"
                    name={"myformName"}
                    value={myform.myformName}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 1 && errMsg}</small>
                </div>
                <div className=" col-md-6 mb-3 info px-3 set-my-input-relative"
                  key="2"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Last Name
                  </label>
                  <input
                    required
                    maxLength="55"
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformLastName"
                    value={myform.myformLastName}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 2 && errMsg}</small>
                </div>
                <div className=" col-md-6 mb-3 px-3 set-my-input-relative" key="3">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Email
                  </label>
                  <input
                    required
                    maxLength="32"
                    type="email"
                    name="myformEmail"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    value={myform.myformEmail}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 3 && errMsg}</small>
                </div>
                <div className=" col-md-6 mb-3  px-3 set-my-input-relative" key="4">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Skype ID
                  </label>
                  <input
                    maxLength="35"
                    required
                    type="text"
                    name="myformSkypeId"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    value={myform.myformSkypeId}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 4 && errMsg}</small>
                </div>
                <div
                  className=" col-md-6 mb-3 px-3 info set-my-input-relative"
                  key="5"
                >
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Contact No
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="tel"
                    className="form-control form-control-lg"
                    id="comp_name"
                    name="myformContact"
                    value={myform.myformContact}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 5 && errMsg}</small>
                </div>
                <div className=" col-md-6 mb-3 px-3 set-my-input-relative" key="6">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Industry
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformcompIndustry"
                    value={myform.myformcompIndustry}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 6 && errMsg}</small>
                </div>
                <div className=" col-md-6 mb-3 px-3 set-my-input-relative" key="7">
                  <label
                    htmlFor="basic-url"
                    className="set-my-label form-label"
                  >
                    Location
                  </label>
                  <input
                    maxLength="25"
                    required
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleFormControlInput1"
                    name="myformcompLoc"
                    value={myform.myformcompLoc}
                    onChange={handleOnchange}
                  />
                  <small className="redmsg">{err === 7 && errMsg}</small>
                </div>

                <div className="d-flex flex-column pt-2 px-3 gap-1">
                  <label className="custom-upload-btn">
                    <span>
                      <i className="fas fa-cloud-upload-alt"></i> Upload Resume
                    </span>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                      onClick={onInputClick}
                      accept=".pdf"
                      style={{ display: "none" }}
                    />
                  </label>
                  {documentLoading && (
                    <p className="resume-upload-text">
                      Your resume is uploading... Please wait.
                    </p>
                  )}
                  <p className="resume-upload-text">{fileName}</p>
                </div>

                <div className="px-3"> 
                <ReCAPTCHA
                  sitekey="6LcsgmopAAAAAMTyP7jnxKeb-fr02SURarYrCVMS"
                  onChange={onChange}
                />
                </div>
                {captchaError && <h6 className="text-danger">Please verify</h6>}
              </div>
            </div>
            <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-none d-md-flex">
              <button className="step-back_btn mx-1 mb-5 " onClick={backStep}>
                Back
              </button>
              <button
                type="submit"
                disabled={documentLoading}
                className="step-btn mx-1 mb-5 candidate-submit"
              >
                Next
              </button>
            </div>
            <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-md-none">
              <button type="submit" className="step-btn mb-3 ">
                Next
              </button>
              <button className="step-back_btn mb-5 " onClick={backStep}>
                Back
              </button>
            </div>
          </form>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default AboutHirinCandidate;
