import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import CardImage from "../Assets/Rectangle 11.webp";
import "./index.css";

const CustomCard = ({ title, content, date, img, link,blogId }) => {
  return (
    <>
      <Link to={link} state={{id: blogId}} >
        <Card className="card">
          <div className="card_img_box">
            <Card.Img variant="top" src={img ? img : CardImage} />
          </div>

          {/* <Card.Img variant="top" src={CardImage2} /> */}
          <Card.Body>
            <span className="article-share">{date}</span>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              <Link to={link} className=" read_more mx-1">
                Read More
                <i class="fas fa-arrow-right"></i>
              </Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </>
  );
};

export default CustomCard;
