import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./skill.css";
const Skill = ({
  next,
  back,
  err,
  data,
  setData,
  setSelectedSkills,
  selectedSkills,
  clearData,
}) => {
  const [skills, setSkills] = useState(data);
  const selectSkill = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills([...selectedSkills.filter((s) => s !== skill)]);
    } else {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const nextStep = () => {
    if (selectedSkills.length === 0) {
      err("Please select atleast one skill.");
      return;
    }
    next();
  };
  const backStep = () => {
    back();
  };

  const handleSomethingElse = () => {
    setShowDropdown(!showDropdown);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleDropdownSubmit = (e) => {
    e.preventDefault();
    const newItem = { id: skills.length, stack: inputValue };
    setSkills([...skills, newItem.stack]);
    setSelectedSkills([...selectedSkills, newItem.stack]);
    setShowDropdown(false);
    setInputValue("");
  };

  console.log("skil", data);
  const location = useLocation();

  return (
    <div className="set-content-flex-center">
      <div className="req-skill wrapper">
        <h1 className="text-center set-remote-font my-5 w-md-50 mx-auto fw-bold">
          {location.pathname === "/join-a-team" ? (
            <>
              What kind of <span className="hilight">skill</span> do you have
              for our team?
            </>
          ) : (
            <>
              What kind of skill sets do you require for your team?
            </>
          )}
        </h1>
        <p className="text-center footr_p">
          {location.pathname === "/join-a-team" ? (
            <>What kind of skill do you have ?</>
          ) : (
            <>Tell us which skill your team needs.</>
          )}
        </p>
        <div className=" pt-1 select-dev">
          {skills.map((skill, i) => {
            const selected = selectedSkills.includes(skill);
            return (
              <button
                className={`select-skill-btn ${selected && "selected"}`}
                value={skill}
                key={i}
                onClick={() => selectSkill(skill)}
              >
                {skill}
              </button>
            );
          })}
          <button onClick={handleSomethingElse} className="select-dev-btn">
            Something else
          </button>
          {/* Dropdown box */}
          {showDropdown && (
            <div className="dropdown-box">
              <form action="" onSubmit={handleDropdownSubmit}>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter something..."
                  style={{ width: "80%" }}
                />
                <button type="submit" className="add_more_btn">
                  <i class="fas fa-arrow-right"></i>
                </button>
              </form>
            </div>
          )}
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-none d-md-flex">
          <button className="step-back_btn  mt-4" onClick={backStep}>
            Back
          </button>
          <button className="step-btn px-5 mx-2 mt-4" onClick={nextStep}>
            Next
          </button>
        </div>
        <div className="form_btn_ctrl d-flex justify-content-center pb-5 d-md-none ">
          <button className="step-btn px-3 mt-4" onClick={nextStep}>
            Next
          </button>
          <button className="step-back_btn px-3 mt-3" onClick={backStep}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Skill;
