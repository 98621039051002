import React, { useState } from "react";
import "./engrSection.css";
import { useLocation } from "react-router-dom";
import lock from "../Assets/locker.svg";
import webapp from "../Assets/webapp.svg";
import mobileapp from "../Assets/mobileapp.svg";
import gameapp from "../Assets/gameapp.svg";
import block from "../Assets/block.svg";
import ai from "../Assets/ai.svg";
import Temp from "./Temp";

const DevelopmentServices = ({ blog }) => {
  const location = useLocation();
  const URL = location.pathname;
  console.log("URL", URL);
  const tabData = [
    {
      id: 1,
      name: "AI/ML/ChatGPT Solutions",
      tab: "chat",
      img: ai,
    },
    {
      id: 2,
      name: "Web Application Development",
      tab: "web",
      img: webapp,
    },
    {
      id: 3,
      name: "Mobile App Development",
      tab: "mobile",
      img: mobileapp,
    },
    {
      id: 4,
      name: "Cloud Services",
      tab: "cloud",
      img: lock,
    },
    {
      id: 5,
      name: "Game/Metaverse Development",
      tab: "game",
      img: gameapp,
    },
    {
      id: 6,
      name: "Blockchain Development",
      tab: "block",
      img: block,
    },
  ];
  const [active, setActive] = useState(blog ? null : "chat");

  return (
    <div className={`main ${blog ? "blog-services" : ""}`}>
      <div className="hero-content ">
        <div className="justify-content-center tabs-text  row">
          <div
            className={`${
              blog ? "text-center py-3 py-sm-5" : "text-center py-3 py-sm-5"
            }`}
          >
            <h2 className="">Software Consulting & Development Services</h2>
            <p className="mb-0">
              Unlock the power of technology, expertise, and innovation with our
              comprehensive services.
            </p>
          </div>
        </div>

        <div className="row align-items-center pb-5">
          <div className={`${blog ? "col-12 p-0" : "col-md-6"}`}>
            <div className="justify-content-center gy-2 row">
              {tabData.map((item) => (
                <div
                  className={` ${
                    blog
                      ? "d-flex justify-content-center col-xl-0 col-md-6 col-sm-4 col-6 col-lg-6 px-1"
                      : "d-flex justify-content-center col-xl-4 col-md-6 col-sm-4 col-6 col-lg-6 px-1"
                  }`}
                  onClick={() => {
                    !blog && setActive(item.tab);
                  }}
                  onMouseEnter={() => {
                    blog && setActive(item.tab);
                  }}
                  onMouseLeave={() => {
                    blog && setActive(null);
                  }}
                >
                  <div
                    className={`Services-module ${
                      active === item.tab ? "active" : ""
                    }`}
                  >
                    <div>
                      <img loading="lazy" src={item.img} alt="" className="invertWhite" />
                      <p>{item.name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {URL === "/blog" ? <></> : <Temp active={active} blog={blog} />}
        </div>
      </div>
    </div>
  );
};

export default DevelopmentServices;
