import React, { useState, useEffect } from "react";
import "./hire-form.css";
import Progress from "./progress/Progress";
import RemoteTeam from "./remoteTeam/RemoteTeam";
import Skill from "./skillsFrom/Skill";
import TeamQty from "./teamQty/TeamQty";
import AboutHiringcompany from "./abouHiringcompany/AboutHiringcompany";
import ThankYou from "./ThankYou";
import Header from "./Headerform";
import Scope from "./scopefile.js";
import UpdatedFooter from "../footer/UpdatedFooter.jsx";

const HireForm = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const findskills = (id) => {
    const val = Scope.filter((t) => t.id === id);
    const dd = [...data, ...val[0].skills];
    const bb = dd.filter((value, index) => dd.indexOf(value) === index);
    setData(bb);
  };

  const delskills = (id) => {
    const val = Scope.filter((t) => t.id === id);
    const bb = data.filter((value) => !val[0].skills.includes(value));
    bb.length === 0 ? setData(data) : setData(bb);
  };

  const [selectedTeams, setSelectedTeams] = useState([]);
  const teams = [
    "React Js",
    "Vue Js",
    "Swift",
    "Python",
    "JavaScript",
    "Java",
    "wordpress",
    "Flutter",
    "Android",
    "Node Js",
    "PHP",
    "Bootstrap5",
    "React Native",
    "Rails",
    "Laravel",
    "Frontend",
    "QA",
    "Fullstack",
    "Backend",
  ];

  const [selectedSkills, setSelectedSkills] = useState([]);
  const skills = [
    "C#",
    "Aws",
    "Swift",
    "Html/Css",
    "Vue Js",
    "React Js",
    "JavaScript",
    "Java",
    "wordpress",
    "Flutter",
    "Android",
    "Node Js",
    "PHP",
    "Bootstrap5",
    "React Native",
    "MySQL",
    "MongoDB",
    "Rails",
    "Python",
    "Laravel",
  ];

  const qty = ["02", "03", "04", "05", "06", "07", "don't know"];
  const [selectedQty, setSelectedQty] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [no, setNo] = useState("");

  // MY SECTION SETTING
  const [myform, setmyform] = useState({
    id: new Date().getTime().toString(),
    myformName: "",
    myformDesignation: "",
    myformEmail: "",
    myformSkypeId: "",
    myformcompName: "",
    myformcompIndustry: "",
    myformcompLoc: "",
    myformcompEmp: "",
    myformBreifDetail: "",
  });
  // END MY SECTION SETTING

  const [compAdd, setCompAdd] = useState({
    compName: "",
    compPhone: no,
    compEmail: "",
    compLoc: "",
    compEmps: "",
    compEngrs: "",
  });

  const [custAdd, setCustAdd] = useState({
    custName: "",
    custLoc: "",
    custEmail: "",
    custSkypeId: "",
  });

  const totalStep = 4;

  const clearData = () => {
    setSelectedTeams([]);
    setSelectedSkills([]);
    setSelectedQty(0);
    setNo("");
    setData([]);

    // MY SECTION SETTING
    setmyform({
      myformName: "",
      myformDesignation: "",
      myformEmail: "",
      myformSkypeId: "",
      myformcompName: "",
      myformcompIndustry: "",
      myformcompLoc: "",
      myformcompEmp: "",
      myformBreifDetail: "",
    });
    // END MY SECTION SETTING

    setCompAdd({
      compEmail: "",
      compEmps: "",
      compLoc: "",
      compName: "",
      compPhone: "",
    });
    setCustAdd({
      custEmail: "",
      custLoc: "",
      custName: "",
      custSkypeId: "",
    });
  };

  const showErr = (msg) => {
    setErrMsg(msg);
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 2000);
  };

  const stepChange = () => {
    if (step === 4) {
      return;
    }
    setStep((prev) => prev + 1);
  };

  const stepBack = () => {
    if (step === 0) {
      setStep(0);
      return;
    }
    setStep((prev) => prev - 1);
  };

  var percentage = ((step / totalStep) * 100).toFixed(0);
  percentage = percentage < 0 ? 0 : percentage;
  percentage = percentage > 100 ? 100 : percentage;
  return (
    <div className="container" style={{ position: "relative" }}>
      <Header />

      <div className="set-main-error">
        {error ? <p className="error-msg">{errMsg}</p> : ""}
      </div>
      <div className="formHeight">
        <Progress progress={percentage} stp={step} />

        {step === 0 ? (
          <RemoteTeam
            next={stepChange}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            back={stepBack}
            err={showErr}
            Scope={Scope}
            findskills={findskills}
            delskills={delskills}
          />
        ) : step === 1 ? (
          <Skill
            next={stepChange}
            skills={skills}
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            back={stepBack}
            err={showErr}
            data={data}
            clearData={clearData}
          />
        ) : step === 2 ? (
          <TeamQty
            next={stepChange}
            qty={qty}
            selectedQty={selectedQty}
            setSelectedQty={setSelectedQty}
            back={stepBack}
            err={showErr}
          />
        ) : step === 3 ? (
          <AboutHiringcompany
            val={no}
            setNo={setNo}
            compAdd={compAdd}
            custAdd={custAdd}
            setCompAdd={setCompAdd}
            myform={myform}
            setmyform={setmyform}
            next={stepChange}
            back={stepBack}
            err={showErr}
            selectedQty={selectedQty}
            selectedSkills={selectedSkills}
            selectedTeams={selectedTeams}
            clearData={clearData}
          />
        ) : (
          step === 4 && <ThankYou next={stepChange} />
        )}
      </div>
      {/* <Footer2 faq={false} /> */}
      <UpdatedFooter />
    </div>
  );
};

export default HireForm;
