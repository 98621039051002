import React from "react";
import "./Steps.css";
import { Link } from "react-router-dom";

const Steps = () => {
  return (
    <>
      <div className="container" style={{margin: "3rem 0rem 3rem 0rem"}}>
        <div className="hero-content p-0">
          <div className="section_headline mb-4">
            <h2 className="Headline_title text-center mb-3 w-100">
              Struggling to find top remote developers?
            </h2>
            <h3 className="Headline_title text-center mb-0 w-100">
              Let Remotepad guide you to find your dream team in 4 easy steps
            </h3>
          </div>
          <ol className="processBox_list mb-3">
            <li className="processBox_listItem mb-3 mb-sm-0">
              <div className="processBox_item">
                <span className="processBox_index"></span>
                <div className="processBox_textWrap">
                  <h3 className="processBox_title">
                    Share Your Project Needs and Desired Skills:
                  </h3>
                  <p className="mb-0">
                    Share your project details, and we'll schedule a call to
                    understand your needs better. Our expert team is here to
                    guide you every step of the way.
                  </p>
                </div>
              </div>
            </li>
            <li className="processBox_listItem mb-3 mb-sm-0">
              <div className="processBox_item">
                <span className="processBox_index"></span>
                <div className="processBox_textWrap">
                  <h3 className="processBox_title">
                    We Source Top Developers from Our Pool:
                  </h3>
                  <p className="mb-0">
                    Sit back and relax, our team carefully check each candidate
                    to ensure you receive a list of pre-screened developers
                    perfectly suited to your project.
                  </p>
                </div>
              </div>
            </li>
            <li className="processBox_listItem mb-3 mb-sm-0">
              <div className="processBox_item">
                <span className="processBox_index"></span>
                <div className="processBox_textWrap">
                  <h3 className="processBox_title">
                    Schedule Interviews with your perfect match:
                  </h3>
                  <p className="mb-0">
                    Once we've found potential candidates, it's your turn to
                    meet them. Schedule interviews with developers you're
                    interested in to get to know them better.
                  </p>
                </div>
              </div>
            </li>
            <li className="processBox_listItem mb-3 mb-sm-0">
              <div className="processBox_item">
                <span className="processBox_index"></span>
                <div className="processBox_textWrap">
                  <h3 className="processBox_title">Start Your 1-Week Risk-Free Trial:</h3>
                  <p className="mb-0">
                  Ready to take the next step? Begin with a 1-week trial period. Dive into your project confidently, with the right team by your side.
                  </p>
                </div>
              </div>
            </li>
          </ol>
          <div className="d-flex justify-content-center pb-5">
            <Link to={"/hire-a-team"}>
              <button style={{width: "max-content", paddingLeft: "0.5rem", paddingRight: "0.5rem"}} className="step-back_btn  ">Start Your Free 1-Week Trail</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Steps;
