import React from "react";
import "./Spinner.css";
const Spinner = () => {
  return (
    <div className="donut-container">
      <div class="donut"></div>
    </div>
  );
};

export default Spinner;
