import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Remotepad Logo.webp";
import Bars from "../Assets/bars.png";
import Close from "../Assets/close.png";
import "./header.css";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMbNav, setShowMbNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoSizeClass = setIsScrolled ? "small-logo" : "";

  return (
    <div
      className={`main-navbar position-fixed w-100 relative ${
        isScrolled ? "navbar-sticky" : ""
      } ${isScrolled ? "main-navbar-scroll" : ""}`}
    >
      <nav className="navbar p-0 container navbar-expand-lg navbar-light">
        <div
          className={`dropdown-menu slideIn  animate ${
            showDropdown ? "show" : ""
          }`}
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <ul>
            <li className="dropdown-item-heading">
              <Link to={"/hire-a-developer/frontend"} class="dropdown-item">
                Frontend Developer
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-react-jsdeveloper"} class="dropdown-item">
                ReactJS
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-angular-developer"} class="dropdown-item">
                Angular
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-next-js-developer"} class="dropdown-item">
                NextJs
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-vue-js-developer"} class="dropdown-item">
                VueJS
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-frontend-developer"} class="dropdown-item">
                HTML/CSS/Javascript
              </Link>
            </li>

            <div className="see_more">
              <Link to={"/engineer"}>
                Show More
                <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </ul>
          <ul>
            <li className="dropdown-item-heading">
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                Backend Developer
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                NodeJS
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                Laravel
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                Ruby On Rails
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                MonoDB
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                Express
              </Link>
            </li>
            <div className="see_more">
              <Link to={"/engineer"}>
                Show More
                <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </ul>
          <ul>
            <li className="dropdown-item-heading">
              <Link to={"/hire-a-fullstack-developer"} class="dropdown-item">
                Full Stack Developer
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-react-js-developer"} class="dropdown-item">
                ReactJS
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                NodeJS
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                Mongo DB
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-backend-developer"} class="dropdown-item">
                Express JS
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-frontend-developer"} class="dropdown-item">
                HTML/CSS/JS
              </Link>
            </li>
            <div className="see_more">
              <Link to={"/engineer"}>
                Show More
                <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </ul>
          <ul>
            <li className="dropdown-item-heading">
              <Link to={"/hire-a-android-developer"} class="dropdown-item">
                Mobile Developer
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-react-native-developer"} class="dropdown-item">
                React Native
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-android-developer"} class="dropdown-item">
                Flutter
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-android-developer"} class="dropdown-item">
                Dart
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-frontend-developer"} class="dropdown-item">
                Javascript
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-android-developer"} class="dropdown-item">
                Java
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-android-developer"} class="dropdown-item">
                Kotlin
              </Link>
            </li>
            <div className="see_more">
              <Link to={"/engineer"}>
                Show More
                <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </ul>
          <ul>
            <li className="dropdown-item-heading">
              <Link to={"/hire-a-devops-developer"} class="dropdown-item">
                Devops Engineer
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-devops-developer"} class="dropdown-item">
                GIT
              </Link>
            </li>
            <li>
              <Link to={"/hire-a-devops-developer"} class="dropdown-item">
                AWS
              </Link>
            </li>
            <ul>
              <li>
                <Link to={"/hire-a-QA-developer"} class="dropdown-item">
                  QA Engineer
                </Link>
              </li>
              <li>
                <Link to={"/hire-a-QA-developer"} class="dropdown-item">
                  Automation Testing
                </Link>
              </li>
              <li>
                <Link to={"/hire-a-QA-developer"} class="dropdown-item">
                  Manual
                </Link>
              </li>
            </ul>
            <div className="see_more">
              <Link to={"/engineer"}>
                Show More
                <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </ul>
          <ul>
            <li className="dropdown-item-heading">
              <Link
                to={"/hire-a-graphic-designer-developer"}
                class="dropdown-item"
              >
                Graphic Designer
              </Link>
            </li>
            <li>
              <Link
                to={"/hire-a-graphic-designer-developer"}
                class="dropdown-item"
              >
                Adobe Photoshop
              </Link>
            </li>
            <li>
              <Link
                to={"/hire-a-graphic-designer-developer"}
                class="dropdown-item"
              >
                Figma
              </Link>
            </li>
            <li>
              <Link
                to={"/hire-a-graphic-designer-developer"}
                class="dropdown-item"
              >
                InDesign
              </Link>
            </li>
            <li>
              <Link
                to={"/hire-a-graphic-designer-developer"}
                class="dropdown-item"
              >
                Branding
              </Link>
            </li>
            <div className="see_more">
              <Link to={"/engineer"}>
                Show More
                <i class="fas fa-arrow-right"></i>
              </Link>
            </div>
          </ul>
        </div>
        <div className="container .header_nav ">
          <Link to={"/"} className="navbar-brand">
            <img className={isScrolled ? "small-logo" : ""} src={Logo} alt="" />
          </Link>
          <button
            className="navbar-toggler customStyle"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowMbNav(!showMbNav)}
          >
            {showMbNav ? (
              <img height={16} width={16} src={Close} alt="close menu button" />
            ) : (
              <img height={16} width={16} src={Bars} alt="menu button" />
            )}
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <Link to={"/"}>
                <li className="nav-item">
                  <a className="nav-link main-nav" tabIndex="-1">
                    Home
                  </a>
                </li>
              </Link>
              <Link to={"/about-us"}>
                <li className="nav-item">
                  <a className="nav-link main-nav" tabIndex="-1">
                    About Us
                  </a>
                </li>
              </Link>
              <Link to={"/engineer"}>
                <li
                  className="nav-item dropdown"
                  onMouseEnter={() => setShowDropdown(true)}
                  onMouseLeave={() => setShowDropdown(false)}
                >
                  <a className="nav-link main-nav" tabIndex="-1">
                    Engineers
                  </a>
                </li>
              </Link>

              <Link to={"/blog"}>
                <li className="nav-item">
                  <a className="nav-link main-nav" tabIndex="-1">
                    Blog
                  </a>
                </li>
              </Link>

              {/* <Link to={"/contact-us"}>
                <li className="nav-item">
                  <a className="nav-link main-nav" tabIndex="-1">
                    Contact Us
                  </a>
                </li>
              </Link> */}
            </ul>

            <div className="header-btn-group">
              <Link to={"/join-a-team"} className="hire-cta">
                <button className="step-back_btn_header">Join a Team</button>
              </Link>
              <Link to={"/hire-a-team"}>
                <button className="step-btn">Hire a Team</button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
