import React, { Suspense, useEffect, useState } from "react";
import Herosection from "./Herosection";
import Header from "./Header/Header";
import Techanimation from "./techanimation/Techanimation";
import Ourclientanimaton from "../components/ourclientanimtion/Ourclientsanimation";
import Steps from "./Steps/Steps";
import { Link, useParams } from "react-router-dom";
import WhyRemotepad from "./WhyRemotpad/WhyRemotepad";
// import Readytoget from "./readytogetsection/Readytoget";
import Developerstack from "./developerstack/Developerstack";
import { developer } from "./SkillsSlider/developersData";
import UpdatedFooter from "./footer/UpdatedFooter";

const Readytoget = React.lazy(() => import("./readytogetsection/Readytoget"));

const MainPage = () => {
  const { techTitle } = useParams();
  const filteredDevelopers = developer.filter((item) =>
    item.technology.includes(techTitle)
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [techTitle]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [techTitle]);
  return (
    <React.Fragment>
      <Header />
      <Herosection />
      <Ourclientanimaton id="clients" />
      <Steps />
      <Techanimation />
      <WhyRemotepad />
      <div className="my-5">
        <Developerstack />
      </div>
      <Suspense fallback={<div>loading....</div>}>
        <Readytoget devlopers={filteredDevelopers} />
      </Suspense>
      <UpdatedFooter />
    </React.Fragment>
  );
};

export default MainPage;
