import React from "react";
import { Link } from "react-router-dom";
import { CgSoftwareDownload } from "react-icons/cg";

const EngrCard = ({
  title,
  exp,
  skills,
  item,
  path,
  hideBtn,
  img,
  name,
  devProfile,
  des,
}) => {
  return (
    <div className="position-relative">
      <div className="card-outer">
        <img src={img} alt="" />
      </div>
      <div className="engr-card">
        <div className="eng-name-wrapper">
          <p className="eng-name-p mb-0">{name}</p>

          <div className="exp-wrapper">
            <div className="exp-inner">
              <p className="exp-inner-year mb-0">Years Experience</p>
              <div className="exp-inner-year-num">
                <p className="mb-0">{exp}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="engr-img-box">
          <img src={devProfile} alt="" />
        </div>

        <div className="description">
          <h3 className="fw-bold">{title}</h3>
          <p className="des">
            {
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever"
            }
          </p>
          <div className="skill d-flex justify-content-space-between ">
            {skills?.slice(0, 3)?.map((skills, index) => (
              <div
                key={index}
                className="techicon step-btn-skill d-flex align-items-center "
              >
                {/* <div className="skills_img">
                  <img src={skills.image} alt="" />
                </div> */}
                <p className="text-engineer">{skills?.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="engr-overlay-card">
          <button className="overlay-resume-btn">
            <p className="mb-0">Resume</p> <CgSoftwareDownload size={20}/>
          </button>
        </div>
      </div>
      {hideBtn ? null : (
        <div className="text-center mt-4 mb-4 mb-sm-0">
          <Link to={`/hire-me-form/${path[0]}-developer`}>
            <button
              onClick={() => {
                localStorage.setItem("devDetails", JSON.stringify(item));
              }}
              className="step-btn"
            >
              {" "}
              Hire Me
              <i class="fas fa-arrow-right"></i>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default EngrCard;
