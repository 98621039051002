// Define your slider data for different technologies
import react from "../Assets/react.png";
import js from "../Assets/js.svg";
import node from "../Assets/node-js.png";
import profile from "../Assets/Ellipse 3.webp";
import mongo from "../Assets/mongo.svg";
import java from "../Assets/java.webp";
import express from "../Assets/express.png";
// import graphql from "../Assets/";
import laravel from "../Assets/laravel.webp";
import ts from "../Assets/typescript.png";
import next from "../Assets/nextjs.webp";
import angular from "../Assets/angular.png";
import kotlin from "../Assets/kotlin.webp";
import dart from "../Assets/dart.png";
import git from "../Assets/git.webp";
import aws from "../Assets/aws.webp";
import blockchain from "../Assets/blockchain.png";
import rust from "../Assets/rust.png";
import php from "../Assets/php.webp";
import swift from "../Assets/swift.png";
import flash from "../Assets/flash.png";
import numpy from "../Assets/numpy.webp";
import c from "../Assets/c.png";
import django from "../Assets/django.png";
import test from "../Assets/test.webp";
import graphql from "../Assets/graph.webp";
import photoshop from "../Assets/photoshop.webp";
import figma from "../Assets/figma.png";
import indesign from "../Assets/Adobe Indesign CC.webp";
import branding from "../Assets/branding.webp";
import vue from "../Assets/Vue.js_Logo_2.webp";
import MVC from "../Assets/MVC.png";
import mvcc from "../Assets/mvcc.webp";
import devops from "../Assets/devops.png";
import f from "../Assets/f#.png";
import net from "../Assets/dotnet.webp";
import wordpress from "../Assets/wordpress.png";
import shopify from "../Assets/shopify.png";
import architure from "../Assets/architecture.webp";
import app from "../Assets/app.webp";
import sql from "../Assets/sql.png";
import cloud from "../Assets/cloud.webp";
import microservices from "../Assets/microservices.webp";
import service from "../Assets/cloudservice.webp";
import docker from "../Assets/docker.png";
import window from "../Assets/window.webp";
import manager from "../Assets/project.webp";
import leadership from "../Assets/leadership.webp";
import python from "../Assets/python.png";
import frontend from "../Assets/frontend.webp";
import backend from "../Assets/backendd.png";
//Profile Pictures
import Wajid from "../Assets/developersImages/wajid.webp";
import AliHamza from "../Assets/developersImages/alihamza.webp";
import Sheharyar from "../Assets/developersImages/shehryar.webp";
import Shoaib from "../Assets/developersImages/shoaib.webp";
import Tayyab from "../Assets/developersImages/tayyab.webp";
import Ahmed from "../Assets/developersImages/ahmed.webp";
import moazam from "../Assets/developersImages/moazam.webp";
import rizwan from "../Assets/developersImages/rizwan.webp";
import Usman from "../Assets/developersImages/usman.webp";
import Billy from "../Assets/developersImages/billy.webp";
import SheharyarJavaid from "../Assets/developersImages/SheharyarJavaid.webp";
import Haider from "../Assets/developersImages/Haider.webp";
import Zaidi from "../Assets/developersImages/zaidi.webp";

export const developer = [
  {
    id: 1,
    name: "Ali Hamza",
    email: "ali@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: frontend },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
    ],

    technology: ["fullstack"],
    category: "Full Stack",
    image: AliHamza,
    EmpExp: "03",
  },
  {
    id: 2,
    name: "Wajid Ali",
    email: "ali@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: frontend },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
    ],

    technology: ["fullstack"],
    category: "Full Stack",
    image: Wajid,
    EmpExp: "03",
  },
  {
    id: 3,
    name: "Ahmed Khalid",
    email: "ali@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "js", image: js },
      { name: "Express", image: express },
      { name: "Java", image: mongo },
      { name: "Python", image: js },
      { name: "Ruby", image: express },
      { name: "Node.js", image: mongo },
      { name: "C#", image: js },
      { name: "GraphQl", image: express },
    ],

    technology: ["node-js", "fullstack"],
    category: "Backend",
    image: Ahmed,
    EmpExp: "03",
  },
  {
    id: 4,
    name: "Sheharyar Khan",
    email: "alih@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: frontend },
      { name: "CSS", image: js },
      { name: "Angular", image: ts },
      { name: "Vue.js", image: react },
      { name: "Mui", image: js },
      { name: "Antd", image: js },
      { name: "typescript", image: ts },
      { name: "Chakra-ui", image: ts },
      { name: "Redux", image: ts },
      { name: "RTK", image: ts },
      { name: "React-Query", image: ts },
    ],

    technology: ["frontend", "react-js", "software-engineer", "react-native"],
    category: "Frontend",
    image: Sheharyar,
    EmpExp: "03",
  },
  {
    id: 5,
    name: "Rizwan",
    email: "sumaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: frontend },
      { name: "CSS", image: js },
      { name: "Angular", image: ts },
      { name: "Vue.js", image: react },
      { name: "Mui", image: js },
      { name: "Antd", image: js },
      { name: "typescript", image: ts },
      { name: "Chakra-ui", image: ts },
      { name: "Redux", image: ts },
      { name: "RTK", image: ts },
      { name: "React-Query", image: ts },
    ],
    technology: [
      "frontend",
      "react-js",
      "wordpress",
      "shopify",
      "react-native",
    ],
    image: rizwan,
    EmpExp: "03",
    category: "Frontend",
  },
  {
    id: 6,
    name: "Tayyab",
    email: "anam@gmail.com",
    stack: [
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: frontend },
      { name: "CSS", image: js },
      { name: "React", image: react },
      { name: "Angular", image: ts },
      { name: "Vue.js", image: react },
      { name: "Mui", image: js },
      { name: "Chakra-ui", image: ts },
      { name: "Angular CLI", image: js },
      { name: "RxJS", image: js },
      { name: "Jasmine", image: js },
      { name: "Angular Material", image: js },
    ],

    technology: ["angular", "frontend", "react-js", "react-native"],
    image: Tayyab,
    EmpExp: "03",
    category: "Frontend",
  },
  {
    id: 7,
    name: "Ahmed Khalid",
    email: "asad@gmail.com",
    stack: [
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: frontend },
      { name: "CSS", image: js },
      { name: "React", image: react },
      { name: "Angular", image: ts },
      { name: "Vue.js", image: react },
      { name: "Mui", image: js },
      { name: "Chakra-ui", image: ts },
      { name: "Angular CLI", image: js },
      { name: "RxJS", image: js },
      { name: "Jasmine", image: js },
      { name: "Angular Material", image: js },
    ],

    technology: ["angular", "frontend", "react-js", "react-native"],
    image: Ahmed,
    EmpExp: "03",
    category: "Frontend",
  },
  {
    id: 8,
    name: "Wajid",
    email: "asad@gmail.com",
    stack: [
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: frontend },
      { name: "CSS", image: js },
      { name: "React", image: react },
      { name: "Angular", image: ts },
      { name: "Vue.js", image: react },
      { name: "Mui", image: js },
      { name: "Chakra-ui", image: ts },
      { name: "Angular CLI", image: js },
      { name: "RxJS", image: js },
      { name: "Jasmine", image: js },
      { name: "Angular Material", image: js },
    ],

    technology: ["angular"],
    image: Wajid,
    EmpExp: "03",
    category: "Frontend",
  },
  {
    id: 9,
    name: "Tayyab",
    email: "tayyab@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "Express", image: express },
      { name: "GraphSql", image: graphql },
      { name: "Java", image: mongo },
      { name: "Python", image: express },
      { name: "Ruby", image: graphql },
      { name: "Node.js", image: mongo },
      { name: "C#", image: express },
      { name: "MySql", image: graphql },
      { name: "PHP", image: mongo },
      { name: "GraphQl", image: express },
    ],

    technology: ["backend", "fullstack"],
    image: Tayyab,
    EmpExp: "03",
    category: "Backend",
  },
  {
    id: 10,
    name: "Ahmed Khalid",
    email: "sheharyar@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "Express", image: express },
      { name: "GraphSql", image: graphql },
      { name: "Java", image: mongo },
      { name: "Python", image: express },
      { name: "Ruby", image: graphql },
      { name: "Node.js", image: mongo },
      { name: "C#", image: express },
      { name: "MySql", image: graphql },
      { name: "PHP", image: mongo },
      { name: "GraphQl", image: express },
    ],

    technology: ["backend"],
    image: Ahmed,
    EmpExp: "03",
    category: "Backend",
  },
  {
    id: 11,
    name: "Wajid Ali",
    email: "sheharyar@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "Express", image: express },
      { name: "GraphSql", image: graphql },
      { name: "Java", image: mongo },
      { name: "Python", image: express },
      { name: "Ruby", image: graphql },
      { name: "Node.js", image: mongo },
      { name: "C#", image: express },
      { name: "MySql", image: graphql },
      { name: "PHP", image: mongo },
      { name: "GraphQl", image: express },
    ],

    technology: ["backend"],
    image: Wajid,
    EmpExp: "03",
    category: "Backend",
  },
  {
    id: 12,
    name: "Rizwan",
    email: "sumbal@gmail.com",
    stack: [
      { name: "java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Android SDK", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["android"],
    image: rizwan,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 13,
    name: "Shoaib",
    email: "sumbal@gmail.com",
    stack: [
      { name: "java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Android SDK", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["android"],
    image: Shoaib,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 14,
    name: "Ahmed khalid",
    email: "sumbal@gmail.com",
    stack: [
      { name: "java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Android SDK", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["android"],
    image: Ahmed,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 15,
    name: "Billy",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Swift (iOS)", image: java },
      { name: "React Native", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["flutter"],
    image: Billy,
    EmpExp: "02",
    category: "Flutter",
  },
  {
    id: 16,
    name: "Ahmed Khalid",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Swift (iOS)", image: java },
      { name: "React Native", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["flutter"],
    image: Ahmed,
    EmpExp: "02",
    category: "Flutter",
  },
  {
    id: 17,
    name: "Sheharyar Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Swift (iOS)", image: java },
      { name: "React Native", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["android", "flutter"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 18,
    name: "Sheharyar Zaidi",
    email: "maryam@gmail.com",
    stack: [
      { name: "API Testing", image: test },
      { name: "Automated Testing", image: test },
      { name: "Manual Testing", image: test },
      { name: "Test Planning", image: test },
      { name: "Test Case Design", image: test },
      { name: "Defect Tracking", image: test },
      { name: "Testing Documentation", image: test },
      { name: "Regression Testing", image: test },
      { name: "Performance Testing", image: test },
      { name: "Load Testing", image: test },
      { name: "Security Testing", image: test },
      { name: "Cross-Browser Testing", image: test },
      { name: "Mobile Testing", image: test },
      { name: "Selenium", image: test },
      { name: "Appium", image: test },
      { name: "CI/CD Testing", image: test },
    ],

    technology: ["QA"],
    image: Zaidi,
    EmpExp: "02",
    category: "QA",
  },
  {
    id: 19,
    name: "Usman",
    email: "maryam@gmail.com",
    stack: [
      { name: "API Testing", image: test },
      { name: "Automated Testing", image: test },
      { name: "Manual Testing", image: test },
      { name: "Test Planning", image: test },
      { name: "Test Case Design", image: test },
      { name: "Defect Tracking", image: test },
      { name: "Testing Documentation", image: test },
      { name: "Regression Testing", image: test },
      { name: "Performance Testing", image: test },
      { name: "Load Testing", image: test },
      { name: "Security Testing", image: test },
      { name: "Cross-Browser Testing", image: test },
      { name: "Mobile Testing", image: test },
      { name: "Selenium", image: test },
      { name: "Appium", image: test },
      { name: "CI/CD Testing", image: test },
    ],

    technology: ["QA"],
    image: Usman,
    EmpExp: "02",
    category: "QA",
  },
  {
    id: 20,
    name: "Rizwan",
    email: "maryam@gmail.com",
    stack: [
      { name: "API testing", image: test },
      { name: "Automation", image: test },
      { name: "Manual", image: test },
      { name: "Test Planning", image: test },
      { name: "Test Case Design", image: test },
      { name: "Defect Tracking", image: test },
      { name: "Testing Documentation", image: test },
      { name: "Regression Testing", image: test },
      { name: "Performance Testing", image: test },
      { name: "Load Testing", image: test },
      { name: "Security Testing", image: test },
      { name: "Cross-Browser Testing", image: test },
      { name: "Mobile Testing", image: test },
      { name: "Selenium", image: test },
      { name: "Appium", image: test },
      { name: "CI/CD Testing", image: test },
    ],

    technology: ["QA"],
    image: rizwan,
    EmpExp: "02",
    category: "QA",
  },
  {
    id: 21,
    name: "Shoaib Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "API Testing", image: test },
      { name: "Automated Testing", image: test },
      { name: "Manual Testing", image: test },
      { name: "Test Planning", image: test },
      { name: "Test Case Design", image: test },
      { name: "Defect Tracking", image: test },
      { name: "Testing Documentation", image: test },
      { name: "Regression Testing", image: test },
      { name: "Performance Testing", image: test },
      { name: "Load Testing", image: test },
      { name: "Security Testing", image: test },
      { name: "Cross-Browser Testing", image: test },
      { name: "Mobile Testing", image: test },
      { name: "Selenium", image: test },
      { name: "Appium", image: test },
      { name: "CI/CD Testing", image: test },
    ],

    technology: ["QA"],
    image: Shoaib,
    EmpExp: "02",
    category: "QA",
  },
  {
    id: 22,
    name: "Haider Javaid",
    email: "usmana@gmail.com",
    stack: [
      { name: "Git", image: git },
      { name: "AWS", image: aws },
      { name: "Jenkins", image: git },
      { name: "Travis CI", image: aws },
      { name: "CircleCI", image: git },
      { name: "Docker", image: aws },
      { name: "Kubernetes", image: git },
      { name: "Terraform", image: aws },
      { name: "Ansible", image: git },
      { name: "Chef", image: aws },
      { name: "Jenkins Pipeline", image: git },
      { name: "GitLab CI", image: aws },
      { name: "GitHub Actions", image: git },
      { name: "Microsoft Azure", image: aws },
      { name: "HashiCorp Vault", image: aws },
      { name: "Bash", image: git },
      { name: "Python", image: aws },
      { name: "New Relic", image: git },
      { name: "Datadog", image: aws },
    ],

    technology: ["devops"],
    image: Haider,
    EmpExp: "02",
    category: "Devops",
  },
  {
    id: 23,
    name: "Sheharyar Javaid",
    email: "afzal@gmail.com",
    stack: [
      { name: "Git", image: git },
      { name: "AWS", image: aws },
      { name: "Jenkins", image: git },
      { name: "Travis CI", image: aws },
      { name: "CircleCI", image: git },
      { name: "Docker", image: aws },
      { name: "Kubernetes", image: git },
      { name: "Terraform", image: aws },
      { name: "Ansible", image: git },
      { name: "Chef", image: aws },
      { name: "Jenkins Pipeline", image: git },
      { name: "GitLab CI", image: aws },
      { name: "GitHub Actions", image: git },
      { name: "Microsoft Azure", image: aws },
      { name: "HashiCorp Vault", image: aws },
      { name: "Bash", image: git },
      { name: "Python", image: aws },
      { name: "New Relic", image: git },
      { name: "Datadog", image: aws },
    ],

    technology: ["devops"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "Devops",
  },
  {
    id: 24,
    name: "Ali Hamza",
    email: "afzal@gmail.com",
    stack: [
      { name: "Git", image: git },
      { name: "AWS", image: aws },
      { name: "Jenkins", image: git },
      { name: "Travis CI", image: aws },
      { name: "CircleCI", image: git },
      { name: "Docker", image: aws },
      { name: "Kubernetes", image: git },
      { name: "Terraform", image: aws },
      { name: "Ansible", image: git },
      { name: "Chef", image: aws },
      { name: "Jenkins Pipeline", image: git },
      { name: "GitLab CI", image: aws },
      { name: "GitHub Actions", image: git },
      { name: "Microsoft Azure", image: aws },
      { name: "HashiCorp Vault", image: aws },
      { name: "Bash", image: git },
      { name: "Python", image: aws },
      { name: "New Relic", image: git },
      { name: "Datadog", image: aws },
    ],

    technology: ["devops"],
    image: AliHamza,
    EmpExp: "02",
    category: "Devops",
  },
  {
    id: 25,
    name: "Tayyab",
    email: "afzal@gmail.com",
    stack: [
      { name: "Git", image: git },
      { name: "AWS", image: aws },
      { name: "Jenkins", image: git },
      { name: "Travis CI", image: aws },
      { name: "CircleCI", image: git },
      { name: "Docker", image: aws },
      { name: "Kubernetes", image: git },
      { name: "Terraform", image: aws },
      { name: "Ansible", image: git },
      { name: "Chef", image: aws },
      { name: "Jenkins Pipeline", image: git },
      { name: "GitLab CI", image: aws },
      { name: "GitHub Actions", image: git },
      { name: "Microsoft Azure", image: aws },
      { name: "HashiCorp Vault", image: aws },
      { name: "Bash", image: git },
      { name: "Python", image: aws },
      { name: "New Relic", image: git },
      { name: "Datadog", image: aws },
    ],

    technology: ["devops"],
    image: Tayyab,
    EmpExp: "02",
    category: "Devops",
  },
  {
    id: 26,
    name: "Ali Hamza",
    email: "zobi@gmail.com",
    stack: [
      { name: "Solidity", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Ethereum", image: blockchain },
      { name: "Web3.js", image: rust },
      { name: "Consensus", image: blockchain },
      { name: "Decentralized Applications", image: rust },
      { name: "Smart Contract Security", image: blockchain },
      { name: "Token Standards", image: rust },
      { name: "Hyperledger Fabric", image: blockchain },
      { name: "Interoperability", image: rust },
      { name: "Hash Functions", image: blockchain },
      { name: "DLT", image: rust },
      { name: "Contract Auditing", image: blockchain },
      { name: "Contract Deployment", image: rust },
      { name: "Wasm", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Truffle", image: blockchain },
    ],

    technology: ["blockchain", "Truffle", "Hardhat"],
    image: AliHamza,
    EmpExp: "02",
    category: "Blockchain",
  },
  {
    id: 27,
    name: "Ahmed Khalid",
    email: "afzal@gmail.com",
    stack: [
      { name: "Solidity", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Ethereum", image: blockchain },
      { name: "Web3.js", image: rust },
      { name: "Consensus", image: blockchain },
      { name: "Decentralized Applications", image: rust },
      { name: "Smart Contract Security", image: blockchain },
      { name: "Token Standards", image: rust },
      { name: "Hyperledger Fabric", image: blockchain },
      { name: "Interoperability", image: rust },
      { name: "Hash Functions", image: blockchain },
      { name: "DLT", image: rust },
      { name: "Contract Auditing", image: blockchain },
      { name: "Contract Deployment", image: rust },
      { name: "Wasm", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Truffle", image: blockchain },
    ],

    technology: ["blockchain", "Truffle", "Hardhat"],
    image: Ahmed,
    EmpExp: "02",
    category: "Blockchain",
  },
  {
    id: 28,
    name: "Sheharyar Khan",
    email: "afzal@gmail.com",
    stack: [
      { name: "Solidity", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Ethereum", image: blockchain },
      { name: "Web3.js", image: rust },
      { name: "Consensus", image: blockchain },
      { name: "Decentralized Applications", image: rust },
      { name: "Smart Contract Security", image: blockchain },
      { name: "Token Standards", image: rust },
      { name: "Hyperledger Fabric", image: blockchain },
      { name: "Interoperability", image: rust },
      { name: "Hash Functions", image: blockchain },
      { name: "DLT", image: rust },
      { name: "Contract Auditing", image: blockchain },
      { name: "Contract Deployment", image: rust },
      { name: "Wasm", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Truffle", image: blockchain },
    ],

    technology: ["blockchain", "Truffle", "Hardhat"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Blockchain",
  },
  {
    id: 29,
    name: "Tayyab",
    email: "afzal@gmail.com",
    stack: [
      { name: "Solidity", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Ethereum", image: blockchain },
      { name: "Web3.js", image: rust },
      { name: "Consensus", image: blockchain },
      { name: "Decentralized Applications", image: rust },
      { name: "Smart Contract Security", image: blockchain },
      { name: "Token Standards", image: rust },
      { name: "Hyperledger Fabric", image: blockchain },
      { name: "Interoperability", image: rust },
      { name: "Hash Functions", image: blockchain },
      { name: "DLT", image: rust },
      { name: "Contract Auditing", image: blockchain },
      { name: "Contract Deployment", image: rust },
      { name: "Wasm", image: blockchain },
      { name: "Rust", image: rust },
      { name: "Truffle", image: blockchain },
    ],

    technology: ["blockchain", "Truffle", "Hardhat"],
    image: Tayyab,
    EmpExp: "02",
    category: "Blockchain",
  },
  {
    id: 30,
    name: "Umar",
    email: "umar@gmail.com",
    stack: [
      { name: "Xcode", image: swift },
      { name: "MVC", image: MVC },
      { name: "MVVM", image: mvcc },
      { name: "CI / CD", image: devops },
      { name: "Xcode IDE", image: swift },
      { name: "UIKit Framework", image: MVC },
      { name: "SwiftUI Framework", image: mvcc },
      { name: "Auto Layout", image: swift },
      { name: "Interface Builder", image: MVC },
      { name: "Core Data", image: mvcc },
      { name: "Cocoa Touch", image: swift },
      { name: "RESTful APIs", image: MVC },
      { name: "HIG", image: mvcc },
      { name: "Push Notifications", image: swift },
      { name: "Core Animation", image: MVC },
      { name: "TDD", image: mvcc },
      { name: "CI for iOS", image: mvcc },
    ],

    technology: ["swift", "Hardhat"],
    image: profile,
    EmpExp: "02",
    category: "IOS",
  },
  {
    id: 31,
    name: "Billy",
    email: "umar@gmail.com",
    stack: [
      { name: "C#", image: c },
      { name: ".NET Core", image: net },
      { name: "F#", image: f },
      { name: "ASP.NET Core", image: c },
      { name: "MVC", image: c },
      { name: "Entity Framework", image: c },
      { name: "SQL Server", image: c },
      { name: "Blazor", image: c },
      { name: "Azure", image: c },
      { name: "Azure DevOps", image: c },
      { name: "Git", image: c },
      { name: "Docker", image: c },
      { name: "Unit Testing", image: c },
      { name: "RESTful API", image: c },
    ],

    technology: ["dot-net"],
    image: Billy,
    EmpExp: "02",
    category: ".Net",
  },
  {
    id: 32,
    name: "Ali Hamza",
    email: "umar@gmail.com",
    stack: [
      { name: "Flask", image: flash },
      { name: "Django", image: django },
      { name: "Numpy", image: numpy },
      { name: "Java", image: flash },
      { name: "Python", image: django },
      { name: "C#", image: numpy },
    ],

    technology: ["python", "backend"],
    image: AliHamza,
    EmpExp: "02",
    category: "Python",
  },
  {
    id: 33,
    name: "Tayyab",
    email: "sami@gmail.com",
    stack: [
      { name: "Flask", image: flash },
      { name: "Django", image: django },
      { name: "Numpy", image: numpy },
      { name: "Java", image: flash },
      { name: "Python", image: django },
      { name: "C#", image: numpy },
    ],
    technology: ["python"],
    image: Tayyab,
    EmpExp: "02",
    category: "Backend",
  },
  {
    id: 34,
    name: "Ahmed Khalid",
    email: "sami@gmail.com",
    stack: [
      { name: "Flask", image: flash },
      { name: "Django", image: django },
      { name: "Numpy", image: numpy },
      { name: "Java", image: flash },
      { name: "Python", image: django },
      { name: "C#", image: numpy },
    ],
    technology: ["python"],
    image: Ahmed,
    EmpExp: "02",
    category: "Backend",
  },
  {
    id: 35,
    name: "Wajid Ali",
    email: "sami@gmail.com",
    stack: [
      { name: "Flask", image: flash },
      { name: "Django", image: django },
      { name: "Numpy", image: numpy },
      { name: "Java", image: flash },
      { name: "Python", image: django },
      { name: "C#", image: numpy },
    ],
    technology: ["python"],
    image: Wajid,
    EmpExp: "02",
    category: "Backend",
  },
  {
    id: 36,
    name: "sami",
    email: "sami@gmail.com",
    stack: [
      { name: "JavaScript", image: js },
      { name: "Typescript", image: ts },
      { name: "React Native", image: react },
      { name: "React", image: js },
      { name: "Expo", image: ts },
      { name: "Redux", image: js },
      { name: "React Context API", image: ts },
      { name: "NativeBase", image: js },
      { name: "Styled Components", image: ts },
      { name: "Axios", image: js },
      { name: "AsyncStorage", image: ts },
      { name: "Realm", image: js },
      { name: "SQLite", image: ts },
      { name: "REST", image: js },
      { name: "GraphQL", image: ts },
      { name: "Jest", image: js },
      { name: "Detox", image: ts },
      { name: "SSL Pinning", image: ts },
    ],

    technology: ["react-native", "android"],
    image: profile,
    EmpExp: "02",
    category: "Android",
  },
  {
    id: 37,
    name: "zafar",
    email: "zafar@gmail.com",
    stack: [
      { name: "JavaScript", image: js },
      { name: "Typescript", image: ts },
      { name: "React Native", image: react },
      { name: "React", image: js },
      { name: "Expo", image: ts },
      { name: "Redux", image: js },
      { name: "React Context API", image: ts },
      { name: "NativeBase", image: js },
      { name: "Styled Components", image: ts },
      { name: "Axios", image: js },
      { name: "AsyncStorage", image: ts },
      { name: "Realm", image: js },
      { name: "SQLite", image: ts },
      { name: "REST", image: js },
      { name: "GraphQL", image: ts },
      { name: "Jest", image: js },
      { name: "Detox", image: ts },
      { name: "SSL Pinning", image: ts },
    ],

    technology: ["react-native"],
    image: profile,
    EmpExp: "02",
    category: "Android",
  },
  {
    id: 38,
    name: "Ali Hamza",
    email: "sumaira@gmail.com",
    stack: [
      { name: "PHP", image: php },
      { name: "Laravel", image: laravel },
      { name: "MongoDB", image: mongo },
      { name: "Symfony", image: php },
      { name: "CodeIgniter", image: laravel },
      { name: "MVC", image: mongo },
      { name: "Composer", image: php },
      { name: "RESTful API", image: laravel },
      { name: "MySQL", image: mongo },
      { name: "ORM", image: php },
      { name: "PHPUnit", image: laravel },
      { name: "Git", image: mongo },
      { name: "JavaScript", image: mongo },
    ],

    technology: ["PHP", "Backend"],
    image: AliHamza,
    EmpExp: "02",
    category: "Backend",
  },
  {
    id: 39,
    name: "Wajid Ali",
    email: "asma@gmail.com",
    stack: [
      { name: "PHP", image: php },
      { name: "Laravel", image: laravel },
      { name: "MongoDB", image: mongo },
      { name: "Symfony", image: php },
      { name: "CodeIgniter", image: laravel },
      { name: "MVC", image: mongo },
      { name: "Composer", image: php },
      { name: "RESTful API", image: laravel },
      { name: "MySQL", image: mongo },
      { name: "ORM", image: php },
      { name: "PHPUnit", image: laravel },
      { name: "Git", image: mongo },
      { name: "JavaScript", image: mongo },
    ],

    technology: ["PHP", "Backend"],
    image: Wajid,
    EmpExp: "02",
    category: "Backend",
  },
  {
    id: 40,
    name: "Tayyab",
    email: "asma@gmail.com",
    stack: [
      { name: "Swift", image: swift },
      { name: "Kotlin", image: java },
      { name: "React Native", image: swift },
      { name: "Flutter", image: java },
      { name: "Xamarin", image: swift },
      { name: "UIKit", image: java },
      { name: "Android XML", image: swift },
      { name: "Core Data", image: java },
      { name: "Room", image: swift },
      { name: "XCTest", image: java },
      { name: "JUnit", image: swift },
      { name: "App Store", image: java },
      { name: "Google Play Store", image: swift },
    ],

    technology: ["app", "swift"],
    image: Tayyab,
    EmpExp: "02",
    category: "APP",
  },
  {
    id: 41,
    name: "Sheharyar Javaid",
    email: "noor@gmail.com",
    stack: [
      { name: "Swift", image: swift },
      { name: "Kotlin", image: java },
      { name: "React Native", image: swift },
      { name: "Flutter", image: java },
      { name: "Xamarin", image: swift },
      { name: "UIKit", image: java },
      { name: "Android XML", image: swift },
      { name: "Core Data", image: java },
      { name: "Room", image: swift },
      { name: "XCTest", image: java },
      { name: "JUnit", image: swift },
      { name: "App Store", image: java },
      { name: "Google Play Store", image: swift },
    ],
    technology: ["app", "swift"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "APP",
  },
  {
    id: 42,
    name: "Shoaib",
    email: "alih@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: frontend },
      { name: "CSS", image: js },
      { name: "Angular", image: ts },
      { name: "Vue.js", image: react },
      { name: "Mui", image: js },
      { name: "Antd", image: js },
      { name: "typescript", image: ts },
      { name: "Chakra-ui", image: ts },
    ],

    technology: ["frontend", "web", "wordpress", "vue-js", "next-js"],
    image: Shoaib,
    EmpExp: "03",
    category: "Web developer",
  },
  {
    id: 43,
    name: "Moazam",
    email: "sanam@gmail.com",
    stack: [
      { name: "Adobe XD", image: photoshop },
      { name: "Figma", image: figma },
      { name: "InDesign", image: indesign },
      { name: "Branding", image: branding },
      { name: "Sketch", image: figma },
    ],

    technology: ["graphic-designer"],
    image: moazam,
    EmpExp: "03",
    category: "Graphic Designer",
  },
  {
    id: 44,
    name: "Usman",
    email: "alih@gmail.com",
    stack: [
      { name: "Adobe XD", image: photoshop },
      { name: "Figma", image: figma },
      { name: "InDesign", image: indesign },
      { name: "Branding", image: branding },
      { name: "Sketch", image: figma },
    ],

    technology: ["graphic-designer"],
    image: Usman,
    EmpExp: "03",
    category: "Graphic Designer",
  },
  {
    id: 45,
    name: "Billy",
    email: "alih@gmail.com",
    stack: [
      { name: "Adobe XD", image: photoshop },
      { name: "Figma", image: figma },
      { name: "InDesign", image: indesign },
      { name: "Branding", image: branding },
      { name: "Sketch", image: figma },
    ],

    technology: ["graphic-designer"],
    image: Billy,
    EmpExp: "03",
    category: "Graphic Designer",
  },
  {
    id: 46,
    name: "Rizwan",
    email: "alih@gmail.com",
    stack: [
      { name: "Adobe XD", image: photoshop },
      { name: "Figma", image: figma },
      { name: "InDesign", image: indesign },
      { name: "Branding", image: branding },
      { name: "Sketch", image: figma },
    ],

    technology: ["graphic-designer"],
    image: rizwan,
    EmpExp: "03",
    category: "Graphic Designer",
  },
  {
    id: 47,
    name: "Sheharyar Khan",
    email: "alih@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "js", image: js },
      { name: "Express", image: express },
      { name: "Java", image: mongo },
      { name: "Python", image: js },
      { name: "Ruby", image: express },
      { name: "Node.js", image: mongo },
      { name: "C#", image: js },
      { name: "GraphQl", image: express },
    ],

    technology: ["node-js"],
    image: Sheharyar,
    EmpExp: "03",
    category: "Backend",
  },
  {
    id: 48,
    name: "Wajid Ali",
    email: "alih@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "js", image: js },
      { name: "Express", image: express },
      { name: "Java", image: mongo },
      { name: "Python", image: js },
      { name: "Ruby", image: express },
      { name: "Node.js", image: mongo },
      { name: "C#", image: js },
      { name: "GraphQl", image: express },
    ],

    technology: ["node-js"],
    image: Wajid,
    EmpExp: "03",
    category: "Backend",
  },
  {
    id: 49,
    name: "Tayyab",
    email: "alih@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "js", image: js },
      { name: "Express", image: express },
      { name: "Java", image: mongo },
      { name: "Python", image: js },
      { name: "Ruby", image: express },
      { name: "Node.js", image: mongo },
      { name: "C#", image: js },
      { name: "GraphQl", image: express },
    ],

    technology: ["node-js"],
    image: Tayyab,
    EmpExp: "03",
    category: "Backend Developer",
  },
  {
    id: 50,
    name: "Ahmed Khalid",
    email: "ahmed@gmail.com",
    stack: [
      { name: "Wordpress", image: wordpress },
      { name: "Shopify", image: shopify },
      { name: "HTML", image: frontend },
      { name: "CSS", image: wordpress },
      { name: "JavaScript", image: shopify },
      { name: "PHP", image: app },
      { name: "Ruby", image: wordpress },
      { name: "Python", image: shopify },
      { name: "React", image: app },
      { name: "Angular", image: wordpress },
      { name: "Vue.js", image: shopify },
      { name: "Next.js", image: app },
      { name: "Laravel", image: app },
      { name: "Theme Dev", image: app },
      { name: "Plugin Dev", image: app },
    ],

    technology: ["web", "wordpress", "shopify"],
    image: Ahmed,
    EmpExp: "03",
    category: "Web Developer",
  },
  {
    id: 51,
    name: "Sheharyar Khan",
    email: "sheharyarkhan@gmail.com",
    stack: [
      { name: "Wordpress", image: wordpress },
      { name: "Shopify", image: shopify },
      { name: "HTML", image: frontend },
      { name: "CSS", image: wordpress },
      { name: "JavaScript", image: shopify },
      { name: "PHP", image: app },
      { name: "Ruby", image: wordpress },
      { name: "Python", image: shopify },
      { name: "React", image: app },
      { name: "Angular", image: wordpress },
      { name: "Vue.js", image: shopify },
      { name: "Next.js", image: app },
      { name: "Laravel", image: app },
      { name: "Theme Dev", image: app },
      { name: "Plugin Dev", image: app },
    ],

    technology: ["web", "wordpress"],
    image: Sheharyar,
    EmpExp: "03",
    category: "Web Developer",
  },
  {
    id: 52,
    name: "Ahmed Khalid",
    email: "umar@gmail.com",
    stack: [
      { name: "C#", image: c },
      { name: ".NET Core", image: net },
      { name: "F#", image: f },
      { name: "ASP.NET Core", image: c },
      { name: "MVC", image: c },
      { name: "Entity Framework", image: c },
      { name: "SQL Server", image: c },
      { name: "Blazor", image: c },
      { name: "Azure", image: c },
      { name: "Azure DevOps", image: c },
      { name: "Git", image: c },
      { name: "Docker", image: c },
      { name: "Unit Testing", image: c },
      { name: "RESTful API", image: c },
    ],

    technology: ["dot-net"],
    image: Ahmed,
    EmpExp: "02",
    category: ".Net",
  },
  {
    id: 53,
    name: "Sheharyar Khan",
    email: "umar@gmail.com",
    stack: [
      { name: "C#", image: c },
      { name: ".NET Core", image: net },
      { name: "F#", image: f },
      { name: "ASP.NET Core", image: c },
      { name: "MVC", image: c },
      { name: "Entity Framework", image: c },
      { name: "SQL Server", image: c },
      { name: "Blazor", image: c },
      { name: "Azure", image: c },
      { name: "Azure DevOps", image: c },
      { name: "Git", image: c },
      { name: "Docker", image: c },
      { name: "Unit Testing", image: c },
      { name: "RESTful API", image: c },
    ],

    technology: ["dot-net"],
    image: Sheharyar,
    EmpExp: "02",
    category: ".Net",
  },
  {
    id: 54,
    name: "Rizwan",
    email: "umar@gmail.com",
    stack: [
      { name: "C#", image: c },
      { name: ".NET Core", image: net },
      { name: "F#", image: f },
      { name: "ASP.NET Core", image: c },
      { name: "MVC", image: c },
      { name: "Entity Framework", image: c },
      { name: "SQL Server", image: c },
      { name: "Blazor", image: c },
      { name: "Azure", image: c },
      { name: "Azure DevOps", image: c },
      { name: "Git", image: c },
      { name: "Docker", image: c },
      { name: "Unit Testing", image: c },
      { name: "RESTful API", image: c },
    ],

    technology: ["dot-net"],
    image: rizwan,
    EmpExp: "02",
    category: ".Net",
  },
  {
    id: 55,
    name: "Sheharyar Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "SQL Server", image: sql },
      { name: "Microservices", image: window },
      { name: "WCF-MSMQ", image: architure },
      { name: "Enterprise-level", image: sql },
      { name: "Cloud Computing", image: window },
      { name: "API Design", image: window },
      { name: "Docker", image: window },
      { name: "System Design", image: window },
      { name: "Kubernetes", image: window },
      { name: "Design Principles", image: window },
      { name: "Technical Leadership", image: window },
      { name: "Strategic Planning", image: window },
      { name: "Cross-Functional", image: window },
      { name: "Vendor Evaluation", image: window },
      { name: "Security Architecture", image: window },
      { name: "Performance Optimization", image: window },
    ],

    technology: ["software-architecture", "cloud", "tech-lead"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "Software Architecture",
  },
  {
    id: 56,
    name: "Haider Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "SQL Server", image: sql },
      { name: "Microservices", image: window },
      { name: "WCF-MSMQ", image: architure },
      { name: "Enterprise-level", image: sql },
      { name: "Cloud Computing", image: window },
      { name: "System Design", image: window },
      { name: "Google Cloud Platform", image: window },
      { name: "Docker", image: window },
      { name: "Kubernetes", image: window },
      { name: "Design Principles", image: window },
      { name: "Technical Leadership", image: window },
      { name: "Strategic Planning", image: window },
      { name: "Cross-Functional", image: window },
      { name: "Vendor Evaluation", image: window },
      { name: "Security Architecture", image: window },
      { name: "Performance Optimization", image: window },
      { name: "AWS", image: window },
      { name: "Azure", image: window },
    ],

    technology: ["software-architecture", "cloud", "tech-lead"],
    image: Haider,
    EmpExp: "02",
    category: "Software Architecture",
  },
  {
    id: 57,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "SQL Server", image: sql },
      { name: "Microservices", image: window },
      { name: "WCF-MSMQ", image: architure },
      { name: "Enterprise-level", image: sql },
      { name: "Cloud Computing", image: window },
      { name: "System Design", image: window },
      { name: "Google Cloud Platform", image: window },
      { name: "Docker", image: window },
      { name: "Kubernetes", image: window },
      { name: "Design Principles", image: window },
      { name: "Technical Leadership", image: window },
      { name: "Strategic Planning", image: window },
      { name: "Cross-Functional", image: window },
      { name: "Vendor Evaluation", image: window },
      { name: "Security Architecture", image: window },
      { name: "Performance Optimization", image: window },
      { name: "AWS", image: window },
      { name: "Azure", image: window },
    ],

    technology: ["software-architecture", "cloud", "tech-lead"],
    image: AliHamza,
    EmpExp: "02",
    category: "Software Architecture",
  },
  {
    id: 58,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "SQL Server", image: sql },
      { name: "Microservices", image: window },
      { name: "WCF-MSMQ", image: architure },
      { name: "Enterprise-level", image: sql },
      { name: "Cloud Computing", image: window },
      { name: "System Design", image: window },
      { name: "Google Cloud Platform", image: window },
      { name: "Docker", image: window },
      { name: "Kubernetes", image: window },
      { name: "Design Principles", image: window },
      { name: "Technical Leadership", image: window },
      { name: "Strategic Planning", image: window },
      { name: "Cross-Functional", image: window },
      { name: "Vendor Evaluation", image: window },
      { name: "Security Architecture", image: window },
      { name: "Performance Optimization", image: window },
      { name: "AWS", image: window },
      { name: "Azure", image: window },
    ],

    technology: ["software-architecture", "cloud", "tech-lead"],
    image: Tayyab,
    EmpExp: "02",
    category: "Software Architecture",
  },
  {
    id: 59,
    name: "Haider Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Project Management", image: manager },
      { name: "Leadership", image: leadership },
      { name: "Strategic Planning", image: manager },
      { name: "Program Management", image: leadership },
      { name: "Budgeting", image: leadership },
      { name: "Resource Allocation", image: leadership },
      { name: "Cross-Func Collaboration", image: leadership },
      { name: "Talent Recruitment", image: leadership },
      { name: "Retention", image: leadership },
      { name: "Conflict Resolution", image: leadership },
      { name: "Risk Management", image: leadership },
      { name: "Agile", image: leadership },
      { name: "Scrum", image: leadership },
    ],

    technology: ["director-of-engineer"],
    image: Haider,
    EmpExp: "02",
    category: "Director of Engineers",
  },
  {
    id: 60,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Project Management", image: manager },
      { name: "Leadership", image: leadership },
      { name: "Strategic Planning", image: manager },
      { name: "Program Management", image: leadership },
      { name: "Budgeting", image: leadership },
      { name: "Resource Allocation", image: leadership },
      { name: "Cross-Func Collaboration", image: leadership },
      { name: "Talent Recruitment", image: leadership },
      { name: "Retention", image: leadership },
      { name: "Conflict Resolution", image: leadership },
      { name: "Risk Management", image: leadership },
      { name: "Agile", image: leadership },
      { name: "Scrum", image: leadership },
    ],

    technology: ["director-of-engineer"],
    image: AliHamza,
    EmpExp: "02",
    category: "Director of Engineers",
  },
  {
    id: 61,
    name: "Sheharyar Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Project Management", image: manager },
      { name: "Leadership", image: leadership },
      { name: "Strategic Planning", image: manager },
      { name: "Program Management", image: leadership },
      { name: "Budgeting", image: leadership },
      { name: "Resource Allocation", image: leadership },
      { name: "Cross-Func Collaboration", image: leadership },
      { name: "Talent Recruitment", image: leadership },
      { name: "Retention", image: leadership },
      { name: "Conflict Resolution", image: leadership },
      { name: "Risk Management", image: leadership },
      { name: "Agile", image: leadership },
      { name: "Scrum", image: leadership },
    ],

    technology: ["director-of-engineer"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "Director of Engineers",
  },
  {
    id: 62,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: python },
      { name: "NumPy and Pandas", image: python },
      { name: "Scikit-Learn", image: python },
      { name: "TensorFlow", image: python },
      { name: "PyTorch", image: python },
      { name: "Keras", image: python },
      { name: "Matplotlib and Seaborn", image: python },
      { name: "Jupyter Notebooks", image: python },
      { name: "Feature Engineering", image: python },
      { name: "Hyperparameter Tuning", image: python },
      { name: "Supervised Learning", image: python },
      { name: "Unsupervised Learning", image: python },
      { name: "NLP", image: python },
      { name: "Computer Vision", image: python },
      { name: "Neural Networks", image: python },
    ],

    technology: ["machine-learning"],
    image: Tayyab,
    EmpExp: "02",
    category: "Machine Learning",
  },
  {
    id: 63,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: python },
      { name: "NumPy and Pandas", image: python },
      { name: "Scikit-Learn", image: python },
      { name: "TensorFlow", image: python },
      { name: "PyTorch", image: python },
      { name: "Keras", image: python },
      { name: "Matplotlib and Seaborn", image: python },
      { name: "Jupyter Notebooks", image: python },
      { name: "Feature Engineering", image: python },
      { name: "Hyperparameter Tuning", image: python },
      { name: "Supervised Learning", image: python },
      { name: "Unsupervised Learning", image: python },
      { name: "NLP", image: python },
      { name: "Computer Vision", image: python },
      { name: "Neural Networks", image: python },
    ],

    technology: ["machine-learning"],
    image: Wajid,
    EmpExp: "02",
    category: "Machine Learning",
  },
  {
    id: 64,
    name: "Sheharyar Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: python },
      { name: "NumPy and Pandas", image: python },
      { name: "Scikit-Learn", image: python },
      { name: "TensorFlow", image: python },
      { name: "PyTorch", image: python },
      { name: "Keras", image: python },
      { name: "Matplotlib and Seaborn", image: python },
      { name: "Jupyter Notebooks", image: python },
      { name: "Feature Engineering", image: python },
      { name: "Hyperparameter Tuning", image: python },
      { name: "Supervised Learning", image: python },
      { name: "Unsupervised Learning", image: python },
      { name: "NLP", image: python },
      { name: "Computer Vision", image: python },
      { name: "Neural Networks", image: python },
    ],

    technology: ["machine-learning"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Machine Learning",
  },
  {
    id: 65,
    name: "Sheharyar Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: python },
      { name: "NumPy and Pandas", image: python },
      { name: "Scikit-Learn", image: python },
      { name: "TensorFlow", image: python },
      { name: "PyTorch", image: python },
      { name: "Keras", image: python },
      { name: "Matplotlib and Seaborn", image: python },
      { name: "Jupyter Notebooks", image: python },
      { name: "Feature Engineering", image: python },
      { name: "Hyperparameter Tuning", image: python },
      { name: "Supervised Learning", image: python },
      { name: "Unsupervised Learning", image: python },
      { name: "NLP", image: python },
      { name: "Computer Vision", image: python },
      { name: "Neural Networks", image: python },
    ],

    technology: ["machine-learning"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "Machine Learning",
  },
  {
    id: 66,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Project Management", image: manager },
      { name: "Leadership", image: leadership },
      { name: "Strategic Planning", image: manager },
      { name: "Program Management", image: leadership },
      { name: "Budgeting", image: leadership },
      { name: "Resource Allocation", image: leadership },
      { name: "Cross-Func Collaboration", image: leadership },
      { name: "Talent Recruitment", image: leadership },
      { name: "Retention", image: leadership },
      { name: "Conflict Resolution", image: leadership },
      { name: "Risk Management", image: leadership },
      { name: "Agile", image: leadership },
      { name: "Scrum", image: leadership },
    ],

    technology: ["director-of-engineer"],
    image: Tayyab,
    EmpExp: "02",
    category: "Director of Engineers",
  },
  {
    id: 67,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "DBMS", image: sql },
      { name: "SQL", image: sql },
      { name: "Oracle Database", image: backend },
      { name: "Database Design", image: sql },
      { name: "Modeling", image: sql },
      { name: "Query Optimization", image: sql },
      { name: "RDBMS", image: sql },
      { name: "NoSQL Databases", image: sql },
      { name: "Database Security", image: sql },
      { name: "Data Backup", image: sql },
      { name: "ETL Processes", image: sql },
      { name: "Database Migration", image: sql },
      { name: "Data Warehousing", image: sql },
      { name: "Cloud Services", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
    ],

    technology: ["database"],
    image: Wajid,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 68,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "DBMS", image: sql },
      { name: "SQL", image: sql },
      { name: "Oracle Database", image: backend },
      { name: "Database Design", image: sql },
      { name: "Modeling", image: sql },
      { name: "Query Optimization", image: sql },
      { name: "RDBMS", image: sql },
      { name: "NoSQL Databases", image: sql },
      { name: "Database Security", image: sql },
      { name: "Data Backup", image: sql },
      { name: "ETL Processes", image: sql },
      { name: "Database Migration", image: sql },
      { name: "Data Warehousing", image: sql },
      { name: "Cloud Services", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
    ],

    technology: ["database"],
    image: Tayyab,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 69,
    name: "Sheharyar Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "DBMS", image: sql },
      { name: "SQL", image: sql },
      { name: "Oracle Database", image: backend },
      { name: "Database Design", image: sql },
      { name: "Modeling", image: sql },
      { name: "Query Optimization", image: sql },
      { name: "RDBMS", image: sql },
      { name: "NoSQL Databases", image: sql },
      { name: "Database Security", image: sql },
      { name: "Data Backup", image: sql },
      { name: "ETL Processes", image: sql },
      { name: "Database Migration", image: sql },
      { name: "Data Warehousing", image: sql },
      { name: "Cloud Services", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
    ],

    technology: ["database"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 70,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "DBMS", image: sql },
      { name: "SQL", image: sql },
      { name: "Oracle Database", image: backend },
      { name: "Database Design", image: sql },
      { name: "Modeling", image: sql },
      { name: "Query Optimization", image: sql },
      { name: "RDBMS", image: sql },
      { name: "NoSQL Databases", image: sql },
      { name: "Database Security", image: sql },
      { name: "Data Backup", image: sql },
      { name: "ETL Processes", image: sql },
      { name: "Database Migration", image: sql },
      { name: "Data Warehousing", image: sql },
      { name: "Cloud Services", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
    ],

    technology: ["database"],
    image: AliHamza,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 71,
    name: "Shoaib",
    email: "shoaib@gmail.com",
    stack: [
      { name: "Theme Dev", image: wordpress },
      { name: "Shopify", image: shopify },
      { name: "HTML", image: app },
      { name: "JavaScript", image: app },
      { name: "CSS", image: app },
      { name: "PHP", image: app },
      { name: "Plugin Dev", image: app },
    ],
    technology: ["shopify"],
    image: Shoaib,
    EmpExp: "02",
    category: "Shopify Developer",
  },
  {
    id: 72,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Theme Dev", image: wordpress },
      { name: "Shopify", image: shopify },
      { name: "HTML", image: app },
      { name: "CSS", image: app },
      { name: "JavaScript", image: app },
      { name: "PHP", image: app },
      { name: "Plugin Dev", image: app },
    ],

    technology: ["shopify"],
    image: Tayyab,
    EmpExp: "02",
    category: "Shopify Developer",
  },
  {
    id: 73,
    name: "Ahmed Khalid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "HTML", image: app },
      { name: "CSS", image: app },
      { name: "JavaScript", image: app },
      { name: "PHP", image: app },
      { name: "Livewrire", image: app },
      { name: "Composer", image: app },
      { name: "MySQL", image: app },
      { name: "PostgreSQL", image: app },
      { name: "RESTful APIs", image: app },
    ],
    technology: ["laravel", "PHP"],
    image: Ahmed,
    EmpExp: "02",
    category: "Laravel Developer",
  },
  {
    id: 74,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "HTML", image: app },
      { name: "CSS", image: app },
      { name: "JavaScript", image: app },
      { name: "PHP", image: app },
      { name: "Livewrire", image: app },
      { name: "Composer", image: app },
      { name: "MySQL", image: app },
      { name: "PostgreSQL", image: app },
      { name: "RESTful APIs", image: app },
    ],
    technology: ["laravel"],
    image: Wajid,
    EmpExp: "02",
    category: "Laravel Developer",
  },
  {
    id: 75,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "HTML", image: app },
      { name: "CSS", image: app },
      { name: "JavaScript", image: app },
      { name: "PHP", image: app },
      { name: "Livewrire", image: app },
      { name: "Composer", image: app },
      { name: "MySQL", image: app },
      { name: "PostgreSQL", image: app },
      { name: "RESTful APIs", image: app },
    ],
    technology: ["laravel", "PHP"],
    image: Tayyab,
    EmpExp: "02",
    category: "Laravel Developer",
  },
  {
    id: 76,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "HTML", image: app },
      { name: "CSS", image: app },
      { name: "JavaScript", image: app },
      { name: "PHP", image: app },
      { name: "Livewrire", image: app },
      { name: "Composer", image: app },
      { name: "MySQL", image: app },
      { name: "PostgreSQL", image: app },
      { name: "RESTful APIs", image: app },
    ],
    technology: ["laravel"],
    image: AliHamza,
    EmpExp: "02",
    category: "Laravel Developer",
  },
  {
    id: 77,
    name: "Rizwan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: react },
      { name: "CSS", image: js },
      { name: "Redux", image: ts },
      { name: "RTK", image: react },
      { name: "React-Query", image: js },
      { name: "Antd", image: ts },
      { name: "Mui", image: react },
      { name: "Chakra-ui", image: js },
      { name: "Node-js", image: ts },
      { name: "Express", image: react },
      { name: "MoongoDb", image: js },
      { name: "GraphQl", image: ts },
      { name: "React", image: react },
      { name: "MySql", image: js },
    ],
    technology: ["mernstack"],
    image: rizwan,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 78,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: react },
      { name: "CSS", image: js },
      { name: "Redux", image: ts },
      { name: "RTK", image: react },
      { name: "React-Query", image: js },
      { name: "Antd", image: ts },
      { name: "Mui", image: react },
      { name: "Chakra-ui", image: js },
      { name: "Node-js", image: ts },
      { name: "Express", image: react },
      { name: "MoongoDb", image: js },
      { name: "GraphQl", image: ts },
      { name: "React", image: react },
      { name: "MySql", image: js },
    ],
    technology: ["mernstack"],
    image: Tayyab,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 79,
    name: "Sheharyar",
    email: "Sheharyar@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: react },
      { name: "CSS", image: js },
      { name: "Redux", image: ts },
      { name: "RTK", image: react },
      { name: "React-Query", image: js },
      { name: "Antd", image: ts },
      { name: "Mui", image: react },
      { name: "Chakra-ui", image: js },
      { name: "Node-js", image: ts },
      { name: "Express", image: react },
      { name: "MoongoDb", image: js },
      { name: "GraphQl", image: ts },
      { name: "React", image: react },
      { name: "MySql", image: js },
    ],
    technology: ["mernstack"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 80,
    name: "Ahmed Khalid",
    email: "Sheharyar@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "HTML", image: react },
      { name: "CSS", image: js },
      { name: "Redux", image: ts },
      { name: "RTK", image: react },
      { name: "React-Query", image: js },
      { name: "Antd", image: ts },
      { name: "Mui", image: react },
      { name: "Chakra-ui", image: js },
      { name: "Node-js", image: ts },
      { name: "Express", image: react },
      { name: "MoongoDb", image: js },
      { name: "GraphQl", image: ts },
      { name: "React", image: react },
      { name: "MySql", image: js },
    ],
    technology: ["mernstack"],
    image: Ahmed,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 81,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Maya", image: react },
      { name: "Unity Game Engine", image: js },
      { name: "C#", image: ts },
      { name: "Unity Scripting API", image: react },
      { name: "Unity UI/UX Design", image: react },
      { name: "Blender", image: react },
      { name: "Unity ShaderLab", image: react },
      { name: "AR or VR Development", image: react },
      { name: "Multiplatform", image: react },
      { name: "Unity Asset Store", image: react },
      { name: "Networking", image: react },
      { name: "Multiplayer", image: react },
      { name: "Unity Physics", image: react },
      { name: "Particle Systems", image: react },
      { name: "Audio Integration", image: react },
      { name: "Sound Design", image: react },
      { name: "Git", image: react },
    ],
    technology: ["game"],
    image: Wajid,
    EmpExp: "02",
    category: "Game Developer",
  },
  {
    id: 82,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Maya", image: react },
      { name: "Unity Game Engine", image: js },
      { name: "C#", image: ts },
      { name: "Unity Scripting API", image: react },
      { name: "Unity UI/UX Design", image: react },
      { name: "Blender", image: react },
      { name: "Unity ShaderLab", image: react },
      { name: "AR or VR Development", image: react },
      { name: "Multiplatform", image: react },
      { name: "Unity Asset Store", image: react },
      { name: "Networking", image: react },
      { name: "Multiplayer", image: react },
      { name: "Unity Physics", image: react },
      { name: "Particle Systems", image: react },
      { name: "Audio Integration", image: react },
      { name: "Sound Design", image: react },
      { name: "Git", image: react },
    ],
    technology: ["game"],
    image: Tayyab,
    EmpExp: "02",
    category: "Game Developer",
  },
  {
    id: 83,
    name: "Rizwan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Maya", image: react },
      { name: "Unity Game Engine", image: js },
      { name: "C#", image: ts },
      { name: "Unity Scripting API", image: react },
      { name: "Unity UI/UX Design", image: react },
      { name: "Blender", image: react },
      { name: "Unity ShaderLab", image: react },
      { name: "AR or VR Development", image: react },
      { name: "Multiplatform", image: react },
      { name: "Unity Asset Store", image: react },
      { name: "Networking", image: react },
      { name: "Multiplayer", image: react },
      { name: "Unity Physics", image: react },
      { name: "Particle Systems", image: react },
      { name: "Audio Integration", image: react },
      { name: "Sound Design", image: react },
      { name: "Git", image: react },
    ],
    technology: ["game"],
    image: rizwan,
    EmpExp: "02",
    category: "Game Developer",
  },
  {
    id: 84,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Maya", image: react },
      { name: "Unity Game Engine", image: js },
      { name: "C#", image: ts },
      { name: "Unity Scripting API", image: react },
      { name: "Unity UI/UX Design", image: react },
      { name: "Blender", image: react },
      { name: "Unity ShaderLab", image: react },
      { name: "AR or VR Development", image: react },
      { name: "Multiplatform", image: react },
      { name: "Unity Asset Store", image: react },
      { name: "Networking", image: react },
      { name: "Multiplayer", image: react },
      { name: "Unity Physics", image: react },
      { name: "Particle Systems", image: react },
      { name: "Audio Integration", image: react },
      { name: "Sound Design", image: react },
      { name: "Git", image: react },
    ],
    technology: ["game"],
    image: AliHamza,
    EmpExp: "02",
    category: "Game Developer",
  },
  {
    id: 85,
    name: "Sheharyar Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "Full-Stack", image: react },
      { name: "Node.js", image: js },
      { name: "Express.js", image: ts },
      { name: "React", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "System Design", image: react },
      { name: "Microservices", image: js },
      { name: "Cloud Computing", image: ts },
      { name: "RESTful API", image: react },
      { name: "GraphQL", image: js },
      { name: "Redux", image: ts },
      { name: "MobX", image: react },
      { name: "Webpack", image: js },
      { name: "Jest", image: ts },
      { name: "Mocha", image: react },
      { name: "Jasmine", image: js },
      { name: "PWA", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
    ],
    technology: ["system"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "System Developer",
  },
  {
    id: 86,
    name: "Haider Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "Full-Stack", image: react },
      { name: "Node.js", image: js },
      { name: "Express.js", image: ts },
      { name: "React", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "System Design", image: react },
      { name: "Microservices", image: js },
      { name: "Cloud Computing", image: ts },
      { name: "RESTful API", image: react },
      { name: "GraphQL", image: js },
      { name: "Redux", image: ts },
      { name: "MobX", image: react },
      { name: "Webpack", image: js },
      { name: "Jest", image: ts },
      { name: "Mocha", image: react },
      { name: "Jasmine", image: js },
      { name: "PWA", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
    ],
    technology: ["system"],
    image: Haider,
    EmpExp: "02",
    category: "System Developer",
  },
  {
    id: 87,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "Full-Stack", image: react },
      { name: "Node.js", image: js },
      { name: "Express.js", image: ts },
      { name: "React", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "System Design", image: react },
      { name: "Microservices", image: js },
      { name: "Cloud Computing", image: ts },
      { name: "RESTful API", image: react },
      { name: "GraphQL", image: js },
      { name: "Redux", image: ts },
      { name: "MobX", image: react },
      { name: "Webpack", image: js },
      { name: "Jest", image: ts },
      { name: "Mocha", image: react },
      { name: "Jasmine", image: js },
      { name: "PWA", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
    ],
    technology: ["system"],
    image: Wajid,
    EmpExp: "02",
    category: "System Developer",
  },
  {
    id: 88,
    name: "Billy",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "JavaScript", image: js },
      { name: "TypeScript", image: ts },
      { name: "Full-Stack", image: react },
      { name: "Node.js", image: js },
      { name: "Express.js", image: ts },
      { name: "React", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "System Design", image: react },
      { name: "Microservices", image: js },
      { name: "Cloud Computing", image: ts },
      { name: "RESTful API", image: react },
      { name: "GraphQL", image: js },
      { name: "Redux", image: ts },
      { name: "MobX", image: react },
      { name: "Webpack", image: js },
      { name: "Jest", image: ts },
      { name: "Mocha", image: react },
      { name: "Jasmine", image: js },
      { name: "PWA", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
    ],
    technology: ["system"],
    image: Billy,
    EmpExp: "02",
    category: "System Developer",
  },
  {
    id: 89,
    name: "Sheharyar Zaidi",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "C#", image: c },
      { name: "Python", image: ts },
      { name: "Selenium", image: ts },
      { name: "Appium", image: ts },
      { name: "JUnit", image: ts },
      { name: "TestNG", image: ts },
      { name: "Bash", image: ts },
      { name: "PowerShell", image: ts },
      { name: "Git", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
      { name: "BDD Tools", image: ts },
    ],
    technology: ["automation"],
    image: Zaidi,
    EmpExp: "02",
    category: "Automation Engineer",
  },
  {
    id: 90,
    name: "Haider",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "C#", image: c },
      { name: "Python", image: ts },
      { name: "Selenium", image: ts },
      { name: "Appium", image: ts },
      { name: "JUnit", image: ts },
      { name: "TestNG", image: ts },
      { name: "Bash", image: ts },
      { name: "PowerShell", image: ts },
      { name: "Git", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
      { name: "BDD Tools", image: ts },
    ],
    technology: ["automation"],
    image: Haider,
    EmpExp: "02",
    category: "Automation Engineer",
  },
  {
    id: 91,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "C#", image: c },
      { name: "Python", image: ts },
      { name: "Selenium", image: ts },
      { name: "Appium", image: ts },
      { name: "JUnit", image: ts },
      { name: "TestNG", image: ts },
      { name: "Bash", image: ts },
      { name: "PowerShell", image: ts },
      { name: "Git", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
      { name: "BDD Tools", image: ts },
    ],
    technology: ["automation"],
    image: AliHamza,
    EmpExp: "02",
    category: "Automation Engineer",
  },
  {
    id: 92,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "C#", image: c },
      { name: "Python", image: ts },
      { name: "Selenium", image: ts },
      { name: "Appium", image: ts },
      { name: "JUnit", image: ts },
      { name: "TestNG", image: ts },
      { name: "Bash", image: ts },
      { name: "PowerShell", image: ts },
      { name: "Git", image: ts },
      { name: "CI/CD", image: ts },
      { name: "Docker", image: ts },
      { name: "BDD Tools", image: ts },
    ],
    technology: ["automation"],
    image: Wajid,
    EmpExp: "02",
    category: "Automation Engineer",
  },
  {
    id: 93,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
    ],
    technology: ["software"],
    image: AliHamza,
    EmpExp: "02",
    category: "Software Developer",
  },
  {
    id: 94,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
    ],
    technology: ["software"],
    image: Wajid,
    EmpExp: "02",
    category: "Software Developer",
  },
  {
    id: 95,
    name: "Sheharyar Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
    ],
    technology: ["software"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Software Developer",
  },
  {
    id: 96,
    name: "Shoaib Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
    ],
    technology: ["software"],
    image: Shoaib,
    EmpExp: "02",
    category: "Software Developer",
  },
  {
    id: 97,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
      { name: "SQL", image: express },
      { name: "NoSQL Databases", image: express },
      { name: "Hadoop", image: express },
      { name: "Spark", image: express },
      { name: "Git", image: express },
      { name: "CI/CD", image: express },
      { name: "Docker", image: express },
    ],
    technology: ["senior-software"],
    image: AliHamza,
    EmpExp: "02",
    category: "Senior Developer",
  },
  {
    id: 98,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
      { name: "SQL", image: express },
      { name: "NoSQL Databases", image: express },
      { name: "Hadoop", image: express },
      { name: "Spark", image: express },
      { name: "Git", image: express },
      { name: "CI/CD", image: express },
      { name: "Docker", image: express },
    ],
    technology: ["senior-software"],
    image: Wajid,
    EmpExp: "02",
    category: "Senior Developer",
  },
  {
    id: 99,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
      { name: "SQL", image: express },
      { name: "NoSQL Databases", image: express },
      { name: "Hadoop", image: express },
      { name: "Spark", image: express },
      { name: "Git", image: express },
      { name: "CI/CD", image: express },
      { name: "Docker", image: express },
    ],
    technology: ["senior-software"],
    image: Tayyab,
    EmpExp: "02",
    category: "Senior Developer",
  },
  {
    id: 100,
    name: "Sheharyar Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "React", image: react },
      { name: "Node.js", image: node },
      { name: "HTML", image: express },
      { name: "CSS", image: react },
      { name: "JavaScript", image: node },
      { name: "TypeScript", image: express },
      { name: "Angular", image: react },
      { name: "Vue.js", image: node },
      { name: "Java", image: express },
      { name: "Python", image: react },
      { name: "Node.js", image: node },
      { name: "TypeScript", image: express },
      { name: "Ruby", image: express },
      { name: "C#", image: express },
      { name: "SQL", image: express },
      { name: "NoSQL Databases", image: express },
      { name: "Hadoop", image: express },
      { name: "Spark", image: express },
      { name: "Git", image: express },
      { name: "CI/CD", image: express },
      { name: "Docker", image: express },
    ],
    technology: ["senior-software"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Senior Developer",
  },
  {
    id: 101,
    name: "Ali Hamza",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Android SDK", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Firebase", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["senior-android"],
    image: AliHamza,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 102,
    name: "Billy",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Android SDK", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Firebase", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["senior-android"],
    image: Billy,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 103,
    name: "Usman",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Android SDK", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Firebase", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["senior-android"],
    image: Usman,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 104,
    name: "Tayyab",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Android SDK", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Firebase", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["senior-android"],
    image: Tayyab,
    EmpExp: "02",
    category: "Andriod",
  },
  {
    id: 105,
    name: "Wajid Ali",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Kotlin", image: kotlin },
      { name: "Dart", image: dart },
      { name: "Swift (iOS)", image: java },
      { name: "React Native", image: kotlin },
      { name: "MVVM", image: dart },
      { name: "Kotlin Coroutines", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Adapters", image: dart },
      { name: "Fragments", image: java },
      { name: "RESTful API", image: kotlin },
      { name: "SQLite", image: dart },
      { name: "Room", image: java },
      { name: "Push Notifications", image: kotlin },
      { name: "Dagger", image: dart },
      { name: "Koin", image: java },
      { name: "JUnit", image: kotlin },
      { name: "Espresso", image: dart },
    ],

    technology: ["flutter"],
    image: Wajid,
    EmpExp: "02",
    category: "Flutter",
  },
  {
    id: 106,
    name: "Haider Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: sql },
      { name: "ROR", image: sql },
      { name: "SQL", image: backend },
      { name: "Pandas", image: sql },
      { name: "NumPy", image: sql },
      { name: "Jupyter Notebooks", image: sql },
      { name: "Matplotlib", image: sql },
      { name: "Seaborn", image: sql },
      { name: "Plotly", image: sql },
      { name: "Scikit-learn", image: sql },
      { name: "TensorFlow and PyTorch", image: sql },
      { name: "Statsmodels", image: sql },
      { name: "MySQL", image: sql },
      { name: "PostgreSQL", image: sql },
      { name: "Apache Spark", image: sql },
      { name: "OpenRefine", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
      { name: "AWS", image: sql },
      { name: "Azure", image: sql },
    ],

    technology: ["data-scientist"],
    image: Haider,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 107,
    name: "Sheharyar Javaid",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: sql },
      { name: "ROR", image: sql },
      { name: "SQL", image: backend },
      { name: "Pandas", image: sql },
      { name: "NumPy", image: sql },
      { name: "Jupyter Notebooks", image: sql },
      { name: "Matplotlib", image: sql },
      { name: "Seaborn", image: sql },
      { name: "Plotly", image: sql },
      { name: "Scikit-learn", image: sql },
      { name: "TensorFlow and PyTorch", image: sql },
      { name: "Statsmodels", image: sql },
      { name: "MySQL", image: sql },
      { name: "PostgreSQL", image: sql },
      { name: "Apache Spark", image: sql },
      { name: "OpenRefine", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
      { name: "AWS", image: sql },
      { name: "Azure", image: sql },
    ],

    technology: ["data-scientist"],
    image: SheharyarJavaid,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 108,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: sql },
      { name: "ROR", image: sql },
      { name: "SQL", image: backend },
      { name: "Pandas", image: sql },
      { name: "NumPy", image: sql },
      { name: "Jupyter Notebooks", image: sql },
      { name: "Matplotlib", image: sql },
      { name: "Seaborn", image: sql },
      { name: "Plotly", image: sql },
      { name: "Scikit-learn", image: sql },
      { name: "TensorFlow and PyTorch", image: sql },
      { name: "Statsmodels", image: sql },
      { name: "MySQL", image: sql },
      { name: "PostgreSQL", image: sql },
      { name: "Apache Spark", image: sql },
      { name: "OpenRefine", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
      { name: "AWS", image: sql },
      { name: "Azure", image: sql },
    ],

    technology: ["data-scientist"],
    image: AliHamza,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 109,
    name: "Tayyab",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Python", image: sql },
      { name: "ROR", image: sql },
      { name: "SQL", image: backend },
      { name: "Pandas", image: sql },
      { name: "NumPy", image: sql },
      { name: "Jupyter Notebooks", image: sql },
      { name: "Matplotlib", image: sql },
      { name: "Seaborn", image: sql },
      { name: "Plotly", image: sql },
      { name: "Scikit-learn", image: sql },
      { name: "TensorFlow and PyTorch", image: sql },
      { name: "Statsmodels", image: sql },
      { name: "MySQL", image: sql },
      { name: "PostgreSQL", image: sql },
      { name: "Apache Spark", image: sql },
      { name: "OpenRefine", image: sql },
      { name: "Hadoop", image: sql },
      { name: "Spark", image: sql },
      { name: "AWS", image: sql },
      { name: "Azure", image: sql },
    ],

    technology: ["data-scientist"],
    image: Tayyab,
    EmpExp: "02",
    category: "Database Engineers",
  },
  {
    id: 110,
    name: "Ahmed Khalid",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Java SE", image: kotlin },
      { name: "Java EE", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Spring Framework", image: kotlin },
      { name: "Hibernate", image: dart },
      { name: "Maven", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Gradle", image: dart },
      { name: "JUnit", image: java },
      { name: "RESTful", image: kotlin },
      { name: "Spring Boot", image: dart },
      { name: "JavaFX", image: java },
      { name: "JDBC", image: kotlin },
      { name: "Servlets and JSP", image: dart },
      { name: "Design Patterns in Java", image: java },
      { name: "Git", image: kotlin },
    ],

    technology: ["java"],
    image: Ahmed,
    EmpExp: "02",
    category: "Java",
  },
  {
    id: 111,
    name: "Wajid Ali",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Java SE", image: kotlin },
      { name: "Java EE", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Spring Framework", image: kotlin },
      { name: "Hibernate", image: dart },
      { name: "Maven", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Gradle", image: dart },
      { name: "JUnit", image: java },
      { name: "RESTful", image: kotlin },
      { name: "Spring Boot", image: dart },
      { name: "JavaFX", image: java },
      { name: "JDBC", image: kotlin },
      { name: "Servlets and JSP", image: dart },
      { name: "Design Patterns in Java", image: java },
      { name: "Git", image: kotlin },
    ],

    technology: ["java"],
    image: Wajid,
    EmpExp: "02",
    category: "Java",
  },
  {
    id: 112,
    name: "Tayyab",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Java SE", image: kotlin },
      { name: "Java EE", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Spring Framework", image: kotlin },
      { name: "Hibernate", image: dart },
      { name: "Maven", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Gradle", image: dart },
      { name: "JUnit", image: java },
      { name: "RESTful", image: kotlin },
      { name: "Spring Boot", image: dart },
      { name: "JavaFX", image: java },
      { name: "JDBC", image: kotlin },
      { name: "Servlets and JSP", image: dart },
      { name: "Design Patterns in Java", image: java },
      { name: "Git", image: kotlin },
    ],

    technology: ["java"],
    image: Tayyab,
    EmpExp: "02",
    category: "Java",
  },
  {
    id: 113,
    name: "Sheharyar Khan",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Java", image: java },
      { name: "Java SE", image: kotlin },
      { name: "Java EE", image: dart },
      { name: "Android Studio IDE", image: java },
      { name: "Spring Framework", image: kotlin },
      { name: "Hibernate", image: dart },
      { name: "Maven", image: java },
      { name: "RecyclerView", image: kotlin },
      { name: "Gradle", image: dart },
      { name: "JUnit", image: java },
      { name: "RESTful", image: kotlin },
      { name: "Spring Boot", image: dart },
      { name: "JavaFX", image: java },
      { name: "JDBC", image: kotlin },
      { name: "Servlets and JSP", image: dart },
      { name: "Design Patterns in Java", image: java },
      { name: "Git", image: kotlin },
    ],

    technology: ["java"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Java",
  },
  {
    id: 114,
    name: "Sheharyar",
    email: "sheharyar@gmail.com",
    stack: [
      { name: "Ruby", image: mongo },
      { name: "Ruby on Rails Framework", image: express },
      { name: "Active Record", image: graphql },
      { name: "RESTful API", image: mongo },
      { name: "Rails CLI", image: express },
      { name: "MVC", image: graphql },
      { name: "Rails Routing", image: mongo },
      { name: "ERB", image: express },
      { name: "RSpec", image: graphql },
      { name: "Capistrano", image: mongo },
      { name: "Action Cable", image: express },
      { name: "Git", image: express },
      { name: "PostgreSQL", image: express },
      { name: "GraphQl", image: express },
      { name: "JavaScript", image: express },
      { name: "AJAX", image: express },
    ],

    technology: ["ROR"],
    image: Sheharyar,
    EmpExp: "03",
    category: "ROR",
  },
  {
    id: 115,
    name: "Rizwan",
    email: "sheharyar@gmail.com",
    stack: [
      { name: "Ruby", image: mongo },
      { name: "Ruby on Rails Framework", image: express },
      { name: "Active Record", image: graphql },
      { name: "RESTful API", image: mongo },
      { name: "Rails CLI", image: express },
      { name: "MVC", image: graphql },
      { name: "Rails Routing", image: mongo },
      { name: "ERB", image: express },
      { name: "RSpec", image: graphql },
      { name: "Capistrano", image: mongo },
      { name: "Action Cable", image: express },
      { name: "Git", image: express },
      { name: "PostgreSQL", image: express },
      { name: "GraphQl", image: express },
      { name: "JavaScript", image: express },
      { name: "AJAX", image: express },
    ],

    technology: ["ROR"],
    image: rizwan,
    EmpExp: "03",
    category: "ROR",
  },
  {
    id: 116,
    name: "Sheharyar Javaid",
    email: "sheharyar@gmail.com",
    stack: [
      { name: "Ruby", image: mongo },
      { name: "Ruby on Rails Framework", image: express },
      { name: "Active Record", image: graphql },
      { name: "RESTful API", image: mongo },
      { name: "Rails CLI", image: express },
      { name: "MVC", image: graphql },
      { name: "Rails Routing", image: mongo },
      { name: "ERB", image: express },
      { name: "RSpec", image: graphql },
      { name: "Capistrano", image: mongo },
      { name: "Action Cable", image: express },
      { name: "Git", image: express },
      { name: "PostgreSQL", image: express },
      { name: "GraphQl", image: express },
      { name: "JavaScript", image: express },
      { name: "AJAX", image: express },
    ],

    technology: ["ROR"],
    image: SheharyarJavaid,
    EmpExp: "03",
    category: "ROR",
  },
  {
    id: 117,
    name: "Haider Javaid",
    email: "sheharyar@gmail.com",
    stack: [
      { name: "Ruby", image: mongo },
      { name: "Ruby on Rails Framework", image: express },
      { name: "Active Record", image: graphql },
      { name: "RESTful API", image: mongo },
      { name: "Rails CLI", image: express },
      { name: "MVC", image: graphql },
      { name: "Rails Routing", image: mongo },
      { name: "ERB", image: express },
      { name: "RSpec", image: graphql },
      { name: "Capistrano", image: mongo },
      { name: "Action Cable", image: express },
      { name: "Git", image: express },
      { name: "PostgreSQL", image: express },
      { name: "GraphQl", image: express },
      { name: "JavaScript", image: express },
      { name: "AJAX", image: express },
    ],

    technology: ["ROR"],
    image: Haider,
    EmpExp: "03",
    category: "ROR",
  },
  {
    id: 118,
    name: "Sheharyar Khan",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Swift", image: java },
      { name: "Xcode IDE", image: kotlin },
      { name: "IOS", image: dart },
      { name: "UIKit Framework", image: java },
      { name: "SwiftUI Framework", image: kotlin },
      { name: "Auto Layout", image: dart },
      { name: "Interface Builder", image: java },
      { name: "Core Data", image: kotlin },
      { name: "Cocoa Touch", image: dart },
      { name: "RESTful APIs", image: java },
      { name: "HIG", image: kotlin },
      { name: "Push Notifications", image: kotlin },
      { name: "Core Animation", image: dart },
      { name: "TDD", image: java },
      { name: "CI for iOS", image: kotlin },
    ],
    technology: ["IOS", "app"],
    image: Sheharyar,
    EmpExp: "02",
    category: "IOS",
  },
  {
    id: 119,
    name: "Billy",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Swift", image: java },
      { name: "Xcode IDE", image: kotlin },
      { name: "IOS", image: dart },
      { name: "UIKit Framework", image: java },
      { name: "SwiftUI Framework", image: kotlin },
      { name: "Auto Layout", image: dart },
      { name: "Interface Builder", image: java },
      { name: "Core Data", image: kotlin },
      { name: "Cocoa Touch", image: dart },
      { name: "RESTful APIs", image: java },
      { name: "HIG", image: kotlin },
      { name: "Push Notifications", image: kotlin },
      { name: "Core Animation", image: dart },
      { name: "TDD", image: java },
      { name: "CI for iOS", image: kotlin },
    ],
    technology: ["IOS", "app"],
    image: Billy,
    EmpExp: "02",
    category: "IOS",
  },
  {
    id: 120,
    name: "Ali Hamza",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Swift", image: java },
      { name: "Xcode IDE", image: kotlin },
      { name: "IOS", image: dart },
      { name: "UIKit Framework", image: java },
      { name: "SwiftUI Framework", image: kotlin },
      { name: "Auto Layout", image: dart },
      { name: "Interface Builder", image: java },
      { name: "Core Data", image: kotlin },
      { name: "Cocoa Touch", image: dart },
      { name: "RESTful APIs", image: java },
      { name: "HIG", image: kotlin },
      { name: "Push Notifications", image: kotlin },
      { name: "Core Animation", image: dart },
      { name: "TDD", image: java },
      { name: "CI for iOS", image: kotlin },
    ],
    technology: ["IOS"],
    image: AliHamza,
    EmpExp: "02",
    category: "IOS",
  },
  {
    id: 121,
    name: "Rizwan",
    email: "sumbal@gmail.com",
    stack: [
      { name: "Swift", image: java },
      { name: "Xcode IDE", image: kotlin },
      { name: "IOS", image: dart },
      { name: "UIKit Framework", image: java },
      { name: "SwiftUI Framework", image: kotlin },
      { name: "Auto Layout", image: dart },
      { name: "Interface Builder", image: java },
      { name: "Core Data", image: kotlin },
      { name: "Cocoa Touch", image: dart },
      { name: "RESTful APIs", image: java },
      { name: "HIG", image: kotlin },
      { name: "Push Notifications", image: kotlin },
      { name: "Core Animation", image: dart },
      { name: "TDD", image: java },
      { name: "CI for iOS", image: kotlin },
    ],
    technology: ["IOS"],
    image: rizwan,
    EmpExp: "02",
    category: "IOS",
  },
  {
    id: 122,
    name: "zunaira",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Microservices", image: window },
      { name: "API Design", image: architure },
      { name: "Enterprise-level", image: sql },
      { name: "Cloud Computing", image: window },
      { name: "API Design", image: window },
      { name: "Docker", image: window },
      { name: "System Design", image: window },
      { name: "Kubernetes", image: window },
      { name: "Cross-Functional", image: window },
      { name: "Vendor Evaluation", image: window },
      { name: "Budgeting", image: window },
      { name: "Agile", image: window },
      { name: "Scrum", image: window },
    ],

    technology: ["tech-lead"],
    image: profile,
    EmpExp: "02",
    category: "Software Architecture",
  },
  {
    id: 123,
    name: "zunaira",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Microservices", image: window },
      { name: "API Design", image: architure },
      { name: "Enterprise-level", image: sql },
      { name: "Cloud Computing", image: window },
      { name: "API Design", image: window },
      { name: "Docker", image: window },
      { name: "System Design", image: window },
      { name: "Kubernetes", image: window },
      { name: "Cross-Functional", image: window },
      { name: "Vendor Evaluation", image: window },
      { name: "Budgeting", image: window },
      { name: "Agile", image: window },
      { name: "Scrum", image: window },
    ],

    technology: ["tech-lead"],
    image: profile,
    EmpExp: "02",
    category: "Software Architecture",
  },
  {
    id: 124,
    name: "Shoaib Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Full-Stack", image: js },
      { name: "Node.js", image: ts },
      { name: "Express.js", image: react },
      { name: "React.js", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "SSR", image: react },
      { name: "RESTful API", image: js },
      { name: "GraphQL", image: ts },
      { name: "Redux", image: react },
      { name: "MobX", image: js },
      { name: "Webpack", image: ts },
      { name: "Jest", image: react },
      { name: "Mocha", image: js },
      { name: "Jasmine", image: ts },
      { name: "PWA", image: react },
      { name: "CI/CD", image: js },
      { name: "Docker", image: js },
    ],
    technology: ["senior-javascript"],
    image: Shoaib,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 125,
    name: "Ali Hamza",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Full-Stack", image: js },
      { name: "Node.js", image: ts },
      { name: "Express.js", image: react },
      { name: "React.js", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "SSR", image: react },
      { name: "RESTful API", image: js },
      { name: "GraphQL", image: ts },
      { name: "Redux", image: react },
      { name: "MobX", image: js },
      { name: "Webpack", image: ts },
      { name: "Jest", image: react },
      { name: "Mocha", image: js },
      { name: "Jasmine", image: ts },
      { name: "PWA", image: react },
      { name: "CI/CD", image: js },
      { name: "Docker", image: js },
    ],
    technology: ["senior-javascript"],
    image: AliHamza,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 126,
    name: "Wajid Ali",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Full-Stack", image: js },
      { name: "Node.js", image: ts },
      { name: "Express.js", image: react },
      { name: "React.js", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "SSR", image: react },
      { name: "RESTful API", image: js },
      { name: "GraphQL", image: ts },
      { name: "Redux", image: react },
      { name: "MobX", image: js },
      { name: "Webpack", image: ts },
      { name: "Jest", image: react },
      { name: "Mocha", image: js },
      { name: "Jasmine", image: ts },
      { name: "PWA", image: react },
      { name: "CI/CD", image: js },
      { name: "Docker", image: js },
    ],
    technology: ["senior-javascript"],
    image: Wajid,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 127,
    name: "Sheharyar Khan",
    email: "zunaira@gmail.com",
    stack: [
      { name: "Full-Stack", image: js },
      { name: "Node.js", image: ts },
      { name: "Express.js", image: react },
      { name: "React.js", image: react },
      { name: "Angular.js", image: js },
      { name: "Vue.js", image: ts },
      { name: "SSR", image: react },
      { name: "RESTful API", image: js },
      { name: "GraphQL", image: ts },
      { name: "Redux", image: react },
      { name: "MobX", image: js },
      { name: "Webpack", image: ts },
      { name: "Jest", image: react },
      { name: "Mocha", image: js },
      { name: "Jasmine", image: ts },
      { name: "PWA", image: react },
      { name: "CI/CD", image: js },
      { name: "Docker", image: js },
    ],
    technology: ["senior-javascript"],
    image: Sheharyar,
    EmpExp: "02",
    category: "Frontend Developer",
  },
  {
    id: 48,
    name: "Wajid Ali",
    email: "alih@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "js", image: js },
      { name: "Express", image: express },
      { name: "Java", image: mongo },
      { name: "Python", image: js },
      { name: "Ruby", image: express },
      { name: "Node.js", image: mongo },
      { name: "C#", image: js },
      { name: "GraphQl", image: express },
    ],

    technology: ["graphql"],
    image: Wajid,
    EmpExp: "03",
    category: "Backend",
  },
  {
    id: 49,
    name: "Tayyab",
    email: "alih@gmail.com",
    stack: [
      { name: "Mongo", image: mongo },
      { name: "js", image: js },
      { name: "Express", image: express },
      { name: "Java", image: mongo },
      { name: "Python", image: js },
      { name: "Ruby", image: express },
      { name: "Node.js", image: mongo },
      { name: "C#", image: js },
      { name: "GraphQl", image: express },
    ],

    technology: ["graphql"],
    image: Tayyab,
    EmpExp: "03",
    category: "Backend Developer",
  },
];

export const techs = [
  { name: "Node.Js Developer", path: "node-js" },
  {
    name: "Frontend Developer",
    path: "frontend",
  },
  {
    name: "Frontend Developer",
    path: "vue-js",
  },
  {
    name: "Frontend Developer",
    path: "next-js",
  },
  {
    name: "Angular Developer",
    path: "angular",
  },
  {
    name: "Swift Developer",
    path: "swift",
  },
  {
    name: "Backend Developer",
    path: "backend",
  },
  {
    name: "Web Developer",
    path: "web",
  },
  {
    name: "WordPress Developer",
    path: "wordpress",
  },
  {
    name: "GraphQL Developer",
    path: "graphql",
  },
  {
    name: "Python Developer",
    path: "python",
  },
  {
    name: "React Native Developer",
    path: "react-native",
  },
  {
    name: "App Developer",
    path: "app",
  },
  {
    name: "Devops Developer",
    path: "devops",
  },
  {
    name: ".Net Developer",
    path: "dot-net",
  },
  {
    name: "Blockchain",
    path: "blockchain",
  },
  {
    name: "Frontend",
    path: "frontend",
  },
  {
    name: "React.js",
    path: "react-js",
  },
  {
    name: "java Developer",
    path: "java",
  },
  {
    name: "IOS Developer",
    path: "IOS",
  },
  {
    name: "QA Engineer",
    path: "QA",
  },
  {
    name: "Android Developer",
    path: "android",
  },
  {
    name: "Ruby on Rails",
    path: "ROR",
  },
  {
    name: "PHP Developer",
    path: "PHP",
  },
  {
    name: "Full Stack",
    path: "fullstack",
  },
  {
    name: "Graphic Designer",
    path: "graphic-designer",
  },
  {
    name: "software-engineer",
    path: "software",
  },
  {
    name: "Senior Software Engineer",
    path: "senior-software",
  },
  {
    name: "Software Architecture",
    path: "software-architecture",
  },
  {
    name: "Tech Lead",
    path: "tech-lead",
  },
  {
    name: "cloud Engineer",
    path: "cloud",
  },
  {
    name: "machine learning",
    path: "machine-learning",
  },
  {
    name: "Database",
    path: "database",
  },
  {
    name: "data-scientist",
    path: "data-scientist",
  },
  {
    name: "Flutter Developer",
    path: "flutter",
  },
  {
    name: "Game Developer",
    path: "game",
  },
  {
    name: "Automation Developer",
    path: "automation",
  },
  {
    name: "Senior Android Developer",
    path: "senior-android",
  },
  {
    name: "Senior Javascript Developer",
    path: "senior-javascript",
  },
  {
    name: "Shopify Developer",
    path: "shopify",
  },
  {
    name: "Laravel Developer",
    path: "laravel",
  },
  {
    name: "Mern Stack Developer",
    path: "mernstack",
  },
  {
    name: "System Engineer",
    path: "system",
  },
  {
    name: "Director Of Engineer",
    path: "director-of-engineer",
  },
];

export const devCategories = [
  { name: "Node.Js Developer", path: "node-js" },
  {
    name: "Angular Developer",
    path: "angular",
  },
  {
    name: "Swift Developer",
    path: "swift",
  },
  {
    name: "Backend Developer",
    path: "backend",
  },
  {
    name: "Web Developer",
    path: "web",
  },
  {
    name: "WordPress Developer",
    path: "wordpress",
  },
  {
    name: "GraphQL Developer",
    path: "graphql",
  },
  {
    name: "Python Developer",
    path: "python",
  },
  {
    name: "React Native Developer",
    path: "react-native",
  },
  {
    name: "App Developer",
    path: "app",
  },
  {
    name: "Devops Developer",
    path: "devops",
  },
  {
    name: ".Net Developer",
    path: "dot-net",
  },
  {
    name: "Blockchain",
    path: "blockchain",
  },
  {
    name: "Frontend",
    path: "frontend",
  },
  {
    name: "React.js",
    path: "react-js",
  },
  {
    name: "java Developer",
    path: "java",
  },
  {
    name: "IOS Developer",
    path: "IOS",
  },
  {
    name: "QA Engineer",
    path: "QA",
  },
  {
    name: "Android Developer",
    path: "android",
  },
  {
    name: "Ruby on Rails",
    path: "ROR",
  },
  {
    name: "PHP Developer",
    path: "PHP",
  },
  {
    name: "Full Stack",
    path: "fullstack",
  },
  {
    name: "Graphic Designer",
    path: "graphic-designer",
  },
  {
    name: "software-engineer",
    path: "software",
  },
  {
    name: "Senior Software Engineer",
    path: "senior-software",
  },
  {
    name: "Software Architecture",
    path: "software-architecture",
  },
  {
    name: "Tech Lead",
    path: "tech-lead",
  },
  {
    name: "cloud Engineer",
    path: "cloud",
  },
  {
    name: "machine learning",
    path: "machine-learning",
  },
  {
    name: "Database",
    path: "database",
  },
  {
    name: "data-scientist",
    path: "data-scientist",
  },
  {
    name: "Flutter Developer",
    path: "flutter",
  },
  {
    name: "Game Developer",
    path: "game",
  },
  {
    name: "Automation Developer",
    path: "automation",
  },
  {
    name: "Senior Android Developer",
    path: "senior-android",
  },
  {
    name: "Senior Javascript Developer",
    path: "senior-javascript",
  },
  {
    name: "Shopify Developer",
    path: "shopify",
  },
  {
    name: "Laravel Developer",
    path: "laravel",
  },
  {
    name: "Mern Stack Developer",
    path: "mernstack",
  },
  {
    name: "System Engineer",
    path: "system",
  },
  {
    name: "Director Of Engineer",
    path: "director-of-engineer",
  },
];
