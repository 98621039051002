import React from "react";
import "./progress.css";
import ProgressBar from "./ProgressBar";

const Progress = ({ progress, stp, hideRequirements }) => {
  let cur1,
    cur2,
    cur3 = "";
  if (stp === 0 || stp === 1 || stp === 2) {
    cur1 = "actv1";
  }
  if (stp === 3) {
    cur2 = "actv2";
  }
  if (stp === 4) {
    cur3 = "actv3";
  }

  return (
    <div className="set-bg-color-bar">
      <div className="cntainer set-side-bar-flex px-1 px-sm-2 px-md-3 mx-lg-3">
        <div className="progress-box w-sm-75  mx-auto py-sm-4 wrapper">
          <ul className="py-1 mb-0">
            {!hideRequirements && (
              <li className={cur1}>
                <i
                  className="far fa-clipboard set-before"
                  style={{ fontSize: "25px", paddingLeft: "2px" }}
                ></i>{" "}
                Requirements
              </li>
            )}

            <li className={cur2}>
              <i className="fas fa-hotel set-before"></i> Contact
            </li>
            <li className={cur3}>
              {" "}
              <i className="far fa-heart pe-1"></i> Thank You
            </li>
          </ul>

          <div className="mx-cust pt-3">
            <h5 className="text-left set-font-completed py-2">
              {" "}
              {`${progress}%  Completed`}
            </h5>
            {/* <div className="progress">
              <div
                className={`progress-bar w-${progress}`}
                role="progressbar"
                aria-valuenow="25%"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div> */}
            {/* <progress
              value={25}
              max="100"
              style={{
                height: "10px",
                width: "100%",
                borderRadius: "0.25rem",
              }}
            ></progress> */}
            <ProgressBar progress={progress}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
