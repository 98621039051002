import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomArrow from "../customerslider/Arrows";
import "./teamslider.css";
import "../../components/animSection/animation.css";
import { Link, useParams } from "react-router-dom";
import { developer } from "../SkillsSlider/developersData";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DevPageSlider from "./DevPageSlider";

function Teamslider({ devlopers }) {
  const { techTitle } = useParams();
  const data = devlopers ? devlopers : developer;
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: data.length < 4 ? data.length : 4, // Adjust as needed
  //   slidesToScroll: 4,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  // const settingsSlider = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: data.length < 3 ? data.length : 3, // Adjust as needed
  //   slidesToScroll: 3,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      {/* <div className="slider-black-arrow">
        <Slider
          {...settings}
          prevArrow={<CustomArrow direction="prev" />}
          nextArrow={<CustomArrow direction="next" />}
        >
          {data.map((dev, index) => (
            <div className="d-flex justify-content-center align-content-center slide mb-5">
              <div className=" justify-content-center align-content-center px-1">
                <div
                  key={index}
                  className="card custom_card_new m-0 d-flex flex-column border rounded-3 align-items-center"
                >
                  <div className="card_details d-flex justify-content-between w-100">
                    <div className="">
                      <span className="fw-bold text-success">5 </span> Years +
                      <br /> Experience
                    </div>
                    <div className="slickImg_new">
                      <CircularProgressbarWithChildren value={71}>
                        <img src={dev.image} alt="doge" />
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="align-self-center">
                      <h3 className="text-blue">71%</h3> score
                    </div>
                  </div>

                  <h5 className="fw-bold title-blue">
                    {capitalizeFirstLetter(dev.name)}
                  </h5>
                  <strong> {dev.category}</strong>
                  <div className="languages w-100 mb-0 d-flex justify-content-between">
                    {dev.stack.slice(0, 3).map(({ name, image }, techIndex) => (
                      <div key={techIndex} className="  technology">
                        <div className="techicon w-100 d-flex align-items-center ">
                          <div className="skills_img">
                            <img src={image} alt="" />
                          </div>

                          <p className="m-0  text-stack">{name}</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Link to={`/hire-me-form/${techTitle}-developer`}>
                    <button
                      className="step-btn my-3"
                      onClick={() => {
                        localStorage.setItem("devDetails", JSON.stringify(dev));
                      }}
                    >
                      Hire Me
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
      {/* <div className="slider-black-arrow mt-3">
        <Slider
          {...settingsSlider}
          prevArrow={<CustomArrow direction="prev" />}
          nextArrow={<CustomArrow direction="next" />}
        >
          {data.map((dev, index) => (
            <div className=" d-flex justify-content-center align-content-center slide my-5">
              <div className=" justify-content-center align-content-center px-1">
                <div
                  key={index}
                  className="card custom_card_new m-0 d-flex flex-column border rounded-3 align-items-center"
                >
                  <div className="card_details d-flex justify-content-between w-100">
                    <div className="">
                      <span className="fw-bold text-success">5 </span> Years +
                      <br /> Experience
                    </div>
                    <div className="slickImg_new">
                      <CircularProgressbarWithChildren value={71}>
                        <img src={dev.image} alt="doge" />
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="align-self-center">
                      <h3 className="text-blue">71%</h3> score
                    </div>
                  </div>

                  <h5 className="fw-bold title-blue">
                    {capitalizeFirstLetter(dev.name)}
                  </h5>
                  <strong> {dev.category}</strong>
                  <div className="languages w-100 mb-0 d-flex justify-content-between">
                    {dev.stack.slice(0, 3).map(({ name, image }, techIndex) => (
                      <div key={techIndex} className="  technology">
                        <div className="techicon w-100 d-flex align-items-center ">
                          <div className="skills_img">
                            <img src={image} alt="" />
                          </div>
                          <p className="m-0  text-stack">{name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Link to={`/hire-me-form/${techTitle}-developer`}>
                    <button
                      className="step-btn my-3"
                      onClick={() => {
                        localStorage.setItem("devDetails", JSON.stringify(dev));
                      }}
                    >
                      Hire Me
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}

      <DevPageSlider slides={data}/>

      {/* <div className="see_more d-flex justify-content-center">
        <Link to={"/engineer"}>
          Show More
          <i class="fas fa-arrow-right"></i>
        </Link>
      </div> */}
    </>
  );
}

export default Teamslider;
