import { Navigation, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Link, useParams } from "react-router-dom";
import { getFirstThreeAsCommaSeparatedString } from "../../utils/getStringFromArray";
import { getTechTitleFromUrl } from "../../utils/getTechTitleFromUrl";

const DevPageSlider = ({ slides }) => {
  const breakpoints = {
    1440: {
      slidesPerView: 4,
    },
    1024: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 2,
    },
    320: {
      slidesPerView: 1,
    },
    280: {
      slidesPerView: 1,
    },
  };

  const array = [2, 4, 5, 6, 7, 8, 34, 5];

  const { techTitle } = useParams();
  console.log("slides data", slides);
  const tectTitleKey = getTechTitleFromUrl(techTitle);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="dev-p-slider">
      <Swiper
        modules={[Navigation, Scrollbar, Autoplay]}
        breakpoints={breakpoints}
        spaceBetween={50}
        slidesPerView={5}
        loop={true}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        //   // Responsive breakpoints
        onSwiper={(swiper) => console.log(swiper)}
      >
        {slides.map((dev, index) => (
          <SwiperSlide>
            <div
              key={index}
              className="card custom_card_new m-0 d-flex flex-column border rounded-3 align-items-center w-100"
            >
                            <div className="d-flex flex-column align-items-center w-100">
                    <div className="exp-bar">
                      <p>5 Years of Experience</p>
                    </div>
                    <div className="dev-image-wrapper">
                      <CircularProgressbarWithChildren value={71}>
                        <img src={dev.image} alt="doge" />
                      </CircularProgressbarWithChildren>
                    </div>
                    <p className="dev-name-bar">
                      {capitalizeFirstLetter(dev.name)}
                    </p>
                    <p className="dev-stack-bar">
                      {capitalizeFirstLetter(dev.category)}
                    </p>
                    <div className="dev-tech-bar d-flex justify-content-between w-100">
                      <p className="dev-tech-bar-child1">Technology</p>
                      <div className="dev-stack-string">
                        <p className="dev-tech-bar-child2">
                          {getFirstThreeAsCommaSeparatedString(dev.stack)}
                        </p>
                      </div>
                    </div>
                    <div className="dev-tech-bar d-flex justify-content-between w-100">
                      <p className="dev-tech-bar-child1">Rating</p>
                      <p className="dev-tech-bar-child2">76% Positive Rated</p>
                    </div>
                  </div>
              {/* <div className="card_details d-flex justify-content-between w-100">
                <div className="">
                  <span className="fw-bold text-success">5 </span> Years +
                  <br /> Experience
                </div>
                <div className="profile-img-warapper">
                  <CircularProgressbarWithChildren value={71}>
                    <img src={dev.image} alt="doge" />
                  </CircularProgressbarWithChildren>
                </div>
                <div className="align-self-center">
                  <h3 className="text-blue">71%</h3> score
                </div>
              </div>

              <h5 className="fw-bold title-blue">
                {capitalizeFirstLetter(dev.name)}
              </h5>
              <strong> {dev.category}</strong>
              <div className="languages w-100 mb-0 d-flex justify-content-between">
                {dev.stack.slice(0, 3).map(({ name, image }, techIndex) => (
                  <div key={techIndex} className="  technology">
                    <div className="techicon w-100 d-flex align-items-center ">
                      <div className="skills_img">
                        <img src={image} alt="" />
                      </div>

                      <p className="m-0  text-stack">{name}</p>
                    </div>
                  </div>
                ))}
              </div> */}

              <Link to={`/hire-me-form/${tectTitleKey}-developer`}>
                <button
                  className="step-btn my-3"
                  onClick={() => {
                    localStorage.setItem("devDetails", JSON.stringify(dev));
                  }}
                >
                  Hire Me
                </button>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default DevPageSlider;
